import { Box, Divider, Grid } from '@material-ui/core'
import { DevelopingComponent } from 'components/DevelopingComponent'
import { FormControlGrid } from 'components/FormControlGrid'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { MyAccountsSelection } from 'containers/transfer/MyAccountsSelection'
import { AccountContext } from 'contexts/AccountContext'
import { Account, Withdraw, WithdrawStatus } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { InquiryFailed } from './InquiryFailed'
import { WithdrawConfirming } from './WithdrawConfirming'
import { WithdrawForm } from './WithdrawForm'
import { WithdrawProcessing } from './WithdrawProcessing'

export const WithdrawPage: React.FunctionComponent<RouteComponentProps<{ accountId?: string }>> = ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { accountId } = match.params
  const [accounts] = useContext(AccountContext)
  const [account, setAccount] = useState<Account | undefined>(accounts?.find(a => a.id === accountId))

  const [inquiry, setInquiry] = useState<Withdraw>()
  const [waitWithdraw, setWaitWithdraw] = useState<Withdraw>()

  const onAccountSelected = (account?: Account) => {
    if (account) {
      history.push(`/withdraws/${account.id}`)
    }
  }

  const onWithdrawInquiried = (withdraw: Withdraw) => {
    setInquiry(withdraw)
  }

  const onWaitWithdrawUpdated = (withdraw: undefined | Withdraw) => {
    setWaitWithdraw(withdraw)
  }

  const onWaitWithdrawConfirmed = (withdraw: Withdraw) => {
    setInquiry(undefined)
    setWaitWithdraw(withdraw)
  }

  useEffect(() => {
    setAccount(accounts?.find(a => a.id === accountId))
  }, [accountId])

  return (
    <Page title={t('withdraw.title')} padded>
      <PaperBlock title={t('withdraw.title')} desc={t('withdraw.subtitle')}>
        {account &&
        waitWithdraw &&
        [
          WithdrawStatus.PENDING,
          WithdrawStatus.PROCESS,
          WithdrawStatus.UNSENT,
          WithdrawStatus.DECLINED,
          WithdrawStatus.SENT,
          WithdrawStatus.SUCCEED,
          WithdrawStatus.OBSCURE,
          WithdrawStatus.FAILED,
        ].includes(waitWithdraw.status) ? (
          <WithdrawProcessing account={account} withdraw={waitWithdraw} onWithdrawUpdated={onWaitWithdrawUpdated} />
        ) : account && inquiry && (inquiry.status === WithdrawStatus.INQUIRIED || inquiry.status === WithdrawStatus.PENDING) ? (
          <WithdrawConfirming
            account={account}
            withdraw={inquiry}
            onWithdrawConfirmed={onWaitWithdrawConfirmed}
            onWithdrawCancelled={() => {
              setInquiry(undefined)
            }}
          />
        ) : account && inquiry ? (
          <InquiryFailed
            inquiry={inquiry}
            onWithdrawCancelled={() => {
              setInquiry(undefined)
            }}
          />
        ) : (
          <>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10}>
                <Box>
                  <FormControlGrid fullWidth margin="normal" title={t('withdraw.from_account')}>
                    <MyAccountsSelection
                      // title={t('withdraw.select_your_account')}
                      account={account}
                      onSelectAccount={onAccountSelected}
                    />
                  </FormControlGrid>

                  <Box marginY={2}>
                    <Divider />
                    {account && account.currencyId === 'IDR' ? (
                      <>
                        <WithdrawForm account={account} onWithdrawSubmitted={onWithdrawInquiried} />

                        {/* <WithdrawalsTable account={account} /> */}
                      </>
                    ) : account ? (
                      <DevelopingComponent />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </PaperBlock>
    </Page>
  )
}
