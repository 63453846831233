import {
  Box,
  Button,
  Chip,
  colors,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Account, Bank } from 'models'
import { Refresh } from '@material-ui/icons'
import { Controller, useForm } from 'react-hook-form'
import { DepositRequest } from 'models/DepositRequest'
import { FormControlGrid } from 'components/FormControlGrid'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import FormatHelper from 'utils/FormatHelper'
import Api from 'utils/Api'
import { toast } from 'react-toastify'
import LoadingComponent from 'components/LoadingComponent'
import { RecentRequests } from './RecentRequests'
import LoadingScreen from 'components/LoadingScreen'

export const DepositVATransfer: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [session] = useContext(SessionContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [banks, setBanks] = useState<Bank[]>()
  const { control, errors, handleSubmit } = useForm<DepositRequest & { method: string }>({
    mode: 'onChange',
    defaultValues: {
      type: 'va',
      bankId: process.env.NODE_ENV === 'development' ? 'demo' : 'bri',
      amount: NaN,
    },
  })

  const currency = account.currency

  const onSubmit = (req: DepositRequest) => {
    setLoading(true)
    Api.post<DepositRequest>(`/accounts/${account.id}/deposits/requests`, req)
      .then(res => {
        if (res.data.status !== 'failed') {
          toast.success(t('deposit.deposit_requested'))
        }
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    Api.get<Bank[]>(`/banks.public?feature=va`)
      .then(res => {
        setBanks(res.data)
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
  }, [])

  if (!currency) return null

  if (!(banks && banks.length)) return <LoadingScreen />

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller control={control} name="type" render={props => <input type="hidden" {...props} />} />
        <FormControlGrid fullWidth margin="normal" title={t('deposit.va_bank')}>
          <Controller
            control={control}
            name="bankId"
            render={props => (
              <RadioGroup row {...props}>
                {banks.map(bank => (
                  <FormControlLabel key={`dpva-bnk-${bank.id}`} value={bank.id} control={<Radio />} label={bank.name} />
                ))}
              </RadioGroup>
            )}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title={t('deposit.amount')}>
          <Controller
            control={control}
            name="amount"
            render={props => (
              <TextField
                {...props}
                placeholder={t('withdraw.amount_placeholder')}
                variant="outlined"
                fullWidth
                required
                value={props.value}
                defaultValue={''}
                onChange={e => {
                  props.onChange(parseInt(e.target.value))
                }}
                InputProps={{
                  inputProps: {
                    decimalScale: account.currency?.precision || 0,
                    max: 100000000,
                  },
                  inputComponent: AmountNumberFormat as any,
                  endAdornment: <InputAdornment position="end">{account.currency?.id}</InputAdornment>,
                }}
                FormHelperTextProps={{ error: true }}
                helperText={errors.amount?.message}
              />
            )}
            rules={{
              validate: val => {
                const minAmount = currency.attributes?.withdrawMin ?? 1
                const maxAmount = currency.attributes?.withdrawMax ?? 1000000000
                if (val && minAmount && maxAmount) {
                  if (val < minAmount) {
                    return t('errors.min_amount_required', { minAmount }).toString()
                  } else if (val > maxAmount) {
                    return t('errors.max_amount_exceeded', { maxAmount }).toString()
                  } else {
                    return undefined
                  }
                }
                return t('errors.input_required').toString()
              },
              valueAsNumber: true,
              // required: { value: true, message: t('errors.input_required') },
              // maxLength: { value: 15, message: t('errors.max_length_exceeded') },
              // max: { value: 10, message: t('errors.max_length_exceeded') },
            }}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title="">
          {t('deposit.va_instruction_confirmation')}
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title="">
          <Button variant="contained" color="primary" type="submit" disabled={loading || Boolean(Object.keys(errors).length !== 0)}>
            {t('common.submit')}
            {loading && '..'}
          </Button>
        </FormControlGrid>
      </form>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <RecentRequests account={account} type="va" load={!loading} />
    </>
  )
}
