import React from 'react'
import { Currency } from 'models'

export const CurrencyIcon: React.FunctionComponent<{
  currency?: Pick<Currency, 'id' | 'iconUrl'>
  size?: number
}> = ({ currency, size }) => {
  const width = size ?? 24
  return (
    <div
      style={{
        width,
        height: width,
        borderRadius: width / 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#999',
        // backgroundColor: '#bdbdbd',
        overflow: 'hidden',
        display: 'inline-block',
        // marginRight: 5
        backgroundImage: `url(${currency?.iconUrl})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
      }}
    >
      {currency && currency.iconUrl ? null : currency ? ( // /> //   style={{ width, height: width }} //   // style={{ width: width }} //   alt={currency.id} //   src={currency.iconUrl} // <img
        <>{currency.id}</>
      ) : (
        <></>
      )}
    </div>
  )
}
