import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import { FindInPage } from '@material-ui/icons'

export const EmptyComponent: React.FunctionComponent<{
  message?: string
  detail?: string
}> = ({ message, detail }) => {
  const { t } = useTranslation()

  return (
    <Box m={2} p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <FindInPage style={{ fontSize: 48, color: '#a9a9a9', margin: 5 }} />

      <Box maxWidth={320}>
        <Typography variant="h4" style={{ marginTop: 15, textAlign: 'center', fontSize: 14 }}>
          {message ?? t('common.empty_result')}.
        </Typography>
        <Typography
          style={{
            marginTop: 5,
            textAlign: 'center',
            color: '#999',
            fontSize: 12,
          }}
        >
          {detail ?? t('common.empty_result_detail')}.
        </Typography>
      </Box>
    </Box>
  )
}
