import { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import lodash from 'lodash'
import ApiErrorParser from './ApiErrorParser'

const DEFAULT_NUMBERFORMAT = Intl.NumberFormat('en-US', { style: 'decimal' })
const CURRENCIES_NUMBERFORMAT: { [k: string]: Intl.NumberFormat } = {
  IDR: Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'IDR',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  USD: Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  }),
  SGD: Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'SGD',
    currencyDisplay: 'code',
  }),
  MYR: Intl.NumberFormat(/*'ms-MY'*/ 'de-DE', {
    style: 'currency',
    currency: 'MYR',
    currencyDisplay: 'code',
  }),
  EUR: Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
  }),
  USDT: Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  }),
}

const FormatHelper = {
  currency: (currency: string, amount: number) => {
    return (CURRENCIES_NUMBERFORMAT[currency] ?? DEFAULT_NUMBERFORMAT).format(amount)
  },

  rate: (amount: number) => {
    return Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 8,
    }).format(amount)
    // return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  },

  amount: (amount: number | undefined, currency: string) => {
    if (typeof amount === 'undefined') return '--'

    return (CURRENCIES_NUMBERFORMAT[currency] ?? DEFAULT_NUMBERFORMAT).format(amount)
  },

  // numberFormat: (amount:number) => {
  // new Intl.NumberFormat(['en'], {style: 'currency', currency: , currencyDisplay: 'symbol', minimumFractionDigits: 0}).format(amount)
  // },
  humanDate: (date: Date) => {
    return dayjs(date).format('LL')
  },

  humanDateTime: (date: Date) => {
    return dayjs(date).format('LL LT')
  },

  error: (arg?: null | Error | string | AxiosError | AxiosResponse) => {
    // if (process.env.NODE_ENV === 'development') console.error(arg)

    if (arg instanceof Object && (arg as AxiosError).response) {
      return new ApiErrorParser(arg).toString()
    }

    if (arg instanceof Error) {
      return arg.message
    } else if (typeof arg === 'string') {
      return arg
    } else {
      return 'error'
    }
  },

  capitalize: (s: string) => {
    return lodash.capitalize(s)
  },

  maskEmail: (s: string) => {
    return s.replace(/^(.{2})(.*)(.{2}@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c)
  },

  secondToMMSS: (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

    return `${formattedMinutes}:${formattedSeconds}`
  },
}

export default FormatHelper
