import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Account, Beneficiary, BeneficiaryStatus, BeneficiaryType } from 'models'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import LoadingComponent from './LoadingComponent'

const BeneficiarySelect: React.FunctionComponent<{
  account: Account
  type?: BeneficiaryType
  value?: Beneficiary
  onChange: (value?: Beneficiary) => void
}> = ({ account, type, value, onChange, ...props }) => {
  const { t } = useTranslation()
  const [beneficiaries, setBeneficiaries] = React.useState<Beneficiary[]>()

  useEffect(() => {
    const where: any = {
      accountId: account.id,
      status: {
        neq: 'inactive',
      },
    }
    if (type) {
      where.type = type
    }
    Api.request<Beneficiary[]>({
      method: 'GET',
      url: `/beneficiaries`,
      params: {
        filter: {
          where,
          order: ['updatedTime DESC'],
        },
      },
    }).then(res => {
      onChange && onChange(undefined)
      setBeneficiaries(res.data.sort((a, b) => (a.status === BeneficiaryStatus.Verified ? -1 : 1)))
    })
  }, [account, type])

  if (typeof beneficiaries === 'undefined') return <LoadingComponent />

  return (
    <Autocomplete
      options={beneficiaries}
      getOptionLabel={option => `${option.bankId} ${option.address} ${option.name ? ` - ${option.name}` : ''} (${option.label})`}
      getOptionDisabled={option => option.status !== BeneficiaryStatus.Verified}
      fullWidth={true}
      renderInput={params => (
        <TextField
          {...params}
          label="-- Select --"
          variant="outlined"
          required
          // FormHelperTextProps={{ error: true }}
          // helperText={value ? '' : t('errors.required')}
        />
      )}
      onChange={(ev: any, v: Beneficiary | null) => {
        ev.persist()
        onChange(v ? v : undefined)
      }}
      {...props}
    />
  )
}

export default BeneficiarySelect
