import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Account, Currency } from 'models'
import { NewAccountForm } from './NewAccountForm'

export const NewAccountPage: React.FunctionComponent<any> = args => {
  const { t } = useTranslation()
  const history = useHistory()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  const onAccountCreated = (account: Account) => {
    history.replace('/accounts/' + account.id)
  }

  return (
    <Page title={t('account.create_new')} padded>
      <PaperBlock title={t('account.create_new')} desc={t('account.new_account_subtitle')} whiteBg={true}>
        <NewAccountForm onAccountCreated={onAccountCreated} />
      </PaperBlock>
    </Page>
  )
}
