import { Box, Button, Typography } from '@material-ui/core'
import { AppContext } from 'contexts'
import { Account, Withdraw, WithdrawStatus } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Spinner from 'react-spinkit'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import { WithdrawDetailContent } from './WithdrawDetailContent'

let fetchTimer: any = -1
export const WithdrawProcessing: React.FunctionComponent<{
  account: Account
  withdraw: Withdraw
  onWithdrawUpdated: (withdraw: Withdraw | undefined) => void
}> = ({ account, withdraw, onWithdrawUpdated }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { handleError } = useContext(AppContext)
  const [updatedWithdraw, setUpdatedWithdraw] = useState<Withdraw>(withdraw)

  const refreshWithdrawStatus = () => {
    Api.request<Withdraw>({
      method: 'GET',
      url: `/accounts/${withdraw.accountId}/withdraw/${withdraw.id}`,
    })
      .then(res => {
        if (res.data) {
          setUpdatedWithdraw(res.data)
          // onWithdrawUpdated(res.data)
          if ((withdraw.status === WithdrawStatus.PENDING || withdraw.status === WithdrawStatus.PROCESS) && res.data.status === WithdrawStatus.SUCCEED) {
            toast.success(t('withdraw.withdraw_succeed'))
          } else if (withdraw.status === WithdrawStatus.PROCESS && res.data.status === WithdrawStatus.SENT) {
            toast.info(t('withdraw.withdraw_sent'))
          } else if (res.data.status === WithdrawStatus.FAILED || res.data.status === WithdrawStatus.UNSENT || res.data.status === WithdrawStatus.DECLINED) {
            handleError(t('withdraw.withdraw_failed'))
            onWithdrawUpdated(res.data)
          } else {
            fetchTimer = setTimeout(refreshWithdrawStatus, 3000)
          }
        }
      })
      .catch(handleError)
  }

  useEffect(() => {
    refreshWithdrawStatus()

    return () => {
      if (fetchTimer !== -1) {
        clearTimeout(fetchTimer)
        fetchTimer = -1
      }
    }
  }, [])

  if (
    updatedWithdraw &&
    [
      WithdrawStatus.PENDING,
      WithdrawStatus.UNSENT,
      WithdrawStatus.DECLINED,
      WithdrawStatus.SENT,
      WithdrawStatus.SUCCEED,
      WithdrawStatus.OBSCURE,
      WithdrawStatus.FAILED,
    ].includes(updatedWithdraw.status)
  ) {
    return (
      <Box>
        <WithdrawDetailContent withdraw={{ ...updatedWithdraw, account }} />
        <Box m={2} textAlign={'center'}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // onWithdrawUpdated(updatedWithdraw)
              onWithdrawUpdated(undefined)
            }}
          >
            {t('common.close')}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box justifyContent="center" alignContent="center" alignItems="center" flexDirection="column" flex={1} display="flex">
      <Box m={10} alignItems="center" flexDirection="column">
        <Spinner name="line-scale" color="blue" style={{ transform: 'scale(2)' }} />
      </Box>
      <Box alignItems="center" textAlign="center">
        <Typography variant="body1" gutterBottom>
          Submitting withdraw...
        </Typography>
        <Typography variant="caption">(ref: {withdraw.id})</Typography>
      </Box>
    </Box>
  )
}
