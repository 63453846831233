import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CallbacksTable } from './CallbacksTable'

export const CallbacksPage: React.FunctionComponent<{}> = () => {
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()

  const [isProfileEditing, setIsProfileEditing] = useState<boolean>(false)

  if (!session.user) return null
  const { user } = session

  return (
    <Page title={t('callbacks.callbacks')} padded>
      <PaperBlock title={t('callbacks.callbacks')} desc={t('callbacks.callbacks_description')}>
        <CallbacksTable />
      </PaperBlock>
    </Page>
  )
}
