import { Typography, Button, Box, Paper } from '@material-ui/core'
import CurrencySelect from 'components/CurrencySelect'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import { Account, Currency } from 'models'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'

export const NewAccountForm: React.FunctionComponent<{
  currency?: Currency
  onAccountCreated?: (account: Account) => void
}> = ({ currency, onAccountCreated }) => {
  const { t } = useTranslation()
  const { flash, handleError } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const [loading, setLoading] = useState<boolean>(false)

  const [newCurrency, setNewCurrency] = useState<Currency | undefined>(currency)

  const postNewAccount = () => {
    if (!newCurrency) return Promise.reject()
    setLoading(true)
    return Api.request<Account>({
      method: 'POST',
      url: `/users/${session.user?.id}/accounts`,
      data: {
        // userId: session.user?.id,
        currencyId: newCurrency.id,
      },
    })
      .then(res => {
        setLoading(false)
        flash(t('account.new_account_created'))
        onAccountCreated && onAccountCreated(res.data)
      })
      .catch(err => {
        setLoading(false)
        handleError(err)
      })
  }

  const onCreateNewAccount = () => {
    postNewAccount()
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box flex={1}>
        <Box m={5} display="flex" flexDirection="column">
          {/* <Typography>{t('account.create_new_tips')}</Typography> */}

          {/* <Box marginY={1} /> */}

          <CurrencySelect value={newCurrency} onChange={setNewCurrency} />

          <Box marginY={1} />
          <Button variant="contained" color="primary" onClick={onCreateNewAccount} disabled={loading}>
            {t('account.create_new')}
          </Button>

          <Box marginY={2} />

          {loading && <LoadingComponent message={t('common.please_wait')} />}
        </Box>
      </Box>
      <Box flex={1}>
        <Paper elevation={3}>
          <Box p={2} minHeight={260}>
            <Typography gutterBottom>{t('account.create_new_tips')}</Typography>
            <br />
            <Typography>{t('account.create_new_tips2')}</Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
