import LoadingScreen from 'components/LoadingScreen'
import React, { Dispatch, PropsWithChildren, createContext, useEffect, useState } from 'react'
import Api from 'utils/Api'
import { StorageManager } from 'utils/StorageManager'
import { useReducerAsync } from 'utils/useReducerAsync'
import { SessionActionType, SessionType, sessionActionHandlers, sessionReducer } from './reducers/SessionReducer'

const storageManager = new StorageManager()

const initialState: SessionType = {
  loading: true,
  accessToken: storageManager.load('accessToken'),
  user: null,
  role: null,
}

export type AuthorizedParams = {
  roles?: string[]
  resource?: string
  scope?: string
  scopes?: string[]
}

const SessionContext = createContext<[SessionType, Dispatch<SessionActionType>]>([initialState, () => null])

const SessionProvider: React.FunctionComponent<{
  children?: React.ReactNode
}> = ({ children }: PropsWithChildren<unknown>) => {
  const [initializing, setInitialiizing] = useState<boolean>(true)
  const [state, dispatch] = useReducerAsync(sessionReducer, initialState, sessionActionHandlers)

  useEffect(() => {
    if (state.accessToken) {
      storageManager.save('accessToken', state.accessToken)
      Api.defaults.headers.common['Authorization'] = 'Bearer ' + state.accessToken
      setInitialiizing(false)
    } else {
      storageManager.remove('accessToken')
      if (!window.location.pathname.startsWith('/auth') && !window.location.pathname.startsWith('/verify')) {
        window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
      }
      setInitialiizing(false)
      dispatch({ type: 'LOADING', loading: false })
      return
    }

    dispatch({ type: 'FETCH_USER_ME' })

    // setLoading(true)
    // Api.request({
    //   method: 'GET',
    //   url: '/users/me'
    // })
    //   .then(({ data }) => {
    //     dispatch({ type: 'SET_USER', payload: data })
    //     setLoading(false)
    //   })
    //   .catch((err) => {
    //     // setLoading(false)
    //     dispatch({ type: 'SET_USER', payload: null })
    //     if (
    //       err &&
    //       err.response &&
    //       (err.response.status === 401 || err.response.status === 404) &&
    //       window.location.pathname.indexOf('/auth') !== 0
    //     ) {
    //       window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
    //     } else if (window.location.pathname.indexOf('/auth') === 0) {
    //       setLoading(false)
    //     }
    //   })
  }, [state.accessToken])

  return (
    <SessionContext.Provider value={[state, dispatch]}>
      {initializing ? (
        <>
          <LoadingScreen />
          init..
        </>
      ) : (
        children
      )}
    </SessionContext.Provider>
  )
}

export { SessionContext, SessionProvider }
