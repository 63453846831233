import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CurrencyFormat from 'components/CurrencyFormat'
import { CurrencyIcon } from 'components/CurrencyIcon'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import { SessionContext } from 'contexts/SessionContext'
import MaterialTable from 'material-table'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const DashboardPage: React.FunctionComponent<{}> = ({}) => {
  const { t } = useTranslation()
  const [accounts] = useContext(AccountContext)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1200)
  }, [])

  const isNew = Boolean(!(accounts && accounts.length > 0))

  return (
    <Page title={t('dashboard.title')} padded>
      <PaperBlock
        title={t('dashboard.title')}
        desc={t('dashboard.subtitle')}
        // desc={session.accessToken ?? ''}
        whiteBg={true}
        ext={<Box flexDirection="row" display="flex"></Box>}
        loading={loading}
      >
        {isNew ? <WelcomeContent /> : <AccountsList />}
      </PaperBlock>
    </Page>
  )
}

const WelcomeContent: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box margin={5}>
        <img src={'/static/images/not_found.png'} height={240} />
      </Box>
      <Typography variant="h3" gutterBottom>
        {t('dashboard.welcome_text')}
      </Typography>
      <Typography variant="body1">{t('dashboard.welcome_subtext')}</Typography>
      <Box margin={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/accounts/new')
          }}
        >
          {t('dashboard.get_started')}
        </Button>
      </Box>
    </Box>
  )
}

const AccountsList: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  if (!accounts) return null

  return (
    <Grid container>
      {accounts.map(account => (
        <Grid item key={`dsb-acc-${account.id}`} md={6} xs={12}>
          <Paper
            onClick={() => {
              history.push(`/accounts/${account.id}`)
            }}
            style={{ padding: 10, margin: 10 }}
          >
            <Box display="flex" alignItems="center">
              <CurrencyIcon currency={account.currency} size={30} />
              <Box marginLeft={2}>
                <Box>
                  <Typography component="span">Acc no: {account.number}</Typography>
                </Box>
                <Box>
                  <React.Fragment>
                    <Typography component="span" variant="body2" color="textSecondary">
                      Balance: <CurrencyFormat currency={account.currency} value={account.balance} />
                    </Typography>
                  </React.Fragment>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

const AccountsTable: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  if (!accounts) return null

  return (
    <MaterialTable
      title={''}
      columns={[
        { field: 'id', hidden: true },
        {
          title: t('account.currency'),
          field: 'currencyId',
          render: row => (
            <>
              <CurrencyIcon currency={row.currency} size={20} />
              <Typography component="span" style={{ marginLeft: 10 }}>
                {row.currencyId}
              </Typography>
            </>
          ),
        },
        { title: t('account.number'), field: 'number' },
        {
          title: t('account.locked'),
          field: 'locked',
          align: 'right',
          // render: (row) => FormatHelper.amount(row.balance, row.currencyId)
          render: row => <CurrencyFormat currency={row.currency} value={row.locked} />,
        },
        {
          title: t('account.balance'),
          field: 'balance',
          align: 'right',
          render: row => <CurrencyFormat currency={row.currency} value={row.balance} />,
        },
      ]}
      data={accounts.filter(a => resourceState.currencies.findIndex(c => c.id === a.currencyId) !== -1)}
      onRowClick={(_, row) => {
        if (row) {
          history.push(`/accounts/${row.id}`)
        }
      }}
      options={{
        paging: false,
        search: false,
        toolbar: false,
      }}
    />
  )
}

const KYCRequiredContent: React.FC = () => {
  const history = useHistory()
  const [session, dispatch] = useContext(SessionContext)
  // const sessionContext = useContext(SessionContext)
  const { t } = useTranslation()

  // if (sessionContext.user && sessionContext.user.level >= 3) return null

  let message: string = t('profile.please_kyc_instruction')
  // "pleaseWaitForVerificationMax1x24hours": "Please wait for verification, max 3 x 24 hours",
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Alert
          elevation={6}
          severity="warning"
          variant="standard"
          onClose={() => {}}
          style={{ marginTop: 30, marginBottom: 30 }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // history.push('/account/verification')
                dispatch({ type: 'FETCH_USER_ME' })
              }}
            >
              {t('profile.verify_account')}
            </Button>
          }
        >
          <span style={{}}>{message}</span>
        </Alert>
      </Grid>
    </Grid>
  )
}
