import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import { FormControlGrid } from 'components/FormControlGrid'
import LoadingComponent from 'components/LoadingComponent'
import { SessionContext } from 'contexts/SessionContext'
import lodash from 'lodash'
import { Account, Bank } from 'models'
import { DepositRequest } from 'models/DepositRequest'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import { RecentRequests } from './RecentRequests'

export const DepositManualTransfer: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [session] = useContext(SessionContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [showTransfer, setShowTransfer] = useState<DepositRequest>()
  const [banks, setBanks] = useState<Bank[]>()
  const { control, errors, handleSubmit, watch, reset, getValues } = useForm<DepositRequest & { method: string }>({
    mode: 'onChange',
    defaultValues: {
      type: 'transfer',
      bankId: '',
      amount: NaN,
      addressName: '',
    },
  })
  const bankId = watch('bankId')

  const onContactWhatsapp = () => {
    const link =
      `https://api.whatsapp.com/send?phone=628118202280&text=` +
      encodeURIComponent(
        `Hi Pazemo, i'd like to make deposit confirmation\nUser email: ${session.user?.email}\n${lodash.upperCase(account.currencyId)} account number: ${
          account.number
        }`,
      )

    //window.location.href = link;
    // window.location.assign(link)
    window.open(link, '_blank')
  }

  const onSubmit = (req: DepositRequest) => {
    setLoading(true)
    reset({ bankId: getValues().bankId, amount: NaN, addressName: '' })
    Api.post<DepositRequest>(`/accounts/${account.id}/deposits/requests`, req)
      .then(res => {
        if (res.data.status !== 'failed') {
          // toast.success(t('deposit.deposit_requested'))
          setShowTransfer(res.data)
        }
      })
      .catch(err => {
        if (err?.message.search("can't be blank")) {
          toast.error(t("errors.select_bank_transfer"))
        } else {
          toast.error(FormatHelper.error(err))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    Api.get<Bank[]>(`/banks.public?feature=transfer`)
      .then(res => {
        setBanks(res.data)
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller control={control} name="type" render={props => <input type="hidden" {...props} />} />
        <>
          <Paper>
            <Box p={2}>
              <Chip label="1" /> {t('deposit.please_transfer_to')}
            </Box>
          </Paper>
          <Box>
            <FormControlGrid fullWidth margin="normal" title={t('deposit.bank')}>
              <Controller
                control={control}
                name="bankId"
                render={props => (
                  <RadioGroup row {...props}>
                    {banks?.map(bank => (
                      <FormControlLabel key={`dpmt-bnk-${bank.id}`} value={bank.id} control={<Radio />} label={bank.name} />
                    ))}
                  </RadioGroup>
                )}
              />
            </FormControlGrid>
            <ManualTransferInstruction account={account} bankId={bankId} />
          </Box>
        </>

        <Box marginBottom={5} />

        <Paper square>
          <Box p={2}>
            <Chip label="2" /> {t('deposit.manual_transfer_confirm')}
          </Box>
        </Paper>
        <Grid container>
          <Grid item xs={12} md={12}>
            <FormControlGrid fullWidth margin="normal" title={t('deposit.transferred_amount')}>
              <Controller
                control={control}
                name="amount"
                render={props => (
                  <TextField
                    {...props}
                    placeholder={t('withdraw.amount_placeholder')}
                    // label="What label"
                    variant="outlined"
                    fullWidth
                    required
                    value={props.value}
                    defaultValue={''}
                    onChange={e => {
                      props.onChange(parseInt(e.target.value))
                    }}
                    InputProps={{
                      inputProps: {
                        decimalScale: account.currency?.precision || 0,
                        max: 100000000,
                      },
                      inputComponent: AmountNumberFormat as any,
                      endAdornment: <InputAdornment position="end">{account.currency?.id}</InputAdornment>,
                    }}
                    FormHelperTextProps={{ error: Boolean(errors.amount) }}
                    helperText={errors.amount?.message || 'Please to input exact amount that has been transferred'}
                  />
                )}
                rules={{
                  valueAsNumber: true,
                }}
              />
            </FormControlGrid>
            <FormControlGrid fullWidth margin="normal" title="Sender name">
              <Controller
                control={control}
                name="addressName"
                render={props => <TextField {...props} variant="outlined" fullWidth defaultValue="" required={true} />}
              />
            </FormControlGrid>
            <FormControlGrid fullWidth margin="normal" title="">
              <Button variant="contained" color="primary" type="submit" disabled={loading || Boolean(Object.keys(errors).length !== 0)}>
                {t('common.submit')}
                {loading && '..'}
              </Button>
            </FormControlGrid>
          </Grid>

          <Grid item md={12}>
            <RecentRequests
              account={account}
              type="transfer"
              load={!loading}
              onOpen={r => {
                setShowTransfer(r)
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={5}>
        <Chip label="2" /> {t('deposit.manual_transfer_upload_instruction')}
        <Box marginTop={[2, 4]} marginBottom={[2, 5]} p={[1, 2]}>
          <Button variant="contained" onClick={onContactWhatsapp} startIcon={<WhatsApp />} style={{ backgroundColor: '#25D366' }}>
            WA +62-8118-2022-80
          </Button>
        </Box>
        <Box>
          {t('deposit.manual_transfer_contact_notes')}
          <ul style={{ paddingLeft: 20 }}>
            <li>{t('deposit.manual_transfer_contact_notes1')}</li>
            <li>{t('deposit.manual_transfer_contact_notes2')}</li>
          </ul>
        </Box>
      </Grid> */}
        </Grid>
      </form>

      <TransferDialog
        depositRequest={showTransfer}
        open={showTransfer ? true : false}
        onClose={() => {
          setShowTransfer(undefined)
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
          }, 200)
        }}
      />
    </>
  )
}

const ManualTransferInstruction: React.FunctionComponent<{ account: Account; bankId: string }> = ({ account, bankId }) => {
  const { t } = useTranslation()
  const [session] = useContext(SessionContext)

  return (
    <>
      {bankId !== '' ? ( 
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{t('deposit.transfer_bank_destination')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          {bankId === 'bca' ? (
            <Typography variant="body1">
              <>
                <strong>BCA</strong>
                <br />
                Bank Code: 014
                <br />
                SWIFT Code: CENAIDJA
                <br />
                Jakarta, Indonesia
                <br />
              </>
            </Typography>
          ) : bankId === 'bni' ? (
            <Typography variant="body1">
              <>
                <strong>BNI</strong>
                <br />
                Bank Code: 009
                <br />
                SWIFT Code: BNINIDJA
                <br />
                Jakarta, Indonesia
                <br />
              </>
            </Typography>
          ) : bankId === 'bri' ? (
            <Typography variant="body1">
              <>
                <strong>BRI</strong>
                <br />
                Bank Code: 002
                <br />
                SWIFT Code: BRINIDJA
                <br />
                Jakarta, Indonesia
                <br />
              </>
            </Typography>
          ) : bankId === 'mandiri' ? (
            <Typography variant="body1">
              <>
                <strong>MANDIRI</strong>
                <br />
                Bank Code: 008
                <br />
                SWIFT Code: BMRIIDJA
                <br />
                Jakarta, Indonesia
                <br />
              </>
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{t('deposit.transfer_bank_destination')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          {bankId === 'bca' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              1066-2677-77
            </Typography>
          ) : bankId === 'bni' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              3-688-368-877
            </Typography>
          ) : bankId === 'bri' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              2074-01-000371-30-1
            </Typography>
          ) : bankId === 'mandiri' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              1260-00969-8977
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5">{t('deposit.transfer_bank_destination_account')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          {bankId === 'bca' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              GIAT BANGUN INDONESIA PT
            </Typography>
          ) : bankId === 'mandiri' ? (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              PT GIAT BANGUN INDONESIA
            </Typography>
          ) : (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              PT. GIAT BANGUN INDONESIA
            </Typography>
          )}
        </Grid>
      </Grid>
       ) : "" }
      <Box marginBottom={2} />
      <Typography variant="body2" gutterBottom>
        <strong>{t('deposit.manual_instruction_caution')}</strong>
      </Typography>
      <Typography variant="body2">{t('deposit.manual_instruction_caution_fee')}</Typography>
    </>
  )
}

let fetchTimer: any = -1
const TransferDialog: React.FunctionComponent<DialogProps & { depositRequest?: DepositRequest }> = ({ depositRequest, onClose, ...props }) => {
  const { t } = useTranslation()

  const refreshStatus = () => {
    if (!(depositRequest && depositRequest.id)) return

    return Api.get(`/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}`).then(res => {
      if ((depositRequest.status === 'pending' || depositRequest.status === 'authorized') && res.data.status === 'captured') {
        toast.success('Payment success')
        onClose && onClose({}, 'backdropClick')
      } else if (res.data.status === 'declined') {
        toast.warn('Request declined')
        onClose && onClose({}, 'backdropClick')
      } else {
        fetchTimer = setTimeout(refreshStatus, 5000)
      }
    })
  }

  useEffect(() => {
    refreshStatus()

    return () => {
      if (fetchTimer !== -1) {
        clearTimeout(fetchTimer)
        fetchTimer = -1
      }
    }
  }, [depositRequest])

  if (!(depositRequest && depositRequest.id)) return <></>

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={(e, r) => {
        clearTimeout(fetchTimer)
        fetchTimer = -1
        onClose && onClose(e, r)
      }}
      {...props}
    >
      <DialogContent>
        <LoadingComponent message={t('deposit.waiting_for_approval') + '..'} />
      </DialogContent>
    </Dialog>
  )
}
