import { Box, Button, Chip, colors, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import LoadingComponent from 'components/LoadingComponent'
import dayjs from 'dayjs'
import { Account } from 'models'
import { DepositRequest } from 'models/DepositRequest'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

export const RecentRequests: React.FunctionComponent<{ account: Account; type: string; load: boolean; onOpen?: (row: DepositRequest) => void }> = ({
  account,
  type,
  load,
  onOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [recents, setRecents] = useState<DepositRequest[]>()

  const fetchRecents = () => {
    setLoading(true)
    Api.get<DepositRequest[]>(`/accounts/${account.id}/deposits/requests`, {
      params: {
        filter: {
          where: { type, createdTime: { gt: dayjs().subtract(1, 'week') } },
          limit: 20,
          order: ['createdTime DESC'],
          include: [{ relation: 'account' }, { relation: 'bank' }],
        },
      },
    })
      .then(res => {
        setRecents(res.data)
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!load) return
    fetchRecents()
  }, [load])

  return (
    <>
      <Box marginTop={5}>
        <Box display={'flex'} flexDirection={'row'}>
          <Box>
            <Typography variant="h3">Recent requests</Typography>
          </Box>
          <Box>
            <Button
              startIcon={<Refresh />}
              onClick={() => {
                fetchRecents()
              }}
            ></Button>
          </Box>
        </Box>
        <Box>
          {loading ? (
            <LoadingComponent />
          ) : (
            <List>
              {recents
                ? recents.map(recent => (
                    <ListItem key={`dp-rc-t-${recent.id}`} style={{ borderBottom: '1px solid #f9f9f9' }} divider>
                      <Grid container>
                        <Grid item md={2}>
                          <ListItemText>{FormatHelper.humanDateTime(recent.createdTime)}</ListItemText>
                        </Grid>
                        <Grid item md={7}>
                          {recent.type === 'va' ? (
                            <Typography variant="h4">
                              VA Number: <strong>{recent.address || '--  '}</strong>
                            </Typography>
                          ) : recent.type === 'qris' ? (
                            <>{/* {recent.address} */}</>
                          ) : null}
                          <ListItemText>Amount: {FormatHelper.amount(recent.amount, account.currencyId)}</ListItemText>
                          <ListItemText>
                            Via {recent.type === 'va' ? `VA Transfer ${recent.bankId}` : recent.type == 'qris' ? 'QRIS' : recent.type}
                          </ListItemText>
                          {/* <ListItemText>
                        to account {recent.account?.currency} {recent.account?.number}
                      </ListItemText> */}
                          {/* {JSON.stringify(recent)} */}
                        </Grid>

                        <Grid item md={3} justifyContent="flex-end">
                          {onOpen ? (
                            <Button
                              style={{ marginRight: 10, borderRadius: 16 }}
                              variant="text"
                              onClick={() => {
                                onOpen(recent)
                              }}
                            >
                              <DepositRequestChip depositRequest={recent} />
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))
                : null}
            </List>
          )}
        </Box>
      </Box>
    </>
  )
}

export const DepositRequestChip: React.FunctionComponent<{ depositRequest: DepositRequest; size?: 'small' | 'medium' }> = ({ depositRequest, size }) => {
  const color = 'default'

  let bgColor = '#333'
  if (depositRequest.status === 'failed') {
    bgColor = colors.red[500]
  } else if (depositRequest.status === 'captured') {
    bgColor = colors.green[500]
  }

  return <Chip variant="outlined" label={depositRequest.status} color={color} style={{ color: bgColor }} size={size || 'medium'} />
}
