import React, { useContext, useEffect, useState } from 'react'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { useTranslation } from 'react-i18next'
import { AccountContext } from 'contexts/AccountContext'
import { Box, Button, FormControl, Grid, InputAdornment, makeStyles, MenuItem, Select, TextField, TextFieldProps, Typography } from '@material-ui/core'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import { Currency, Account, Transfer, Transaction } from 'models'
import { AppContext, ResourceContext } from 'contexts'
import Api from 'utils/Api'
import { ForexRate } from 'models/ForexRate'
import FormatHelper from 'utils/FormatHelper'
import { SwapVert } from '@material-ui/icons'
import LoadingComponent from 'components/LoadingComponent'
import { flashVariantIcon } from 'components/flash/FlashContentWrapper'
import { useHistory } from 'react-router'
import { isArray } from 'lodash'
import { EmptyComponent } from 'components/EmptyComponent'

export const ExchangePage: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Page title={t('exchange.title')} padded>
      <PaperBlock title={t('exchange.title')} desc={t('exchange.subtitle')}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} xl={8}>
            <ExchangeContent />
          </Grid>
        </Grid>
      </PaperBlock>
    </Page>
  )
}

export const ExchangeContent: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { handleError, flash } = useContext(AppContext)
  const history = useHistory()
  const [accounts] = useContext(AccountContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [fromAccount, setFromAccount] = useState<Account | undefined>(accounts && accounts.length > 0 ? accounts[0] : undefined)
  const [fromAmount, setFromAmount] = useState<number>()

  const [toAccount, setToAccount] = useState<Account>()
  const [toAmount, setToAmount] = useState<number>()
  const [forexRates, setForexRates] = useState<ForexRate[]>()
  const [rate, setRate] = useState<number>()

  const fetchRates = () => {
    Api.request<ForexRate[]>({
      method: 'GET',
      url: '/forex-rates/latest',
    })
      .then(({ data }) => {
        setForexRates(data)
      })
      .catch(handleError)
  }

  const swap = () => {
    setFromAccount(toAccount)
    setToAccount(fromAccount)
    setFromAmount(toAmount)
    setToAmount(fromAmount)
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    setLoading(true)
    Api.request<{
      debitTransaction: Transaction
      creditTransaction: Transaction
    }>({
      method: 'POST',
      url: `/exchange`,
      data: {
        fromAccountId: fromAccount?.id,
        toAccountId: toAccount?.id,
        amount: fromAmount,
        // receiverAmount: toAmount
      },
    })
      .then(({ data }) => {
        setLoading(false)
        flash(t('exchange.exchange_succeed'))
        if (data && data.creditTransaction) {
          history.push(`/accounts/${data.creditTransaction.accountId}`)
        }
      })
      .catch(err => {
        setLoading(false)
        handleError(err)
      })
  }

  useEffect(() => {
    if (forexRates && fromAccount && toAccount) {
      const fr = forexRates.find(fr => fr.base === fromAccount.currencyId && fr.currencyId === toAccount.currencyId)
      if (fr) {
        setRate(fr.rate)
      } else {
        setRate(undefined)
      }
    }
  }, [forexRates, fromAccount, toAccount])

  useEffect(() => {
    fetchRates()
  }, [fromAccount, toAccount])

  useEffect(() => {
    if (accounts && accounts.length > 1 && fromAccount) {
      if (!toAccount) {
        setToAccount(accounts.filter(a => a.id !== fromAccount?.id)[0])
      } else if (toAccount && fromAccount && toAccount.id === fromAccount.id) {
        setToAccount(accounts.filter(a => a.id !== fromAccount?.id)[0])
      }
    }
  }, [fromAccount])

  useEffect(() => {
    if (rate && fromAmount) {
      setToAmount(Number(fromAmount) * rate)
    } else {
      setToAmount(undefined)
    }
  }, [fromAmount, rate])

  if (!(accounts && accounts.length > 1))
    return <EmptyComponent message={t('errors.no_exchange_pair_available')} detail={t('errors.no_exchange_pair_available_detail')} />

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid container>
        {/* <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4">{t('exchange.exchange')}</Typography>
          </Grid>
        </Grid> */}

        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <AccountInputAmount
                label={t('exchange.from')}
                accounts={accounts}
                amount={fromAmount}
                account={fromAccount}
                onChangeAmount={(amount, account) => {
                  setFromAccount(account)
                  setFromAmount(amount)
                }}
                disabled={loading}
              />
              <Box color="#999">
                {t('account.balance')}: {fromAccount && FormatHelper.amount(fromAccount.balance, fromAccount.currencyId)}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box marginTop={[1, 2]} marginBottom={[1, 2]} color="#999" textAlign="right">
              {rate ? (
                <>
                  {t('exchange.rate')} : {FormatHelper.rate(rate)}
                </>
              ) : (
                <>{t('errors.no_rate')}</>
              )}
              <Button onClick={swap}>
                <SwapVert style={{ fontSize: 16, marginRight: 5 }} />
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <AccountInputAmount
                label={t('exchange.to')}
                accounts={accounts.filter(a => a.id !== fromAccount?.id)}
                amount={toAmount}
                account={toAccount}
                onChangeAmount={(amount, account) => {
                  setToAccount(account)
                  setToAmount(amount)
                }}
                disabled={loading}
              />
              {/* <Box color="#999">
                {t('account.balance')}:{' '}
                {toAccount &&
                  FormatHelper.amount(toAccount.balance, toAccount.currencyId)}
              </Box> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box marginTop={4}>
              <Button variant="contained" color="primary" type="submit" disabled={loading}>
                {t('exchange.exchange')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export const AccountInputAmount: React.FunctionComponent<
  {
    accounts: Account[]
    amount?: number
    account?: Account
    onChangeAmount: (amount: number, account?: Account) => void
  } & TextFieldProps
> = ({ accounts, amount, account, onChangeAmount, ...props }) => {
  const classes = useStyles()

  return (
    <AmountCurrencySelectInput
      variant="outlined"
      currency={account ? account.currency : accounts[0].currency}
      currencies={accounts.map(a => a.currency!)}
      className={classes.amountControl}
      value={amount}
      onChangeAmount={(amount, currency) => {
        onChangeAmount(
          amount,
          accounts.find(a => a.currencyId === currency?.id),
        )
      }}
      onChangeCurrency={currency => {
        onChangeAmount(
          amount ?? 0,
          accounts.find(a => a.currencyId === currency?.id),
        )
      }}
      {...props}
    />
  )
}

const AmountCurrencySelectInput: React.FunctionComponent<
  TextFieldProps & {
    currency?: Currency
    currencies: Currency[]
    onChangeAmount: (amount: number, currency?: Currency) => void
    onChangeCurrency: (currency?: Currency) => void
  }
> = ({ currency, currencies, onChangeAmount, onChangeCurrency, ...props }) => {
  if (!currency) {
    currency = currencies[0]
  }

  return (
    <FormControl margin="normal">
      <TextField
        variant="outlined"
        size="medium"
        value={props.value}
        // error={errors.amount ? true : false}
        // helperText={errors.amount?.message ?? ''}
        // onBlur={props.onBlur}
        onChange={e => {
          onChangeAmount(
            parseFloat(e.target.value),
            currencies.find(c => c.id === currency?.id),
          )
          // props.onChange(e.target.value)
        }}
        InputProps={{
          inputProps: {
            decimalScale: currency ? currency.precision : 0,
          },
          inputComponent: AmountNumberFormat as any,
          // endAdornment: (
          //   <InputAdornment position="end">
          //     {currency?.id.toUpperCase()}
          //   </InputAdornment>
          // )
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={currency?.id}
                onChange={e => {
                  const nCurrency = e.target.value as string
                  // setPickCurrency(nCurrency ?? undefined)
                  onChangeCurrency(currencies.find(c => c.id === nCurrency))
                }}
                name="suffix"
                style={{ minWidth: 80 }}
                // disabled={props.disabled}
              >
                {currencies.map(currency => (
                  <MenuItem key={`aci-tf-ia-s-${currency.id}-${currency.id}`} value={currency.id}>
                    {currency.id}
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  instructionTitle: {
    fontSize: 18,
  },
  countryControl: {
    minWidth: 320,
  },
  currencyControl: {
    minWidth: 320,
  },
  amountControl: {
    minWidth: 320,
  },
}))
