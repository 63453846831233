import { Typography, Chip, Avatar, Box, makeStyles, FormControlLabel } from '@material-ui/core'
import { AccountContext } from 'contexts/AccountContext'
import React, { FunctionComponent, useContext } from 'react'
import FormatHelper from 'utils/FormatHelper'
import { Account } from 'models'
import { useTranslation } from 'react-i18next'
import LoadingComponent from 'components/LoadingComponent'
import { CurrencyIcon } from 'components/CurrencyIcon'
import { ResourceContext } from 'contexts'
import CurrencyFormat from 'components/CurrencyFormat'

export const MyAccountsSelection: FunctionComponent<{
  onSelectAccount: (account?: Account) => void
  account?: Account
  title?: string
}> = ({ onSelectAccount, account, title }) => {
  return <MyAccountsSelectionInner onSelectAccount={onSelectAccount} account={account} title={title} />
}

export const MyAccountsSelectionInner: FunctionComponent<{
  onSelectAccount: (account?: Account) => void
  account?: Account
  title?: string
}> = ({ onSelectAccount, account: selectedAccount, title }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  if (typeof accounts === 'undefined') return <LoadingComponent />

  return (
    <Box marginY={1}>
      <Box>
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      </Box>
      {!(accounts !== undefined && accounts.length > 0) && <Typography>{t('transfer.no_accounts_available')}</Typography>}
      <Box marginTop={1} display="flex" flexDirection="row">
        {accounts
          .filter(a => Boolean(resourceState.currencies.findIndex(c => c.id == a.currencyId) !== -1))
          .map(account => (
            <Chip
              key={`acc-${account.id}`}
              style={{
                marginRight: 15,
                borderWidth: 3,
                borderRadius: 15,
                borderColor: selectedAccount && selectedAccount.id === account.id ? '#3f51b5' : '#ccc',
                // backgroundColor:
                //   selectedAccount && selectedAccount.id === account.id
                //     ? '#3f51b5'
                //     : '#fff',
                // color:
                //   selectedAccount && selectedAccount.id === account.id
                //     ? '#fff'
                //     : '#333'
              }}
              className={selectedAccount && selectedAccount.id === account.id ? classes.chipActive : selectedAccount ? classes.chipInactive : classes.chip}
              variant="outlined"
              size="medium"
              // color={account.id === selectedAccount?.id ? 'primary' : 'default'}
              color={'default'}
              avatar={
                <span style={{ marginLeft: 5, borderColor: 'transparent' }}>
                  <CurrencyIcon currency={account.currency} />
                </span>
              }
              label={
                <Box display="flex" flexDirection="row">
                  {/* <Box>
                  {account.currencyId}
                  &nbsp;
                  {account.number}
                </Box> */}
                  <Box
                    style={{
                      marginLeft: 5,
                      fontWeight: selectedAccount && selectedAccount.id === account.id ? 'bold' : 'normal',
                    }}
                  >
                    <CurrencyFormat currency={account.currency} value={account.balance} />
                  </Box>
                </Box>
              }
              onClick={e => {
                onSelectAccount(account)
              }}
            />
          ))}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(2),
    // padding: theme.spacing(2)
    minHeight: 50,
    fontSize: 16,
    // marginRight: 15,
    borderWidth: 3,
    borderRadius: 15,
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
  },
  chipActive: {
    marginRight: theme.spacing(2),
    minHeight: 50,
    fontSize: 16,
    // backgroundColor: '#3f51b5',
    // borderColor: '#3f51b5',
    // color: '#fff',
    fontWeight: 'bold',
  },
  chipInactive: {
    minHeight: 50,
    opacity: 0.7,
    fontSize: 16,
  },
}))
