import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import { FindInPage } from '@material-ui/icons'
import Spinner from 'react-spinkit'

export const DevelopingComponent: React.FunctionComponent<{
  message?: string
  detail?: string
}> = ({ message, detail }) => {
  const { t } = useTranslation()

  return (
    <Box m={10} p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Spinner name="cube-grid" color="gray" style={{ transform: 'scale(4)' }} />

      <Box maxWidth={280}>
        <Typography variant="h4" style={{ marginTop: 80, textAlign: 'center', fontSize: 14 }}>
          {message ?? t('errors.on_development')}.
        </Typography>
        <Typography
          style={{
            marginTop: 5,
            textAlign: 'center',
            color: '#999',
            fontSize: 12,
          }}
        >
          {detail ?? t('errors.on_development_detail')}.
        </Typography>
      </Box>
    </Box>
  )
}
