import { Box, FormControl, FormControlLabelProps, FormControlProps, Grid, Typography } from '@material-ui/core'
import React from 'react'

export const FormControlGrid: React.FunctionComponent<FormControlProps & { title: string }> = ({ title, children, ...props }) => (
  <FormControl {...props}>
    <Grid container>
      <Grid item xs={12} md={3}>
        <Box minHeight={title ? 48 : 0} display="flex" alignItems="center">
          <Typography variant="h5">{title}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        {children}
      </Grid>
    </Grid>
  </FormControl>
)
