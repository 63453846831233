/* eslint react/jsx-key: 0 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableInstance } from 'react-table'
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography, Box } from '@material-ui/core'
import { EmptyComponent } from 'components/EmptyComponent'

export const TableMaterial: React.FunctionComponent<TableInstance<any> & { title?: string }> = ({
  title,
  rows,
  headerGroups,
  getTableProps,
  getTableBodyProps,
  prepareRow,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {rows && rows.length > 0 ? (
        <TableContainer>
          {/* <Toolbar></Toolbar> */}
          {title ? (
            <Box p={2}>
              <Typography variant="h5">{title} </Typography>
            </Box>
          ) : null}
          <Table style={{ tableLayout: 'auto' }} {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>

            {/* <TableFooter>{rows.length == 0 && <EmptyComponent />}</TableFooter> */}
          </Table>
        </TableContainer>
      ) : rows.length === 0 ? (
        <EmptyComponent />
      ) : null}
    </>
  )
}
