import { Account, Beneficiary, Transaction } from './'

export enum WithdrawStatus {
  UNAUTHORIZED = 'unauthorized',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  INQUIRIED = 'inquiried',
  PROCESS = 'process',
  DECLINED = 'declined',
  FAILED = 'failed',
  SENT = 'sent',
  UNSENT = 'unsent',
  SUCCEED = 'succeed',
  OBSCURE = 'obscure',
}

export class Withdraw {
  constructor(
    public id: string,
    public createdTime: Date,
    public updatedTime: Date,
    public accountId: string,
    public transactionId: number,
    public status: WithdrawStatus,
    public prefix: string,
    public address: string,
    public addressName: string,
    public amount: number,
    public feeAmount: number,
    public notes: string,
    public reference: string,
    public networkId: string,
    public statusMessage?: string,
    public account?: Account,
    public beneficiaryId?: string,
    public beneficiary?: Beneficiary,
    public transaction?: Transaction,
    public network?: any,
    public referenceData?: any,
  ) {}
}
