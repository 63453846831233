import React, { useContext, useEffect } from 'react'
import Api from 'utils/Api'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Currency } from 'models'
import LoadingComponent from './LoadingComponent'
import { ResourceContext } from 'contexts'
import { useTranslation } from 'react-i18next'

const CurrencySelect: React.FunctionComponent<{
  defaultValue?: Currency
  value?: Currency
  onChange: (value?: Currency) => void
  required?: true
}> = ({ defaultValue, value, onChange, required, ...props }) => {
  const { t } = useTranslation()
  const [resourceState] = useContext(ResourceContext)

  // if (typeof banks === 'undefined') return <LoadingComponent />

  return (
    <FormControl>
      <InputLabel id="currency-select">{t('account.select_currency')}</InputLabel>

      <Select
        labelId="currency-select"
        defaultValue={
          // defaultValue ? defaultValue.id : resourceState.currencies[0].id
          defaultValue
        }
        // variant="outlined"
        // label={t('account.select_currency')}
        style={{ minWidth: 260 }}
        onChange={e => {
          onChange(resourceState.currencies.find(c => c.id == e.target.value))
        }}
        required
        {...props}
      >
        {resourceState.currencies.map(currency => (
          <MenuItem key={`cur-sel-${currency.id}`} value={currency.id}>
            {currency.id} - {currency.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CurrencySelect
