import { AppBar, Box, Button, Collapse, Divider, FormControl, FormControlLabel, Grid, Switch, Tab, Tabs, TextField, TextFieldProps } from '@material-ui/core'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { AppContext } from 'contexts'
import { User, Account, Quote } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import { InquiryForm } from './InquiryForm'
import { ReceiverForm } from './ReceiverForm'
import { QuoteComponent } from './QuoteComponent'
import { AccountForm } from './AccountForm'
import { SessionContext } from 'contexts/SessionContext'
import { AccountSelect } from 'components/AccountSelect'
import { MyAccountsSelection } from './MyAccountsSelection'
import { RouteComponentProps, useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { AccountContext } from 'contexts/AccountContext'
import { FormControlGrid } from 'components/FormControlGrid'
import { Controller, useForm } from 'react-hook-form'

interface TransferConfirmInput {
  authorizationCode: string
}

export const TransferPage: React.FunctionComponent<RouteComponentProps<{ accountId?: string }>> = ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { accountId } = match.params

  const { flash, handleError } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const [accounts] = useContext(AccountContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [quote, setQuote] = useState<Quote>()
  const [receiver, setReceiver] = useState<User>()
  const [reference, setReference] = useState<string>()
  const [senderAccount, setSenderAccount] = useState<Account | undefined>(accounts?.find(a => a.id === accountId))
  const [receiverAccount, setReceiverAccount] = useState<Account>()
  const { control, handleSubmit } = useForm<TransferConfirmInput>()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const onAccountSelected = (account?: Account) => {
    if (account) {
      history.push(`/transfers/${account.id}`)
    }
  }

  const onReset = () => {
    setQuote(undefined)
  }

  const setSubmit = () => {
    setSubmitting(true)
  }

  const onSubmit = (formValue: TransferConfirmInput) => {
    if (formValue.authorizationCode && formValue.authorizationCode.length !== 6) return
    if (!submitting) {
      setLoading(false)
      return
    } else {
      setLoading(true)
      Api.request({
        method: 'POST',
        url: `/transfers`,
        data: {
          quoteId: quote?.id,
          senderAccountId: senderAccount?.id,
          receiverAccountId: receiverAccount?.id,
          authorizationCode: formValue.authorizationCode || undefined
        },
      })
      .then(res => {
        flash(t('transfer.transfer_submitted'))
      })
      .catch(handleError)
      .finally(() => {
        setQuote(undefined)
        setReceiver(undefined)
        setReference(undefined)
        setLoading(false)
        setSubmitting(false)
      }) 
    }
  }

  useEffect(() => {
    setSenderAccount(accounts?.find(a => a.id === accountId))
    if (!accountId) {
      setQuote(undefined)
    }
  }, [accountId])

  if (!(session.user && session.user.id)) return null

  return (
    <Page padded>
      <form onSubmit={(handleSubmit(onSubmit))}>
        <PaperBlock title={t('transfer.title')} desc={t('transfer.subtitle')}>
          {/* <AppBar
            position="static"
            style={{
              backgroundColor: '#fff'
              // boxShadow: 'none',
              // padding: '0px 15px'
            }}
          >
            <Tabs value={0} onChange={() => {}}>
              <Tab
                key={`inqp-tabs-simple`}
                classes={{ selected: 'text-green active-background' }}
                label={t('transfer.simple_transfer')}
                style={{ color: '#333' }}
              />

              <Tab
                key={`inqp-tabs-advanced`}
                classes={{ selected: 'text-green active-background' }}
                label={t('transfer.advanced_transfer')}
                style={{ color: '#333' }}
                disabled={true}
              />
            </Tabs>
          </AppBar> */}

          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={8}>
              <Box>
                <FormControlGrid fullWidth margin="normal" title={t('transfer.from_account')}>
                  <MyAccountsSelection
                    // title={t('transfer.select_your_account')}
                    onSelectAccount={onAccountSelected}
                    account={senderAccount}
                  />
                </FormControlGrid>

                <Box marginY={2}>
                  <Divider />
                </Box>

                {senderAccount ? (
                  <InquiryForm account={senderAccount} quote={quote} onQuoteUpdated={setQuote} />
                ) : (
                  <Box m={5} textAlign="center">
                    {t('transfer.select_your_account')}
                  </Box>
                )}

                <Box m={[1, 2]} />

                <Collapse in={quote ? true : false}>
                  {quote && <QuoteComponent quote={quote} />}
                  <Box marginTop={1} />
                  {quote && (
                    <ReceiverForm
                      quote={quote}
                      onReceiverChange={(user, account) => {
                        setReceiver(user)
                        setReceiverAccount(account)
                      }}
                    />
                  )}
                  <Box marginTop={1} />

                  {session.user?.twoFAEnabled ? (
                    <Box marginTop={4} marginBottom={2}>
                      <Controller
                        control={control}
                        name="authorizationCode"
                        render={field => <TwoFAInput value={field.value} onChange={field.onChange} />}
                        rules={{
                          minLength: 6,
                          maxLength: 6,
                        }}
                      />
                    </Box>
                  ) : null}
                </Collapse>

                {quote && (
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={reference !== undefined ? true : false}
                            onChange={e => {
                              if (e.target.checked) {
                                setReference('')
                              } else {
                                setReference(undefined)
                              }
                            }}
                          />
                        }
                        label={t('transfer.use_reference')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Collapse in={reference === undefined ? false : true}>
                        <FormControl margin="normal" fullWidth>
                          <TextField
                            variant="outlined"
                            label={t('inquiry.reference')}
                            // placeholder={t('inquiry.reference_placeholder')}
                            value={reference}
                            onChange={e => {
                              setReference(e.target.value)
                            }}
                          />
                        </FormControl>
                      </Collapse>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                  </Grid>
                )}

                {quote && (
                  <Box p={[2, 4]} display="flex" justifyContent="center">
                    <Button variant="contained" type="reset" color="default" onClick={onReset}>
                      {quote ? t('common.cancel') : t('common.back')}
                    </Button>

                    <Box marginX={1} />

                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="primary"
                      onClick={setSubmit}
                      disabled={loading || !(quote && receiver && senderAccount && receiverAccount)}
                    >
                      {t('common.transfer')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Box marginY={[2, 5]} p={2}>
                <Typography variant="caption">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                  illum facere eum nisi dolor quidem vero voluptatibus nam harum
                  vitae, quod error sed, quae dolore? Harum natus in eius
                  repellendus.
                </Typography>
              </Box>

              <Box marginY={[2, 5]} p={2}>
                <Typography variant="caption">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam
                  illum facere eum nisi dolor quidem vero voluptatibus nam harum
                  vitae, quod error sed, quae dolore? Harum natus in eius
                  repellendus.
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
        </PaperBlock>
      </form>
    </Page>
  )
}

const TwoFAInput: React.FunctionComponent<TextFieldProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState<string>('')

  return (
    <>
      <TextField
        fullWidth={true}
        variant="outlined"
        label={t('profile.twofa_google_code')}
        value={code}
        required={true}
        size="medium"
        onChange={e => {
          setCode(e.target.value)
        }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
        {...props}
      />
    </>
  )
}