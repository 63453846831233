import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Account, BeneficiaryStatus, BeneficiaryType } from 'models'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BankSelect from 'components/BankSelect'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts'
import { Beneficiary } from 'models'
import { Controller, useForm } from 'react-hook-form'
import retry from 'retry'
import Api from 'utils/Api'

export const BeneficiaryAddDialog: React.FunctionComponent<
  DialogProps & {
    account: Account
    type: BeneficiaryType
    onWithdrawAccountCreated: (beneficiary: Beneficiary) => void
  }
> = ({ account, type, onWithdrawAccountCreated, onClose, ...options }) => {
  const { flash, handleError } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = useStyles()

  const [loading, setLoading] = useState<string | false | undefined>(false)
  let [beneficiary, setBeneficiary] = useState<Beneficiary>()

  const { handleSubmit, control, errors } = useForm<Beneficiary>({
    // mode: 'onBlur',
    defaultValues: {},
  })

  const fetchBeneficiaryDetail = () => {
    if (!(beneficiary && beneficiary.id)) return Promise.reject('No ID')

    return Api.get<Beneficiary>(`/beneficiaries/${beneficiary.id}`).then(({ data }) => {
      setBeneficiary(data)
      return data
    })
  }

  const onSubmit = (values: Beneficiary) => {
    setLoading(t('beneficiary.inquiring').toString())
    Api.request<Beneficiary>({
      method: 'POST',
      url: `/beneficiaries`,
      data: {
        accountId: account.id,
        type,
        bankId: values.bank?.id,
        address: values.address,
        label: values.label,
      },
    })
      .then(res => {
        setBeneficiary((beneficiary = res.data))

        const op = retry.operation({ retries: 6, maxTimeout: 12000 })
        op.attempt(
          cur => {
            fetchBeneficiaryDetail().then(beneficiary => {
              if (beneficiary.status !== BeneficiaryStatus.Verified) {
                if (op.retry(new Error('Still fetching'))) return
              }

              setLoading(false)
            })
          },
          { timeout: 2000 },
        )
      })
      .catch(err => {
        handleError(err)
        setLoading(false)
      })
  }

  return (
    <Dialog {...options}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <DialogTitle>{t('beneficiary.add_beneficiary')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('beneficiary.add_beneficiary_instruction')}</DialogContentText>

          <Box marginTop={2} />

          <Controller control={control} name="bank" render={props => <BankSelect required onChange={props.onChange} />} />

          <Controller
            control={control}
            name="address"
            render={props => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                required={true}
                label={t('beneficiary.account_destination', {
                  currency: account.currencyId,
                })}
                placeholder={t('beneficiary.account_destination_placeholder', {
                  currency: account.currencyId,
                })}
                onChange={e => {
                  props.onChange(e.target.value)
                }}
                defaultValue=""
                margin="normal"
                autoComplete={'false'}
                // disabled={loading || verifying}
                // error={addressError ? true : false}
                // helperText={addressError}
                FormHelperTextProps={{ error: errors.address ? true : false }}
                helperText={errors.address?.message}
              />
            )}
            rules={{
              required: { value: true, message: t('errors.input_required') },
              pattern: {
                value: /^[0-9a-zA-Z]{6,36}$/i,
                message: t('errors.invalid_address'),
              },
            }}
          />

          {/* <Controller
            control={control}
            name="name"
            render={(props) => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                required={true}
                label={t('beneficiary.account_name')}
                placeholder={t('beneficiary.account_name_placeholder')}
                onChange={(e) => {
                  props.onChange(e.target.value)
                }}
                defaultValue=""
                margin="normal"
                autoComplete={'false'}
                // disabled={loading || verifying}
                // error={addressError ? true : false}
                // helperText={addressError}
              />
            )}
          /> */}

          <Controller
            control={control}
            name="label"
            render={props => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                required={false}
                label={t('beneficiary.account_label')}
                placeholder={t('beneficiary.account_label_placeholder')}
                onChange={e => {
                  props.onChange(e.target.value)
                }}
                defaultValue=""
                autoComplete="false"
                margin="normal"
                // disabled={loading || verifying}
              />
            )}
          />

          {loading && <LoadingComponent message={'Fetching account informations...'} />}
          {beneficiary && beneficiary.name ? (
            <FormControl margin="normal">
              <Typography variant="caption">Account name</Typography>
              <Typography>
                <strong>{beneficiary.name}</strong>
              </Typography>
            </FormControl>
          ) : null}
        </DialogContent>

        {beneficiary ? (
          <DialogActions>
            <Button
              onClick={e => {
                if (beneficiary) {
                  onWithdrawAccountCreated(beneficiary)
                  setBeneficiary(undefined)
                }
              }}
              color="primary"
            >
              {t('common.confirm')}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={e => {
                onClose && onClose(e, 'escapeKeyDown')
              }}
              color="primary"
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit" color="primary" disabled={loading ? true : false}>
              {t('common.submit')}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    // display: 'flex',
    // flexDirection: 'column'
  },
}))
