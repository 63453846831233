import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { Check, HourglassEmpty, HourglassEmptyOutlined, HourglassFullOutlined, Warning } from '@material-ui/icons'
import CurrencyFormat from 'components/CurrencyFormat'
import { HorizontalLabeledValue, LabeledValue, ObjectLabeledValues } from 'components/LabeledValue'
import LoadingComponent from 'components/LoadingComponent'
import lodash from 'lodash'
import { FeeSide, Transaction, Withdraw, WithdrawStatus } from 'models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import Spinner from 'react-spinkit'

export const WithdrawDetailContent: React.FunctionComponent<{ withdraw: Withdraw }> = ({ withdraw }) => {
  const { accountId, transactionId, account } = withdraw
  const { t } = useTranslation()
  const [transaction, setTransaction] = useState<{ loading: boolean; data?: Transaction }>({ loading: true })

  useEffect(() => {
    Api.request({
      method: 'GET',
      url: `/accounts/${accountId}/transactions/${transactionId}`,
      params: {
        filter: {
          include: [{ relation: 'transactionFees', scope: { include: [{ relation: 'fee' }] } }],
        },
      },
    }).then(({ data }) => {
      setTransaction({ loading: false, data })
    })
  }, [])

  return (
    <Box p={2}>
      {transaction.loading ? (
        <LoadingComponent />
      ) : transaction.data ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} lg={4}>
            <Box marginTop={[1, 2, 4]} textAlign="center">
              <Typography variant="h5">{t('withdraw.withdraw') + ' ' + lodash.upperCase(account?.currencyId)}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={[1, 2]}>
              {withdraw.status === WithdrawStatus.SENT || withdraw.status === WithdrawStatus.SUCCEED ? (
                <>
                  <Check fontSize={'large'} color="primary" style={{ color: 'green', fontSize: 80, margin: 10 }} />
                  <Typography variant="h4">{lodash.capitalize(withdraw.status)}</Typography>
                </>
              ) : withdraw.status === WithdrawStatus.PENDING ? (
                <>
                  <HourglassEmpty fontSize={'large'} style={{ fontSize: 60, margin: 10 }} />
                  <Typography variant="h4">{lodash.capitalize(withdraw.status)}</Typography>
                </>
              ) : (
                <>
                  <Warning fontSize={'large'} color="primary" style={{ color: 'orange', fontSize: 80, margin: 10 }} />
                  <Typography variant="h4">{lodash.capitalize(withdraw.status)}</Typography>
                </>
              )}

              <Box textAlign="center" m={[2, 4]}>
                <Typography variant="caption" color="textSecondary">
                  {FormatHelper.humanDateTime(transaction.data.createdTime)}
                </Typography>
                <br />
                <Typography variant="caption" color="textSecondary">
                  {t('withdraw.reference')}
                  <br />
                  {withdraw.id}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <LabeledValue label={t('withdraw.sender_account')} value={account?.number} />
            {/* <LabeledValue label={t('withdraw.time')} value={FormatHelper.humanDateTime(transaction.data.createdTime)} /> */}
            <LabeledValue label={t('withdraw.amount')} value={<CurrencyFormat currency={account?.currency} value={withdraw.amount} />} />
            <LabeledValue label={t('withdraw.beneficiary')} value={`${withdraw.prefix} ${withdraw.address} ${withdraw.addressName}`} />
            {/* <LabeledValue label={t('withdraw.status')} value={transaction.data.status} /> */}
            <LabeledValue label={t('withdraw.notes')} value={withdraw.notes} />
            {/* <LabeledValue label={t('withdraw.reference')} value={`${withdraw.id}`} /> */}
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            {transaction.data.transactionFees && (
              <Box p={[1, 2]}>
                <Box m={1}>
                  <Divider />
                </Box>
                <Box textAlign="center">
                  <Typography variant="h5">Transaction fees</Typography>
                </Box>
                {transaction.data.transactionFees.map(transactionFee => (
                  <HorizontalLabeledValue
                    key={`wrp-trfee-${transactionFee.id}`}
                    p={0.5}
                    label={transactionFee.fee ? transactionFee.fee?.name : 'Fee'}
                    value={<CurrencyFormat currency={account?.currency} value={transactionFee.amount} />}
                  />
                ))}
                <Divider />
                <HorizontalLabeledValue
                  bold
                  p={0.5}
                  label={t('transaction.total_fee')}
                  value={
                    <CurrencyFormat
                      currency={account?.currency}
                      value={transaction.data.transactionFees.reduce((total, fee) => (total += Number(fee.amount)), 0)}
                    />
                  }
                />
              </Box>
            )}
          </Grid>
        </Grid>
      ) : // <>
      //   <ObjectLabeledValues object={lodash.omit(transaction, 'transactionFees')} />
      // </>
      null}
    </Box>
  )
}
