import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, OutlinedInput, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import MaterialTable, { Query } from 'material-table'
import { Account, Beneficiary, BeneficiaryStatus, BeneficiaryType } from 'models'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

const BeneficiariesTable: React.FunctionComponent<{
  account?: Account
  title?: string
}> = ({ title, ...props }) => {
  const { t } = useTranslation()
  const tableRef = useRef<any>()
  const [addressNumber, setAddressNumber] = useState<string | undefined>(undefined)
  const [labelOrAddrName, setLabelOrAddrName] = useState<string | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<Partial<Beneficiary>>()

  const handleConfirmOpen = (data: Partial<Beneficiary>) => {
    setOpen(true)
    setData(data)
  }

  const handleConfirmClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box>
        <Grid container direction="row" spacing={2}>
          <Grid item md={7} lg={7}>
            <OutlinedInput
              style={{ height: 40 }}
              placeholder="Search by Label or Address Name"
              value={labelOrAddrName}
              fullWidth
              onChange={e => {
                setLabelOrAddrName(e.target.value)
              }}
            />
          </Grid>
          <Grid item md={4} lg={4}>
            <OutlinedInput
              style={{ height: 40 }}
              placeholder="Search by Address Number"
              value={addressNumber}
              fullWidth
              onChange={e => {
                setAddressNumber(e.target.value)
              }}
            />
          </Grid>
          <Grid item md={1} lg={1}>
            <Button style={{ height: 40 }} fullWidth variant="contained" color="secondary" onClick={() => tableRef.current.onQueryChange()}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        <MaterialTable
          tableRef={tableRef}
          title={title ?? t('withdraw.beneficiaries')}
          columns={[
            {
              title: 'ID',
              field: 'id',
              width: 100,
              cellStyle: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              hidden: true,
            },
            {
              title: 'Label',
              field: 'label',
            },
            {
              title: 'Type',
              field: 'type',
              hidden: true,
            },
            {
              title: 'Account',
              field: 'accountId',
              render: row => (row.account ? row.account.currencyId : row.accountId),
            },
            {
              title: 'Address',
              field: 'address',
              render: row => (
                <Box flexDirection="row">
                  <Box>{row.bank ? row.bank.name : row.bankId}</Box>
                  <Box>{row.address}</Box>
                  <Box>{row.name ? <>{row.name}</> : null}</Box>
                </Box>
              ),
            },
            {
              title: 'Name',
              field: 'name',
              hidden: true,
            },
            {
              title: 'Status',
              field: 'status',
              width: 200,
              render: row => (
                <Box flexDirection="row">
                  <Box>{row.status}</Box>
                  <Box>
                    <Typography>{row.updatedTime ? <>last update {FormatHelper.humanDate(row.updatedTime)}</> : null}</Typography>
                  </Box>
                </Box>
              ),
            },
            {
              title: 'Action',
              width: '100%',
              render: (b: Partial<Beneficiary>) => {
                return (
                  <IconButton onClick={() => handleConfirmOpen(b)}>
                    <Delete />
                  </IconButton>
                )
              },
            },
          ]}
          data={(query: Query<Partial<Beneficiary>>) =>
            Api.request({
              method: 'GET',
              url: `/beneficiaries`,
              params: {
                filter: {
                  include: [
                    {
                      relation: 'account',
                      scope: { fields: ['id', 'currencyId'] },
                    },
                    { relation: 'bank' },
                  ],
                  limit: query.pageSize,
                  skip: query.page * query.pageSize,
                  order: 'id DESC',
                  where: {
                    type: {
                      eq: BeneficiaryType.Individual,
                    },
                    status: {
                      eq: BeneficiaryStatus.Verified,
                    },
                    ...(addressNumber ? { address: { eq: addressNumber.trim() } } : undefined),
                    ...(labelOrAddrName
                      ? {
                          or: [{ label: { like: `%${labelOrAddrName?.trim()}%` } }, { name: { like: `%${labelOrAddrName?.trim()}%` } }],
                        }
                      : undefined),
                  },
                },
              },
            }).then(res => {
              return {
                data: res.data,
                page: query.page,
                totalCount: 100000,
              }
            })
          }
          options={{
            actionsColumnIndex: -1,
            toolbar: true,
            showTitle: true,
            search: false,
            draggable: false,
            exportButton: false,
            exportAllData: false,
            pageSize: 25,
            pageSizeOptions: [25, 50, 100, 500],
            detailPanelColumnAlignment: 'left',
          }}
        />
      </Box>
      <Dialog open={open} onClose={handleConfirmClose}>
        <DialogTitle>{'Are you sure want to delete this recipient?'}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            No
          </Button>
          <Button
            onClick={e => {
              e.preventDefault()
              Api.request({
                method: 'PATCH',
                url: `/beneficiaries/${data?.id}`,
                data: {
                  accountId: data?.accountId,
                  type: data?.type,
                  bankId: data?.bankId,
                  address: data?.address,
                  ...(data?.label ? { label: data.label } : undefined),
                  status: 'inactive',
                },
              })
                .then(res => {
                  setOpen(false)
                  tableRef.current.onQueryChange()
                })
                .catch(err => {
                  console.log(err)
                })
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BeneficiariesTable
