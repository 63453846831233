import { Box } from '@material-ui/core'
import { Add, DashboardRounded } from '@material-ui/icons'
import CurrencyFormat from 'components/CurrencyFormat'
import { CurrencyIcon } from 'components/CurrencyIcon'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import NavItem from './NavItem'

export const NavBarBalances: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const [resource] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  return (
    <>
      {/* <NavItem title=overview */}
      {accounts &&
        accounts.length &&
        resource.currencies &&
        resource.currencies.length ?
        (accounts
          .filter(a => Boolean(resource.currencies.findIndex(c => c.id == a.currencyId) !== -1))
          .map(account => (
            <NavItem
              key={`nav-i-${account.id}`}
              href={`/accounts/${account.id}`}
              // title={`${FormatHelper.amount(
              //   account.balance,
              //   account.currencyId
              // )}`}
              title={
                <Box paddingLeft={2} flex={1} alignItems="center" justifyContent="flex-start">
                  <CurrencyFormat currency={account.currency} value={account.balance} />
                </Box>
              }
              icon={() => <CurrencyIcon currency={account.currency} size={24} />}

              // icon={account.currency?.iconUrl}
            />
          ))
          ) : ""
      }

      {accounts && accounts.length > 10 ? (
        <>
          <NavItem
            href="/accounts"
            // title={t('account.new_account')}
            title={t('account.other_accounts')}
            icon={DashboardRounded}
          />
        </>
      ) : (
        resource.currencies.length > (accounts ? accounts?.length : 0) && (
          <NavItem
            href="/accounts/new"
            // title={t('account.new_account')}
            title={'Open balance'}
            icon={Add}
          />
        )
      )}

      {/* <NavItem
        href="/accounts"
        // title={t('account.new_account')}
        title={t('account.other_accounts')}
        icon={DashboardRounded}
      /> */}
    </>
  )
}
