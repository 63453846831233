import { Box, Button, Dialog, DialogContent, DialogProps, Divider, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@material-ui/core'
import Image from 'material-ui-image'
import { SessionContext } from 'contexts/SessionContext'
import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Account } from 'models'
import { Controller, useForm } from 'react-hook-form'
import { DepositRequest } from 'models/DepositRequest'
import { FormControlGrid } from 'components/FormControlGrid'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import FormatHelper from 'utils/FormatHelper'
import Api from 'utils/Api'
import { toast } from 'react-toastify'
import { RecentRequests } from './RecentRequests'
import Env from 'utils/Env'

export const DepositQRISPayment: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [session] = useContext(SessionContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [showingQr, setShowingQr] = useState<DepositRequest | undefined>(undefined)
  const { register, control, errors, watch, setValue, handleSubmit } = useForm<DepositRequest & { method: string }>({
    mode: 'onChange',
    defaultValues: {
      type: 'qris',
      amount: NaN,
    },
  })

  const onSubmit = (req: DepositRequest) => {
    setLoading(true)
    Api.post<DepositRequest>(`/accounts/${account.id}/deposits/requests`, req)
      .then(res => {
        if (res.data.status === 'pending') {
          // toast.success(t('deposit.deposit_requested'))
          setShowingQr(res.data)
        } else {
          toast.warn('Deposit request ' + res.data.status)
        }
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller control={control} name="type" render={props => <input type="hidden" {...props} />} />
        <Controller control={control} name="bankId" render={props => <input type="hidden" {...props} />} />
        {/* <FormControlGrid fullWidth margin="normal" title={t('deposit.qris_bank')}>
          <Controller
            control={control}
            name="bankId"
            render={props => (
              <RadioGroup row {...props}>
                {process.env.NODE_ENV === 'development' && <FormControlLabel value={'demo'} control={<Radio />} label={'Demo Bank'} />}
                <FormControlLabel value={'nagari'} control={<Radio />} label={'Nagari Bank'} />
                <FormControlLabel value={'permata'} control={<Radio />} label={'Permata Bank'} />
              </RadioGroup>
            )}
          />
        </FormControlGrid> */}
        <FormControlGrid fullWidth margin="normal" title={t('deposit.amount')}>
          <Controller
            control={control}
            name="amount"
            render={props => (
              <TextField
                {...props}
                placeholder={t('withdraw.amount_placeholder')}
                variant="outlined"
                fullWidth
                required
                value={props.value}
                defaultValue={''}
                onChange={e => {
                  props.onChange(parseInt(e.target.value))
                }}
                InputProps={{
                  inputProps: {
                    decimalScale: account.currency?.precision || 0,
                    max: 100000000,
                  },
                  inputComponent: AmountNumberFormat as any,
                  endAdornment: <InputAdornment position="end">{account.currency?.id}</InputAdornment>,
                }}
                FormHelperTextProps={{ error: true }}
                helperText={errors.amount?.message}
              />
            )}
            rules={{
              validate: val => {
                const minAmount = account.currency?.attributes?.withdrawMin ?? 1
                // const maxAmount = account.currency?.attributes?.withdrawMax ?? 1000000000
                const maxAmount = 20000000
                if (val && minAmount && maxAmount) {
                  if (val < minAmount) {
                    return t('errors.min_amount_required', { minAmount }).toString()
                  } else if (val > maxAmount) {
                    return t('errors.max_amount_exceeded', { maxAmount }).toString()
                  } else {
                    return undefined
                  }
                }
                return t('errors.input_required').toString()
              },
              valueAsNumber: true,
              // required: { value: true, message: t('errors.input_required') },
              // maxLength: { value: 15, message: t('errors.max_length_exceeded') },
              // max: { value: 10, message: t('errors.max_length_exceeded') },
            }}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title="">
          <Button variant="contained" color="primary" type="submit" disabled={loading || Boolean(Object.keys(errors).length !== 0)}>
            {t('common.submit')}
            {loading && '..'}
          </Button>
        </FormControlGrid>
      </form>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <RecentRequests
        account={account}
        type="qris"
        load={!loading}
        onOpen={row => {
          setShowingQr(row)
        }}
      />

      <QRDialog
        depositRequest={showingQr}
        open={showingQr ? true : false}
        onClose={() => {
          setShowingQr(undefined)
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
          }, 200)
        }}
      />
    </>
  )
}

let fetchTimer: any = -1
const QRDialog: React.FunctionComponent<DialogProps & { depositRequest?: DepositRequest }> = ({ depositRequest, onClose, ...props }) => {
  const refreshStatus = () => {
    if (!(depositRequest && depositRequest.id)) return

    return Api.get(`/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}`).then(res => {
      if ((depositRequest.status === 'pending' || depositRequest.status === 'authorized') && res.data.status === 'captured') {
        toast.success('Payment success')
        onClose && onClose({}, 'backdropClick')
      } else {
        fetchTimer = setTimeout(refreshStatus, 1000)
      }
    })
  }

  useEffect(() => {
    refreshStatus()

    return () => {
      if (fetchTimer !== -1) {
        clearTimeout(fetchTimer)
        fetchTimer = -1
      }
    }
  }, [depositRequest])

  if (!(depositRequest && depositRequest.id)) return <></>

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={(e, r) => {
        clearTimeout(fetchTimer)
        fetchTimer = -1
        onClose && onClose(e, r)
      }}
      {...props}
    >
      <DialogContent>
        <QRDialogContent depositRequest={depositRequest} />
      </DialogContent>
    </Dialog>
  )
}

const QRDialogContent: FunctionComponent<{ depositRequest: DepositRequest }> = ({ depositRequest }) => {
  return (
    <Image
      src={`${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/qr`}
      aspectRatio={12 / 12}
      style={{ margin: '0 auto' }}
    />
  )
  // if (depositRequest.bankId == 'demo' || depositRequest.bankId == 'permata' || depositRequest.bankId == 'nagari') {
  //   return (
  //     <Image
  //       src={`${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/qr`}
  //       aspectRatio={12 / 12}
  //       style={{ margin: '0 auto' }}
  //     />
  //   )
  // } else {
  //   return (
  //     <Box minHeight={500}>
  //       <object
  //         data={`${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/qr`}
  //         type="text/html"
  //         width={'100%'}
  //         height={'auto'}
  //         style={{ minHeight: 500, margin: '0 auto' }}
  //       />
  //     </Box>
  //   )
  //   // return <link rel="import" href={`${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/qr`} />
  // }
}
