import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Delete, DeleteForever, ExploreOff } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { EmptyComponent } from 'components/EmptyComponent'
import { LabeledValue } from 'components/LabeledValue'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import { Apikey } from 'models/Apikey'
import React, { useContext, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

export const ApikeysTable: React.FunctionComponent<{}> = () => {
  const { flash, handleError } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [apikeys, setApiKeys] = useState<Apikey[]>()
  const [newApikey, setNewApikey] = useState<Partial<Apikey>>({ name: '' })

  const fetchApikeys = () => {
    Api.request<Apikey[]>({
      method: 'GET',
      url: '/apikeys',
    })
      .then(({ data }) => {
        setApiKeys(data)
      })
      .catch(handleError)
  }

  const onCreateApikey = () => {
    Api.request<Apikey>({
      method: 'POST',
      url: '/apikeys',
      data: newApikey,
    })
      .then(({ data }) => {
        setNewApikey(data)
        fetchApikeys()
      })
      .catch(handleError)
  }

  const onUpdate = (apikey: Apikey) => {
    Api.patch<Apikey>(`/apikeys/${apikey.id}`, { isActive: false })
      .then(res => {
        toast.info('Apikey successfully updated')
        fetchApikeys()
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
  }

  useEffect(() => {
    fetchApikeys()
  }, [])

  if (!session.user) return null

  return (
    <>
      <Box className={classes.root}>
        {apikeys && apikeys.length > 0 ? (
          apikeys.map(apikey => (
            <Box key={`user-apikey-${session.user?.id}-${apikey.id}`}>
              <ApiKeyComponent apikey={apikey} onUpdate={onUpdate} />
            </Box>
          ))
        ) : apikeys !== undefined ? (
          <EmptyComponent message={t('errors.empty_data_apikeys')} detail={''} />
        ) : null}
        <Box margin={4} display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={() => {
              setShowCreate(true)
            }}
          >
            {t('account.new_apikey')}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={showCreate}
        onClose={() => {
          setShowCreate(false)
        }}
      >
        <DialogTitle>{t('account.new_apikey')}</DialogTitle>
        <DialogContent style={{ minWidth: 460 }}>
          <DialogContentText>{t('account.new_apikey_instruction')}</DialogContentText>
          <FormControl margin="normal" fullWidth>
            <TextField
              autoFocus
              name="name"
              type="text"
              defaultValue={newApikey?.name}
              fullWidth
              label={t('account.apikey_name')}
              onChange={e => {
                if (e && e.target && e.target.value) {
                  const t = e.target.value
                  setNewApikey(apikey => ({
                    ...(apikey ?? {}),
                    name: t,
                  }))
                }
              }}
              disabled={newApikey.id ? true : false}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              name="allowedIP"
              type="text"
              defaultValue={newApikey?.name}
              fullWidth
              label={t('account.apikey_allowed_ip')}
              placeholder={t('account.apikey_allowed_ip_placeholder')}
              onChange={e => {
                if (e && e.target && e.target.value) {
                  const t = e.target.value
                  const newIP = t.split(',')
                  setNewApikey(apikey => ({
                    ...(apikey ?? {}),
                    allowedIP: newIP,
                  }))
                }
              }}
              disabled={newApikey.id ? true : false}
            />
          </FormControl>

          {newApikey.id && (
            <Box marginTop={4}>
              <FormControl margin="dense" fullWidth>
                <TextField autoFocus name="id" type="text" value={newApikey?.id} fullWidth label={t('account.apikey_id')} disabled={true} />
              </FormControl>

              <Box marginTop={4} />
              <Alert variant="standard" color="warning">
                {t('account.new_apikey_created_instruction')}
              </Alert>
              <FormControl margin="normal" fullWidth>
                <TextField autoFocus name="secret" type="text" value={newApikey?.secret} fullWidth label={t('account.apikey_secret')} disabled={true} />
              </FormControl>

              <Typography variant="caption">{t('account.new_apikey_notes')}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {newApikey.id ? (
            <Button
              color="primary"
              onClick={() => {
                setShowCreate(false)
                setNewApikey({})
              }}
            >
              {t('common.done')}
            </Button>
          ) : (
            <Button color="primary" onClick={onCreateApikey}>
              {t('common.submit')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export const ApiKeyComponent: React.FunctionComponent<{ apikey: Apikey; onUpdate: (apikey: Apikey) => void }> = ({ apikey, onUpdate }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false)

  const onDelete = () => {
    if (confirmingDelete) {
      apikey.isActive = false
      onUpdate(apikey)
      setConfirmingDelete(false)
    } else {
      setConfirmingDelete(true)
    }
  }

  return (
    <Box p={[1, 2]}>
      <Paper>
        <Grid container>
          <Grid item md={8}>
            <Box p={[1, 2]} display="flex">
              <Box p={2}>
                <Icon color={apikey.isActive ? 'primary' : 'disabled'}>api</Icon>
              </Box>
              <Box>
                <Typography variant="h3">
                  {apikey.name}{' '}
                  <Typography variant="h5" component="span">
                    - {apikey.isActive ? 'Active' : 'On review'}
                  </Typography>
                </Typography>

                <Box display="flex" flexDirection="row">
                  <Typography variant="body1">Apikey: {apikey.id}</Typography>
                  <Typography variant="body1" style={{ marginLeft: 20 }}>
                    Secret: ************
                  </Typography>
                </Box>

                <Typography variant="body1">IP: {apikey.allowedIP ? apikey.allowedIP.join(',') : ''}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            {apikey.isActive && (
              <Box p={[1, 2]} display="flex" flexDirection="column" alignItems="flex-end">
                <IconButton onClick={onDelete}>
                  <ExploreOff />
                </IconButton>
                <>{confirmingDelete ? <>Click again to confirm deactivating this apikey</> : null}</>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    padding: theme.spacing(2),
    flex: 1,
    minHeight: 60,
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
}))
