import { AccountPage } from 'containers/account/AccountPage'
import { NewAccountPage } from 'containers/account/NewAccountPage'
import { CallbacksPage } from 'containers/callbacks/CallbacksPage'
import { DashboardPage } from 'containers/dashboard/DashboardPage'
import { DepositPage } from 'containers/deposit/DepositPage'
import { ExchangePage } from 'containers/exchange/ExchangePage'
import { TransferHistoryPage } from 'containers/history/TransferHistoryPage'
import { ApiKeysPage } from 'containers/profile/ApiKeysPage'
import { ProfilePage } from 'containers/profile/ProfilePage'
import { TwoFAPage } from 'containers/profile/TwoFAPage'
import { InquiryRRNPage } from 'containers/tools/InquiryRRNPage'
import { TransferPage } from 'containers/transfer/TransferPage'
import { BeneficiariesPage } from 'containers/withdraw/BeneficiariesPage'
import { WithdrawPage } from 'containers/withdraw/WithdrawPage'
import { WithdrawReportPage } from 'containers/withdraw/WithdrawReportPage'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { NotFoundPage } from './NotFoundPage'

export function AppRoutes() {
  const [session] = useContext(SessionContext)

  return (
    <Switch>
      <Route path={'/accounts/new'} exact={true} component={NewAccountPage} />
      <Route path={'/accounts/:id'} exact={true} component={AccountPage} />
      <Route path={'/accounts'} exact={true} component={DashboardPage} />
      <Route path={'/transfers/history'} exact={true} component={TransferHistoryPage} />
      <Route path={'/transfers/:accountId?'} exact={true} component={TransferPage} />
      <Route path={'/withdraws/history'} exact={true} component={WithdrawReportPage} />
      <Route path={'/withdraws/beneficiaries'} exact={true} component={BeneficiariesPage} />
      <Route path={'/withdraws/:accountId?'} exact={true} component={WithdrawPage} />

      <Route path={'/deposits/:accountId?'} exact={true} component={DepositPage} />

      <Route path={'/exchanges'} exact={true} component={ExchangePage} />

      <Route path={'/callbacks'} exact={true} component={CallbacksPage} />
      <Route path={'/inquiryrrn'} exact={true} component={InquiryRRNPage} />
      <Route path={'/profile/apikeys'} exact={true} component={ApiKeysPage} />
      <Route path={'/profile/twofa'} exact={true} component={TwoFAPage} />
      <Route path={'/profile'} exact={true} component={ProfilePage} />

      {}
      <Route path={'/'} exact={true}>
        <Redirect to="/accounts" />
      </Route>
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
