import { Paper, Box, Typography, Grid, Divider } from '@material-ui/core'
import { LabeledValue, HorizontalLabeledValue } from 'components/LabeledValue'
import { Quote } from 'models/Quote'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormatHelper from 'utils/FormatHelper'

export const QuoteComponent: React.FunctionComponent<{ quote: Quote }> = ({ quote }) => {
  const { t } = useTranslation()

  const totalFee = quote.quoteFees?.reduce((total, fee) => total + parseFloat(fee.amount as unknown as string), 0)

  return (
    <Paper variant="outlined">
      <Box p={[1, 2]}>
        <Typography variant="subtitle1">{t('quote.summary')}</Typography>
      </Box>
      {/* <Grid container>
        <Grid item xs={12} md={6}>
          <LabeledValue label={t('quote.payout')} value={'----'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LabeledValue label={t('quote.est_received_time')} value={'----'} />
        </Grid>
      </Grid> */}

      <Divider />

      <Box p={[1, 2]} display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center">
        <HorizontalLabeledValue
          width={380}
          p={1}
          label={t('inquiry.sender_amount')}
          value={FormatHelper.currency(quote.sendCurrencyId, quote.sendAmount)}
          bold={true}
        />
        <HorizontalLabeledValue width={380} p={1} label={t('quote.fee')} value={FormatHelper.currency(quote.sendCurrencyId, totalFee ?? 0)} />
        <HorizontalLabeledValue
          width={380}
          p={1}
          label={t('inquiry.receiver_amount')}
          value={FormatHelper.currency(quote.receiveCurrencyId, quote.receiveAmount)}
          bold={true}
        />
      </Box>
    </Paper>
  )
}
