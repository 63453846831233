import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { MyAccountsSelection } from 'containers/transfer/MyAccountsSelection'
import { AppContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import { Account, Transaction, Withdraw, WithdrawStatus } from 'models'
import React, { ClassAttributes, Props, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { TableInstance, useTable, Column } from 'react-table'
import Api from 'utils/Api'
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer, TableFooter, Box, Drawer } from '@material-ui/core'
import { EmptyComponent } from 'components/EmptyComponent'
import FormatHelper from 'utils/FormatHelper'
import { TableMaterial } from 'components/TableMaterial'
import LoadingComponent from 'components/LoadingComponent'
import { DataGrid, GridColDef, GridRowsProp, GridRowData, GridColType } from '@mui/x-data-grid'
import { HorizontalLabeledValue, LabeledValue, ObjectLabeledValues } from 'components/LabeledValue'
import lodash from 'lodash'
import { WithdrawDetailContent } from './WithdrawDetailContent'

export const WithdrawReportPage: React.FunctionComponent<RouteComponentProps<{}>> = ({ match }) => {
  const { t } = useTranslation()
  const { handleError } = useContext(AppContext)

  const [selectedRow, setSelectedRow] = useState<GridRowData>()
  const [currentPage, setCurrentPage] = useState<number>(0)

  const data = useWithdrawData({}, { currentPage })

  return (
    <Page title={t('withdraw.title')} padded>
      <PaperBlock title={t('withdraw.title')} desc={t('withdraw.subtitle')}>
        <Box>
          <DataGrid
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            sortingMode={'server'}
            disableColumnMenu={true}
            pageSize={25}
            rowsPerPageOptions={[25]}
            autoHeight={true}
            pagination
            paginationMode="server"
            rowCount={10000}
            onPageChange={page => {
              setCurrentPage(page)
            }}
            {...data}
            onRowClick={row => {
              setSelectedRow(row.row)
            }}
          />
        </Box>

        <Drawer
          variant="temporary"
          anchor="bottom"
          open={Boolean(selectedRow)}
          onClose={() => {
            setSelectedRow(undefined)
          }}
        >
          {selectedRow && <WithdrawDetailContent withdraw={selectedRow as Withdraw} />}
        </Drawer>
      </PaperBlock>
    </Page>
  )
}

const useWithdrawData = (query: Partial<Withdraw>, props: { currentPage: number }) => {
  const [loading, setLoading] = useState<boolean>(true)

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: 'id', headerName: 'ID', width: 150 },
      // { field: 'accountId', headerName: 'Account', width: 120, valueGetter: row => (row.row.account ? row.row.account.currencyId : row.row.accountId) },
      { field: 'createdTime', headerName: 'Time', width: 240, type: 'date', valueFormatter: cell => FormatHelper.humanDateTime(cell.value as Date) },
      {
        field: 'address',
        headerName: 'Address',
        width: 320,
        valueFormatter: cell => `${cell.row.prefix ?? ''} ${cell.row.address} ${cell.row.addressName ?? ''}`,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        width: 200,
        valueFormatter: cell => FormatHelper.amount(cell.value as number, cell.row.account ? cell.row.account.currencyId : 'USD'),
      },
      { field: 'status', headerName: 'Status', width: 120 },
      { field: 'transactionId', headerName: 'Transaction ID', width: 150 },
    ],
    [],
  )

  const [rows, setRows] = React.useState<GridRowsProp>([])

  React.useEffect(() => {
    Api.request({
      method: 'GET',
      url: '/withdraws',
      params: {
        filter: {
          where: query,
          order: ['createdTime DESC'],
          include: [{ relation: 'account', scope: { include: [{ relation: 'currency' }] } }],
          limit: 25,
          offset: props.currentPage * 25,
        },
      },
    }).then(({ data }) => {
      setLoading(false)
      setRows(data)
    })
  }, [props.currentPage])

  return {
    columns,
    rows,
    loading,
  }
}
