import {
  Box, Button, Card, CardActions, CardHeader, Dialog,
  DialogContent, DialogProps, DialogTitle,
  FormControl, Grid, IconButton, Menu,
  MenuItem, Paper,
  TextField, Typography
} from '@material-ui/core'
import { AccountBalance, CropFree, MoreVert, Refresh } from '@material-ui/icons'
import { EmptyComponent } from 'components/EmptyComponent'
import { LabeledValue } from 'components/LabeledValue'
import LoadingComponent from 'components/LoadingComponent'
import { AppContext } from 'contexts'
import Image from 'material-ui-image'
import { Account } from 'models'
import { DepositAccount } from 'models/DepositAccount'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import Env from 'utils/Env'
import FormatHelper from 'utils/FormatHelper'

export const DepositAccounts: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [refreshTime, setRefreshTime] = useState<Date>()
  const [recents, setRecents] = useState<DepositAccount[]>()
  const [showingQr, setShowingQr] = useState<DepositAccount>()
  const [showingTransfer, setShowingTransfer] = useState<DepositAccount>()

  const fetchRecents = () => {
    setLoading(true)
    Api.get<DepositAccount[]>(`/accounts/${account.id}/deposits/accounts`, {
      params: { filter: { where: {status: 'active'}, limit: 10, order: ['createdTime DESC'], include: [{ relation: 'account' }] } },
    })
      .then(res => {
        setRecents(res.data)
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchRecents()
  }, [refreshTime])

  if (!(recents && recents.length)) return null

  return (
    <>
      <Box>
        <Box display={'flex'} flexDirection={'row'} marginY={2}>
          <Box>
            <Typography variant="h3">Deposit accounts</Typography>
          </Box>
          <Box>
            <Button
              startIcon={<Refresh />}
              onClick={() => {
                fetchRecents()
              }}
            ></Button>
          </Box>
        </Box>
        <Box>
          {loading ? (
            <LoadingComponent />
          ) : recents?.length === 0 ? (
            <EmptyComponent />
          ) : (
            <Grid container spacing={2}>
              {recents
                ? recents.map(recent => (
                    <Grid item key={`dp-rcac-t-${recent.id}`} lg={4} md={4} sm={12}>
                      <AccountComponent account={recent} onShowQr={setShowingQr} onTransferAccount={setShowingTransfer} />
                    </Grid>
                  ))
                : null}
            </Grid>
          )}
        </Box>
      </Box>

      <QRDialog
        depositAccount={showingQr}
        open={showingQr ? true : false}
        onClose={() => {
          setShowingQr(undefined)
          setRefreshTime(new Date())
        }}
      />

      <TransferDialog
        account={account}
        depositAccount={showingTransfer}
        open={showingTransfer ? true : false}
        onClose={() => {
          setShowingTransfer(undefined)
          setRefreshTime(new Date())
        }}
      />
    </>
  )
}

const AccountComponent: React.FunctionComponent<{
  account: DepositAccount
  onShowQr: (row: DepositAccount) => void
  onTransferAccount: (row: DepositAccount) => void
}> = ({ account, onShowQr, onTransferAccount }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Card>
        <CardHeader
          avatar={account.type === 'qris' ? <CropFree /> : <AccountBalance />}
          action={
            <IconButton onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
          }
          title={account.addressName}
          subheader={account.address || '--'}
        ></CardHeader>
        {/* <CardContent></CardContent> */}
        <CardActions>
          {account.type === 'qris' ? (
            <>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  onShowQr(account)
                }}
              >
                Show QRIS
              </Button>

              <Button
                size="small"
                variant="text"
                onClick={() => {
                  onShowQr(account)
                }}
              ></Button>
            </>
          ) : account.type === 'va' ? (
            <Typography color="textSecondary">Pay via banks</Typography>
          ) : null}
        </CardActions>
      </Card>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            onTransferAccount(account)
          }}
        >
          Transfer this account
        </MenuItem>
      </Menu>
    </>
  )
}

const QRDialog: React.FunctionComponent<DialogProps & { depositAccount?: DepositAccount }> = ({ depositAccount, onClose, ...props }) => {
  let url = `${Env.apiUrl}/accounts/${depositAccount?.accountId}/deposits/accounts/${depositAccount?.id}/qr`
  if (depositAccount?.fileUrl) {
    url = depositAccount.fileUrl
  }
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} {...props}>
      <DialogContent>
        <Image src={url} aspectRatio={12 / 16} />
      </DialogContent>
    </Dialog>
  )
}

const TransferDialog: React.FunctionComponent<DialogProps & { account: Account; depositAccount?: DepositAccount }> = ({
  account,
  depositAccount,
  onClose,
  ...props
}) => {
  const { handleError, flash } = useContext(AppContext)
  const { t } = useTranslation()
  const [toAccount, setToAccount] = useState<Account>()

  const { handleSubmit, control, errors, setValue, watch } = useForm<{ accountNumber: string }>({
    mode: 'onBlur',
    defaultValues: {
      accountNumber: '',
    },
  })

  const onSubmit = (values: { accountNumber: string }) => {
    if (!(values && toAccount)) return
    Api.request<{ accountNumber: string }>({
      method: 'POST',
      url: `/accounts/${toAccount.id}/deposits/accounts/${depositAccount?.id}/transfer`,
      data: {
        accountId: toAccount.id,
      },
    })
      .then(res => {
        flash('Account successfully Transferred')
        if (onClose) onClose({}, 'escapeKeyDown')
      })
      .catch(handleError)
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} {...props}>
      <DialogTitle>Transfer account ownership</DialogTitle>
      <DialogContent>
        <Box>This will transfer the owner of this {depositAccount?.type} Account to other Pazemo Account</Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth margin="normal">
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Controller
                  control={control}
                  name="accountNumber"
                  as={TextField}
                  label={t('account.search')}
                  fullWidth
                  rules={{
                    validate: val => {
                      if (!val) return
                      return Api.get<Account[]>(`/accounts/search`, { params: { currencyId: account.currencyId, query: val } }).then(res => {
                        if (res && res.status === 200 && res.data && res.data[0]) {
                          setToAccount(res.data[0])
                          return
                        }
                        return 'Account not found'
                      })
                    },
                  }}
                  FormHelperTextProps={{ error: true }}
                  helperText={errors.accountNumber?.message}
                />
              </Grid>
            </Grid>
          </FormControl>
          {toAccount ? (
            <>
              <Paper style={{ marginTop: 10 }}>
                <Grid container>
                  <Grid item md={12}>
                    <LabeledValue label={'User'} value={`${toAccount.user?.name}`} />
                  </Grid>
                  <Grid item md={12}>
                    <LabeledValue label={'Account ID / Currency'} value={`${toAccount.id} - ${toAccount.currencyId}`} />
                  </Grid>
                </Grid>
              </Paper>

              <FormControl fullWidth margin="normal">
                <Button variant="contained" type="submit" color="primary">
                  Transfer this account
                </Button>
              </FormControl>
            </>
          ) : (
            <>
              <FormControl fullWidth margin="normal">
                <Button variant="contained" type="submit" color="primary">
                  Search account
                </Button>
              </FormControl>
            </>
          )}
        </form>
      </DialogContent>
    </Dialog>
  )
}
