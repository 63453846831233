import { Box, Button, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import BankSelect from 'components/BankSelect'
import BeneficiarySelect from 'components/BeneficiarySelect'
import { FormControlGrid } from 'components/FormControlGrid'
import { AppContext, ResourceContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import { Account, BeneficiaryType, Fee, FeeSide, Withdraw, WithdrawStatus } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

enum WithdrawType {
  Beneficiary = 'beneficiary',
  Network = 'network',
}

export const WithdrawForm: React.FunctionComponent<{
  account: Account
  onWithdrawSubmitted: (withdraw: Withdraw) => void
}> = ({ account, onWithdrawSubmitted }) => {
  const { t } = useTranslation()
  const { flash, handleError } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const [resourceState] = useContext(ResourceContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [fees, setFees] = useState<Fee[]>()

  const { register, control, errors, watch, setValue, handleSubmit } = useForm<Withdraw & { type: WithdrawType }>({
    mode: 'onChange',
    defaultValues: {
      type: WithdrawType.Network,
      beneficiary: undefined,
      amount: NaN,
    },
  })

  const type = watch('type')
  const beneficiary = watch('beneficiary')
  const amount = watch('amount')
  const totalFee =
    fees && fees.length > 0 && amount ? Number(fees.reduce((total, fee) => (total += fee.side === FeeSide.Debitor ? Number(fee.amount) : 0), 0)) : 0

  const currency = resourceState.currencies.find(c => c.id === account.currencyId)

  const fetchFees = () => {
    return Api.request({
      method: 'GET',
      url: '/fees',
      params: {
        filter: {
          where: {
            partnerId: session.user!.partnerId,
            type: 'withdraw',
            currencyId: account.currencyId,
          },
        },
      },
    })
      .then(res => {
        setFees(res.data)
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (type === WithdrawType.Beneficiary && beneficiary) {
      setValue('prefix', beneficiary.bankId)
      setValue('address', beneficiary.address)
      setValue('addressName', beneficiary.name ?? '')
    } else if (type === WithdrawType.Network) {
      setValue('prefix', '')
      setValue('address', '')
      setValue('addressName', '')
    }

    fetchFees()
  }, [account, type, beneficiary])

  const onSubmit = (value: Withdraw & { type: WithdrawType }) => {
    setLoading(true)
    setError(undefined)

    const data: Partial<Withdraw> = {
      amount: value.amount,
    }

    if (type === WithdrawType.Beneficiary && value.beneficiary) {
      data.beneficiaryId = value.beneficiaryId
      data.prefix = value.beneficiary.bankId
      data.address = value.beneficiary.address
    } else if (type === WithdrawType.Network) {
      data.prefix = value.prefix
      data.address = value.address
      data.addressName = value.addressName
    }

    Api.request<Withdraw>({
      method: 'POST',
      url: `/accounts/${account.id}/withdraw/inquiry`,
      data,
    })
      .then(res => {
        setLoading(false)
        if (res.data && res.data.status === WithdrawStatus.FAILED) {
          setError('Inquiry failed / invalid account')
        } else if (res.data) {
          onWithdrawSubmitted(res.data)
        }
      })
      .catch(err => {
        handleError(err)
        setLoading(false)
      })
  }

  if (!currency) return null

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControlGrid fullWidth margin="normal" title={t('withdraw.to') + ' ' + account.currencyId + ' ' + t('withdraw.account')}>
          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={props => (
              <RadioGroup value={props.value} onChange={props.onChange}>
                <Box display="flex">
                  <Box flex={1}>
                    <FormControlLabel value={WithdrawType.Network} control={<Radio />} label={t('withdraw.new_address')} />
                  </Box>
                  {/* <Box flex={1}>
                    <FormControlLabel value={WithdrawType.Personal} control={<Radio />} label={t('withdraw.to_myself')} />
                  </Box> */}
                  <Box flex={1}>
                    <FormControlLabel value={WithdrawType.Beneficiary} control={<Radio />} label={t('withdraw.to_beneficiary')} />
                  </Box>
                </Box>
              </RadioGroup>
            )}
          />

          {/* {type === WithdrawType.Network && (
            <Controller
              control={control}
              name="network"
              render={(props) => (
                <Select
                  variant="outlined"
                  style={{ minWidth: 180 }}
                  // defaultValue={defaultCountryId}
                  value={props.value}
                  // label={label}
                  onChange={(e) => {
                    props.onChange(e.target.value)
                    // if (e.target.value !== -1) {
                    //   onChange(countries.find((c) => c.id === e.target.value))
                    // }
                  }}
                >
                  <MenuItem value={WithdrawNetwork.BRI}>BRI</MenuItem>
                  <MenuItem value={WithdrawNetwork.Flip}>Other banks</MenuItem>
                </Select>
              )}
            />
          )} */}
        </FormControlGrid>

        {type === WithdrawType.Network && (
          <FormControlGrid
            fullWidth
            margin="normal"
            // title={t('withdraw.address')}
            title=""
          >
            <Controller
              control={control}
              // as={BankSelect}
              name="prefix"
              rules={{
                required: { value: true, message: 'Prefix ' + t('errors.input_required') },
              }}
              // FormHelperTextProps={{ error: errors.address ? true : false }}
              // helperText={errors.address?.message}
              render={props => (
                <BankSelect
                  onChange={bank => {
                    props.onChange(bank?.id)
                  }}
                />
              )}
            />
          </FormControlGrid>
        )}

        {type === WithdrawType.Beneficiary && (
          <FormControlGrid fullWidth margin="normal" title="">
            <Controller
              control={control}
              name="beneficiary"
              rules={{
                required: { value: true, message: 'Beneficiary ' + t('errors.input_required') },
              }}
              render={props => <BeneficiarySelect account={account} value={props.value} onChange={props.onChange} />}
            />
          </FormControlGrid>
        )}

        {(type === WithdrawType.Network || beneficiary) && (
          <>
            <FormControlGrid
              fullWidth
              margin="normal"
              // title={t('withdraw.address')}
              title=""
            >
              <Controller
                control={control}
                as={TextField}
                variant="outlined"
                name="address"
                defaultValue=""
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: type !== WithdrawType.Network }}
                label={t('withdraw.address')}
                fullWidth
                placeholder={t('withdraw.address_placeholder')}
                rules={{
                  required: {
                    value: true,
                    message: 'Amount ' + t('errors.input_required'),
                  },
                  pattern: {
                    value: /^[0-9a-zA-Z]{6,36}$/i,
                    message: t('errors.invalid_address'),
                  },
                }}
                FormHelperTextProps={{ error: errors.address ? true : false }}
                helperText={errors.address?.message}
              />
            </FormControlGrid>

            {type !== WithdrawType.Network && (
              <FormControlGrid
                fullWidth
                margin="normal"
                // title={t('withdraw.address')}
                title=""
              >
                <Controller
                  control={control}
                  as={TextField}
                  variant="outlined"
                  name="addressName"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  label={t('withdraw.address_name')}
                  fullWidth
                  placeholder={t('withdraw.address_name_placeholder')}
                  rules={{
                    required: {
                      value: true,
                      message: t('errors.input_required'),
                    },
                  }}
                  disabled={false}
                  FormHelperTextProps={{
                    error: errors.addressName ? true : false,
                  }}
                  helperText={errors.addressName?.message}
                />
              </FormControlGrid>
            )}
          </>
        )}

        <FormControlGrid fullWidth margin="normal" title={t('withdraw.amount')}>
          <Controller
            control={control}
            name="amount"
            render={props => (
              <TextField
                {...props}
                placeholder={t('withdraw.amount_placeholder')}
                variant="outlined"
                fullWidth
                required
                value={props.value}
                defaultValue={''}
                onChange={e => {
                  props.onChange(parseInt(e.target.value))
                }}
                InputProps={{
                  inputProps: {
                    decimalScale: account.currency?.precision || 0,
                    max: 100000000,
                  },
                  inputComponent: AmountNumberFormat as any,
                  endAdornment: <InputAdornment position="end">{account.currency?.id}</InputAdornment>,
                }}
                FormHelperTextProps={{ error: true }}
                helperText={errors.amount?.message}
              />
            )}
            rules={{
              validate: val => {
                const minAmount = currency.attributes?.withdrawMin ?? 1
                const maxAmount = currency.attributes?.withdrawMax ?? 1000000000
                if (val && minAmount && maxAmount) {
                  if (val < minAmount) {
                    return t('errors.min_amount_required', { minAmount }).toString()
                  } else if (val > maxAmount) {
                    return t('errors.max_amount_exceeded', { maxAmount }).toString()
                  } else {
                    return undefined
                  }
                }
                return t('errors.input_required').toString()
              },
              valueAsNumber: true,
              // required: { value: true, message: t('errors.input_required') },
              // maxLength: { value: 15, message: t('errors.max_length_exceeded') },
              // max: { value: 10, message: t('errors.max_length_exceeded') },
            }}
          />
          {/* <Controller
            control={control}
            name="notes"
            as={TextField}
            rules={{
              validate: val => {
                return 'truNOtese'
              },
            }}
            FormHelperTextProps={{ error: errors.notes ? true : false }}
            helperText={errors.notes?.message}
          /> */}
          <Box marginTop={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Box>{t('withdraw.fee')}</Box>
            <Box>{FormatHelper.amount(totalFee, account.currencyId)}</Box>
          </Box>
          <Box marginTop={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Box>{t('withdraw.withdraw_amount')}</Box>
            <Box>{amount ? FormatHelper.amount(Number(amount ?? 0) + Number(totalFee ?? 0), account.currencyId) : 0}</Box>
          </Box>
        </FormControlGrid>

        <FormControlGrid fullWidth margin="normal" title="">
          {error ? (
            <Alert variant="outlined" color="error">
              {error}
            </Alert>
          ) : null}
        </FormControlGrid>

        <FormControlGrid fullWidth margin="normal" title="">
          <Button variant="contained" color="primary" type="submit" disabled={loading || Boolean(Object.keys(errors).length !== 0)}>
            {t('common.continue')}
            {loading && '..'}
          </Button>
        </FormControlGrid>
      </form>
    </>
  )
}
