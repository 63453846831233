import React, { useEffect } from 'react'
import Api from 'utils/Api'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { Bank } from 'models'
import LoadingComponent from './LoadingComponent'

const BankSelect: React.FunctionComponent<{
  value?: Bank
  onChange: (value?: Bank) => void
  required?: Boolean
}> = ({ value, onChange, ...props }) => {
  const [banks, setBanks] = React.useState<Bank[]>()

  useEffect(() => {
    Api.request<Bank[]>({
      method: 'GET',
      url: '/banks.public',
      params: {
        filter: { where: { networkType: 'bank' }, order: ['id ASC'] },
      },
    }).then(res => {
      setBanks(res.data)
    })
  }, [])

  if (typeof banks === 'undefined') return <LoadingComponent />

  return (
    <Autocomplete
      options={banks}
      getOptionLabel={option => option.id + ' - ' + option.name}
      fullWidth={true}
      renderInput={params => <TextField {...params} required={props.required ? true : false} label="-- Select Bank --" variant="outlined" />}
      onChange={(ev: any, v: Bank | null) => {
        ev.persist()
        onChange(v ? v : undefined)
      }}
      {...props}
    />
  )
}

export default BankSelect
