import { Currency, User } from './'

export class Account {
  constructor(
    public id: string,
    public userId: undefined | string,
    public currencyId: string,
    public number: string,
    public balance?: number,
    public locked?: number,
    public user?: User,
    public currency?: Currency,
    public pending?: number,
    public availableBalance?: number,
  ) {}

  public isFiatCurrency() {
    return true
  }
}
