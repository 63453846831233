import { Box, Button, Paper, TextField, Typography } from '@material-ui/core'
import { EmptyComponent } from 'components/EmptyComponent'
import { FormControlGrid } from 'components/FormControlGrid'
import { ObjectLabeledValues } from 'components/LabeledValue'
import LoadingComponent from 'components/LoadingComponent'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { SessionContext } from 'contexts/SessionContext'
import { Account } from 'models'
import { DepositRequest } from 'models/DepositRequest'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'

export const InquiryRRNPage: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation()
  const [session] = useContext(SessionContext)
  const [accounts, setAccounts] = useState<Account[]>()

  const fetchAccounts = () => {
    return Api.request<Account[]>({
      method: 'GET',
      url: `/users/${session.user?.id}/accounts`,
      params: {
        filter: {
          where: { currency: 'IDR' },
          order: ['createdTime ASC'],
          limit: 1,
        },
      },
    }).then(({ data }) => {
      return data
    })
  }
  useEffect(() => {
    fetchAccounts().then(setAccounts)
  }, [])

  if (!session.user) return null

  return (
    <Page title={t('tools.inquiry_rrn')} padded>
      <PaperBlock title={t('tools.inquiry_rrn')} desc={t('tools.inquiry_rrn_subtitle')}>
        {accounts && accounts.length ? <InquiryRRNForm account={accounts[0]} /> : <LoadingComponent />}
      </PaperBlock>
    </Page>
  )
}

type InquiryRRNFormTypes = { rrn: string }
const InquiryRRNForm: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [depositRequest, setDepositRequest] = useState<DepositRequest>()
  const { control, errors, handleSubmit, watch, reset, getValues } = useForm<InquiryRRNFormTypes>({
    mode: 'onChange',
    defaultValues: {
      rrn: '',
    },
  })

  const onSubmit = (value: InquiryRRNFormTypes) => {
    setLoading(true)
    Api.post(`/accounts/${account.id}/deposits/requests/search`, { type: 'qris', networkId: 'netzme', networkReference: value.rrn })
      .then(res => {
        setDepositRequest(res.data)
      })
      .catch(err => {
        toast.error(`${err}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControlGrid fullWidth margin="normal" title={t('tools.rrn')}>
          <Controller
            control={control}
            name="rrn"
            render={props => (
              <TextField
                {...props}
                variant="outlined"
                fullWidth
                required
                value={props.value}
                defaultValue={''}
                FormHelperTextProps={{ error: Boolean(errors.rrn) }}
                helperText={'Please to input exact RRN code to be checked'}
              />
            )}
            rules={{
              maxLength: 64,
              minLength: 4,
            }}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title="">
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            {t('common.search')}
            {loading && '..'}
          </Button>
        </FormControlGrid>
      </form>

      <Box m={2}>{loading && <LoadingComponent />}</Box>
      <Paper>
        <Box p={2}>
          {depositRequest ? (
            <>
              {/* <Box p={2}>
                <Typography variant="h4">Internal Reference data</Typography>
              </Box>
              {depositRequest && depositRequest.id ? <ObjectLabeledValues object={lodash.omit(depositRequest, 'bankId', 'networkData')} /> : <EmptyComponent />}
              <Divider /> */}
              <Box p={2}>
                <Typography variant="h4">External Reference data</Typography>
              </Box>
              {depositRequest.networkData ? <ObjectLabeledValues object={depositRequest.networkData} /> : <EmptyComponent />}
            </>
          ) : null}
        </Box>
      </Paper>
    </>
  )
}
