import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import MaterialTable from 'material-table'
import { Transfer } from 'models'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

const colorizeBySide = (side: string) => {
  return side === 'buy' ? 'green' : side === 'sell' ? 'red' : '#333'
}

export const TransferHistoryPage: React.FunctionComponent<{}> = ({}) => {
  const { t } = useTranslation()
  const tableRef = React.createRef<any>()

  return (
    <Page title={t('transfer.history')} padded>
      <PaperBlock title={t('transfer.history')} desc={t('transfer.history_subtitle')}>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            {
              title: 'ID',
              field: 'id',
              cellStyle: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 100,
              },
              hidden: true,
            },
            {
              title: 'Datetime',
              field: 'createdTime',
              type: 'datetime',
              cellStyle: {
                whiteSpace: 'nowrap',
              },
              hidden: false,
            },
            // {
            //   title: 'Sender Account ID',
            //   field: 'senderAccountId',
            //   hidden: false
            // },
            // {
            //   title: 'Receiver Account ID',
            //   field: 'receiverAccountId',
            //   hidden: false
            // },
            // {
            //   title: 'Quote ID',
            //   field: 'quoteId',
            //   hidden: false
            // },
            {
              title: 'Account',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.senderAccount ? `${t.senderAccount.number} - ${t.senderAccount.currencyId}` : t.senderAccountId),
            },
            {
              title: 'Amount',
              // field: 'quote.sendAmount',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.quote ? FormatHelper.currency(t.quote?.sendCurrencyId, t.quote?.sendAmount) : '--'),
            },
            {
              title: 'To amount',
              // field: 'quote.sendAmount',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.quote ? FormatHelper.currency(t.quote?.receiveCurrencyId, t.quote?.receiveAmount) : '--'),
            },
            {
              title: 'To account',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.receiverAccount ? `${t.receiverAccount.number} - ${t.receiverAccount.currencyId}` : t.receiverAccountId),
            },
            {
              title: 'To user',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.receiverAccount?.user ? `${t.receiverAccount.user.name}` : '-'),
            },
            {
              title: 'Rate',
              hidden: false,
              headerStyle: { textAlign: 'right' },
              cellStyle: { textAlign: 'right' },
              render: (t: Transfer) => (t.quote ? FormatHelper.rate(t.quote.rate) : '--'),
            },
            // {
            //   title: 'Kind',
            //   field: 'side',
            //   // cellStyle: { maxWidth: 80 }
            //   cellStyle: (_, row) => {
            //     return {
            //       maxWidth: 20,
            //       color: colorizeBySide(row.side)
            //     }
            //   }
            // },
            // {
            //   title: 'Amount',
            //   field: 'amount',
            //   headerStyle: { textAlign: 'right' },
            //   cellStyle: { textAlign: 'right' }
            // },
            // {
            //   title: 'Price',
            //   field: 'price',
            //   headerStyle: { textAlign: 'right' },
            //   cellStyle: { textAlign: 'right' }
            // }
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              return Api.request<Transfer[]>({
                method: 'GET',
                url: '/transfers',
                params: {
                  filter: {
                    limit: query.pageSize,
                    skip: query.page * query.pageSize,
                    order: [query.orderBy ? `${query.orderBy.field} ${query.orderDirection}` : 'createdTime DESC'],
                    include: [
                      { relation: 'senderAccount' },
                      { relation: 'receiverAccount', scope: { include: [{ relation: 'user' }] } },
                      { relation: 'quote' },
                    ],
                    where: {},
                  },
                },
              })
                .then(res => {
                  resolve({
                    data: res.data,
                    page: query.page,
                    totalCount: 100,
                  })
                })
                .catch(reject)
            })
          }
          actions={[]}
          options={{
            actionsColumnIndex: -1,
            toolbar: true,
            showTitle: false,
            search: false,
            draggable: false,
            exportButton: false,
            exportAllData: false,
            pageSize: 25,
            pageSizeOptions: [25],
            detailPanelColumnAlignment: 'left',
            showEmptyDataSourceMessage: true,
          }}
        />
      </PaperBlock>
    </Page>
  )
}
