import { makeStyles } from '@material-ui/core'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import NavBar from './NavBar'
import TopBar from './TopBar'
import { Decoration } from './Decoration'
import { SessionContext } from 'contexts/SessionContext'
import { AppRoutes } from './AppRoutes'
import { AccountProvider } from 'contexts/AccountContext'

interface RouteProps {
  title: string
  href?: string
  icon?: ReactNode
  component?: ReactNode
}

export function AppLayout() {
  const classes = useStyles()
  const [session] = useContext(SessionContext)

  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>()

  useEffect(() => {
    // nothing?
  }, [session.user])

  return (
    <>
      <Decoration
      // mode={mode}
      // gradient={gradient}
      // decoration={deco}
      // bgPosition={bgPosition}
      // horizontalMenu={false}
      />
      <AccountProvider>
        <div className={classes.root}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} className={classes.topbar} />

          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <AppRoutes />
              </div>
            </div>
          </div>
        </div>
      </AccountProvider>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  topbar: {
    // backgroundColor: '#fff',
    // paddingLeft: 240,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 245,
    },
  },
  // topbarMobile: { paddingLeft: 0 },
  wrapper: {
    zIndex: 10,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}))
