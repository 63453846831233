import axios, { AxiosError } from 'axios'
import LoadingScreen from 'components/LoadingScreen'
import { Account } from 'models'
import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import Api from 'utils/Api'
import Env from 'utils/Env'
import { SessionContext } from './SessionContext'

const AccountContext = createContext<[undefined | Account[]]>([undefined])

type AccountProps = {}

let fetchTimer: any = -1

const AccountProvider: FunctionComponent<PropsWithChildren<AccountProps>> = ({ children }) => {
  const [session, sessionDispatch] = useContext(SessionContext)
  const [loading, setLoading] = useState<boolean>(true)
  let [accounts, setAccounts] = useState<Account[]>()
  // const isPageVisible = usePageVisibility()
  // const isWindowFocused = useWindowFocus()

  const fetchAccounts = (force = false) => {
    if (fetchTimer !== -1 && !force) return
    // if (!isWindowFocused) return

    Api.request<Account[]>({
      method: 'GET',
      url: `/users/${session.user?.id}/accounts`,
      params: {
        filter: {
          order: ['createdTime ASC'],
          include: [{ relation: 'currency' }], //TODO: Set currency from resource instead of relation
        },
      },
    })
      .then(res => {
        let isSame = false
        if (accounts) {
          isSame = accounts.length === res.data.length
          accounts.forEach((account, k) => {
            if (
              isSame &&
              account.id === res.data[k].id &&
              account.balance === res.data[k].balance &&
              account.locked === res.data[k].locked &&
              account.pending === res.data[k].pending
            ) {
              // Check on something
              // isSame = isSame
            } else {
              isSame = false
            }
          })
        }

        if (!isSame) {
          accounts = res.data.map(
            m =>
              new Account(
                m.id,
                m.userId,
                m.currencyId,
                m.number,
                m.balance,
                m.locked,
                m.user,
                m.currency,
                m.pending,
                (m.balance as number) - (m.pending as number),
              ),
          )
          setAccounts(accounts)
        }

        if (Env.accountRefreshInterval) {
          fetchTimer = setTimeout(() => {
            fetchTimer = -1
            fetchAccounts()
          }, Env.accountRefreshInterval)
        }

        if (loading) setLoading(false)
      })
      .catch(err => {
        if (axios.isAxiosError(err) && (err as AxiosError) && err.response?.status == 401) {
          // JWT expired
          sessionDispatch({ type: 'SIGNOUT' })
          window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
          return
        }

        // shall we retry?
        fetchTimer = setTimeout(() => {
          fetchTimer = -1
          fetchAccounts()
        }, Env.accountRefreshInterval * 3)
      })
  }

  useEffect(() => {
    fetchAccounts(true)

    return () => {
      clearTimeout(fetchTimer)
    }
  }, [])

  return <AccountContext.Provider value={[accounts]}>{loading ? <LoadingScreen /> : children}</AccountContext.Provider>
}

export { AccountContext, AccountProvider }
