/* eslint react/jsx-key: 0 */

import { Box, useMediaQuery } from '@material-ui/core'
import CurrencyFormat from 'components/CurrencyFormat'
import LoadingComponent from 'components/LoadingComponent'
import dayjs from 'dayjs'
import MaterialTable from 'material-table'
import { Account, Transaction } from 'models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

export const StatementTable: React.FunctionComponent<{
  account: Account
}> = ({ account }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const tableRef = React.createRef<any>()
  const largeSize = useMediaQuery('(min-width:960px)')
  const [csvDownloading, setCsvDownloading] = useState<boolean>(false)
  let [filterDateValue, setFilterDateValue] = useState<Date>()

  if (!(account && account.id)) return null

  const executeDownload = () => {
    const filters = tableRef.current?.state?.query?.filters
    for (let filter of filters) {
      if (filter.column && filter.column.field === 'createdTime') {
        setFilterDateValue((filterDateValue = filter.value))
      }
    }
    if (!filterDateValue) {
      toast.error('Select date filter to be able to download csv')
      return
    }

    Api.request({
      url: `/accounts/${account.id}/transactions.csv`,
      method: 'GET',
      params: {
        filter: {
          where: {
            createdTime: { between: [dayjs(filterDateValue).startOf('day').toISOString(), dayjs(filterDateValue).endOf('day').toISOString()] },
          },
        },
      },
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `account_statement_${account.number}_${dayjs(filterDateValue).toISOString()}.csv`) //or any other extension
        document.body.appendChild(link)
        link.click()
        setLoading(false)
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
        setLoading(false)
      })

    return
  }

  useEffect(() => {
    tableRef.current?.retry()
    // setLoading(true)
    // setTimeout(() => {
    //   setLoading(false)
    // }, 100)
  }, [account.id])

  // if (loading) return <LoadingComponent />

  return (
    <>
      {loading ? <LoadingComponent /> : null}
      <MaterialTable
        title={`Account statement ${account.number}`}
        tableRef={tableRef}
        // isLoading={loading}
        options={{
          showTitle: false,
          search: false,
          draggable: false,
          exportButton: false,
          // exportAllData: true,
          filtering: true,
          pageSize: 100,
          pageSizeOptions: [100],
          showFirstLastPageButtons: false,
          debounceInterval: 700,
        }}
        actions={[
          {
            icon: 'download',
            tooltip: 'Download as CSV',
            isFreeAction: true,
            disabled: csvDownloading,
            // hidden: false,
            onClick: event => {
              executeDownload()
            },
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: event => {
              tableRef?.current?.retry()
            },
          },
        ]}
        onFilterChange={f => {
          console.log('filter change', f)
        }}
        columns={[
          {
            title: 'ID',
            field: 'id',
            cellStyle: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: 240,
            },
            hidden: !largeSize,
            filtering: true,
          },
          {
            title: 'Date Time',
            field: 'createdTime',
            type: 'date',
            headerStyle: { maxWidth: 190 },
            grouping: false,
            cellStyle: { whiteSpace: 'nowrap', minWidth: 200, maxWidth: 200 },
            render: (t: Transaction) => <>{FormatHelper.humanDateTime(t.createdTime)}</>,
          },
          {
            title: 'Type',
            field: 'type',
            lookup: { credit: 'credit', debit: 'debit' },
            headerStyle: { maxWidth: 80 },

            filterCellStyle: { maxWidth: 80 },
            cellStyle: { maxWidth: 80 },
          },
          {
            title: 'Notes',
            field: 'notes',
            filtering: false,
            cellStyle: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 80, maxWidth: 360 },
          },
          {
            title: 'Amount',
            field: 'amount',
            filtering: false,
            headerStyle: { textAlign: 'right' },
            render: (t: Transaction) => {
              let color = t.amount >= 0 ? '#618833' : '#ab003c'
              if (t.status === 'failed') color = '#33333366'

              return (
                <Box textAlign="right">
                  <span style={{ color }}>
                    <CurrencyFormat currency={account.currency} value={t.amount} />
                  </span>
                  <span style={{ color }}>{t.status === 'failed' && <Box>(Refunded)</Box>}</span>
                </Box>
              )
            },
          },
        ]}
        data={async query => {
          let where: any = {}

          for (const f of query.filters) {
            if (f.value && typeof f.value === 'string') {
              f.value = f.value.trim()
            }

            if (f.column.field && f.value && f.column.field == 'createdTime') {
              where[f.column.field] = { between: [dayjs(f.value).startOf('day'), dayjs(f.value).endOf('day')] }
            } else if (f.column.field && f.column.field && f.value && f.value.length > 0 && f.column.field != 'accountId') {
              where[f.column.field] = f.value
            }
          }

          return new Promise((resolve, reject) => {
            return Api.request({
              method: 'GET',
              url: `/accounts/${account.id}/transactions`,
              params: {
                filter: {
                  limit: query.pageSize,
                  skip: query.page * query.pageSize,
                  where,
                },
              },
            }).then(res => {
              resolve({
                data: res.data,
                page: query.page,
                totalCount: 10000,
              })
            })
          })
        }}
      />
    </>
  )
}
