import { Box, BoxProps, Button, Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core'
import { FlipToBack, FlipToFront, SwapHoriz, SwapVert } from '@material-ui/icons'
import CurrencyFormat from 'components/CurrencyFormat'
import { CurrencyIcon } from 'components/CurrencyIcon'
import LoadingComponent from 'components/LoadingComponent'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import BeneficiariesTable from 'containers/wallet/BeneficiariesTable'
import DepositContent from 'containers/wallet/DepositContent'
import { ReceiveContent } from 'containers/wallet/ReceiveContent'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import { Account } from 'models'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Api from 'utils/Api'
import { StatementTable } from './StatementTable'

export const AccountPage: React.FunctionComponent<any> = args => {
  const { t } = useTranslation()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)

  const { id: accountId } = useParams<{ id: string }>()
  const [account, setAccount] = useState<Account>()

  useEffect(() => {
    const myAccount = accounts?.find(a => a.id === accountId)
    if (myAccount) {
      setAccount(myAccount)
    } else {
      Api.get<Account>(`/accounts/${accountId}`, { params: { filter: { include: [{ relation: 'currency' }] } } }).then(res => {
        setAccount(res.data)
      })
    }
  }, [accounts, accountId])

  return (
    <Page title={t('account.my_accounts')} padded>
      <PaperBlock
        title={(account ? account.currencyId : '') + ' ' + t('account.balance')}
        desc={t('account.balance_subtitle')}
        whiteBg={true}
        icon={<CurrencyIcon currency={account?.currency} size={55}></CurrencyIcon>}
      >
        {account ? <AccountComponent account={account} /> : <LoadingComponent message={t('common.please_wait')} />}
      </PaperBlock>
    </Page>
  )
}

export const AccountComponent: React.FunctionComponent<{
  account: Account
}> = ({ account }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { currency } = account

  if (!currency) return null

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item md={2} xs={12}>
          <Box minHeight={64}>
            <CurrencyIcon currency={currency} size={64} />
          </Box>

          <Typography variant="body1" style={{ marginTop: 10 }}>
            {currency.name}
          </Typography>
        </Grid> */}

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box textAlign="center">
              <Typography component="span" variant="caption" color={'textSecondary'} gutterBottom>
                {t('account.number')}
              </Typography>

              <Typography variant="h5">{account.number}</Typography>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box textAlign="center">
              <Typography variant="caption" color={'textSecondary'} gutterBottom>
                {t('account.balance')}
              </Typography>

              <Typography variant="h5">
                <CurrencyFormat currency={account.currency} value={account.balance} />
              </Typography>

              <Typography variant="caption" color={'textSecondary'} gutterBottom>
                ({t('account.hold')}: <CurrencyFormat currency={account.currency} value={account.pending} />)
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box textAlign="center">
              <Typography variant="caption" color={'textSecondary'} gutterBottom>
                {t('account.available_balance')}
              </Typography>

              <Typography variant="h5">
                <CurrencyFormat currency={account.currency} value={account.availableBalance} />
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Box textAlign="center">
              <Typography variant="caption" color={'textSecondary'} gutterBottom>
                {t('account.locked_balance')}
              </Typography>

              <Typography variant="h5">
                <CurrencyFormat currency={account.currency} value={account.locked} />
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          marginTop={4}
          display="flex"
          flexWrap="wrap"
          flex={1}
          flexDirection="row"
          // alignItems="flex-end"
          justifyContent="center"
          justifySelf="center"
        >
          <Button
            component={Link}
            to={`/deposits/${account.id}`}
            variant="outlined"
            color="primary"
            startIcon={<FlipToFront />}
            className={classes.actionButton}
          >
            {t('account.deposit')}
          </Button>
          <Button component={Link} to={`/exchanges`} variant="outlined" color="primary" startIcon={<SwapVert />} className={classes.actionButton}>
            {t('exchange.exchange')}
          </Button>
          <Button
            component={Link}
            to={`/withdraws/${account.id}`}
            variant="outlined"
            color="primary"
            startIcon={<FlipToBack />}
            className={classes.actionButton}
          >
            {t('withdraw.withdraw')}
          </Button>
          <Button
            component={Link}
            to={`/transfers/${account.id}`}
            variant="outlined"
            color="primary"
            startIcon={<SwapHoriz />}
            className={classes.actionButton}
          >
            {t('transfer.send') + ' ' + account.currencyId}
          </Button>
        </Box>
      </Grid>

      {/* <Divider style={{ marginTop: 15, marginBottom: 15 }} /> */}

      {/* <AccountTabComponent account={account} /> */}

      <Box marginTop={5}>
        <Typography variant="h4" gutterBottom>
          {t('account.account_statement')}
        </Typography>
        <StatementTable account={account} />
      </Box>
    </>
  )
}

export const AccountTabComponent: React.FunctionComponent<{
  account: Account
}> = ({ account }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [tabValue, setTabValue] = useState<number>(0)
  const { currency } = account

  const handleTabChange = (ev: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  if (!currency) return null
  // const iconUrl = currency.iconUrl ? currency.iconUrl : '/cryptos/generic.png'

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            orientation="horizontal"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Currencies"
            classes={{
              flexContainer: classes.tabPane,
              //@ts-ignore
              flexContainerVertical: classes.tabPane,
            }}
          >
            <Tab label={t('account.account_statement')} />
            <Tab
              // disabled={!currency.deposit_enabled}
              label={t('account.deposit')}
            />

            <Tab
              // disabled={!currency.withdrawal_enabled}
              label={t('account.beneficiaries')}
            />
          </Tabs>

          <TabAccountPanel account={account} index={0} value={tabValue}>
            <Box paddingTop={2}>
              <StatementTable account={account} />
            </Box>
          </TabAccountPanel>
          {true && (
            <TabAccountPanel account={account} index={1} value={tabValue}>
              {account.isFiatCurrency() ? <DepositContent account={account} /> : <ReceiveContent account={account} />}
            </TabAccountPanel>
          )}

          {true && (
            <TabAccountPanel account={account} index={2} value={tabValue}>
              <BeneficiariesTable account={account} />
            </TabAccountPanel>
          )}
        </Grid>
      </Grid>
    </>
  )
}

interface TabAccountProps extends BoxProps {
  account: Account
  value: number
  index: number
}

const TabAccountPanel: React.FunctionComponent<TabAccountProps> = ({ children, account, value, index, ...other }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} id={`watac-id-${account.id}-${index}`} {...other}>
      {value === index ? <Box component="div">{children}</Box> : null}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.background.paper,
  //   display: 'flex',
  //   minHeight: 360
  //   // margin: theme.spacing(3, 0)
  // },
  // tabs: {
  //   borderRight: `1px solid ${theme.palette.divider}`
  // },
  // tabAccount: {
  //   margin: 10,
  //   marginBottom: 20,
  //   width: 180
  //   // minWidth: 160,
  //   // maxWidth: 180
  //   // borderBottom: `1px solid ${theme.palette.divider}`
  // },
  // tabBalance: {},
  tabPane: { width: 300 },
  // tabPanel: {
  //   flexGrow: 1,
  //   padding: theme.spacing(2)
  //   // minWidth: 600
  //   // maxWidth: 800
  // },
  // depositInstruction: {
  //   margin: theme.spacing(3, 0)
  // }
  actionButton: { margin: theme.spacing(1) },
}))
