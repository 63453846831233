import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import { FormControlGrid } from 'components/FormControlGrid'
import { Account } from 'models'
import { DepositRequest } from 'models/DepositRequest'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import Env from 'utils/Env'
import FormatHelper from 'utils/FormatHelper'
import { DepositRequestChip, RecentRequests } from './RecentRequests'

export const DepositEWalletPayment: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [showingDialog, setShowingDialog] = useState<DepositRequest | undefined>(undefined)

  const { register, control, errors, watch, setValue, handleSubmit } = useForm<DepositRequest & { method: string }>({
    mode: 'onChange',
    defaultValues: {
      type: 'ewallet',
      bankId: 'dana',
      amount: NaN,
    },
  })

  const onSubmit = (req: DepositRequest) => {
    setLoading(true)
    Api.post<DepositRequest>(`/accounts/${account.id}/deposits/requests`, req)
      .then(res => {
        if (res.data.status === 'pending') {
          toast.success(t('deposit.deposit_requested'))
          setShowingDialog(res.data)
        } else {
          toast.warn('Deposit request ' + res.data.status)
        }
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControlGrid fullWidth margin="normal" title={t('deposit.e_wallet_option')}>
          <Controller
            control={control}
            name="bankId"
            render={props => (
              <RadioGroup row {...props}>
                <FormControlLabel value={'dana'} control={<Radio />} label={'DANA'} />
              </RadioGroup>
            )}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title={t('deposit.amount')}>
          <Controller control={control} name="type" render={props => <input type="hidden" {...props} />} />
          <Controller
            control={control}
            name="amount"
            render={props => (
              <TextField
                {...props}
                placeholder={t('withdraw.amount_placeholder')}
                variant="outlined"
                fullWidth
                required
                value={props.value}
                defaultValue={''}
                onChange={e => {
                  props.onChange(parseInt(e.target.value))
                }}
                InputProps={{
                  inputProps: {
                    decimalScale: account.currency?.precision || 0,
                    max: 100000000,
                  },
                  inputComponent: AmountNumberFormat as any,
                  endAdornment: <InputAdornment position="end">{account.currency?.id}</InputAdornment>,
                }}
                FormHelperTextProps={{ error: true }}
                helperText={errors.amount?.message}
              />
            )}
            rules={{
              validate: val => {
                const minAmount = account.currency?.attributes?.withdrawMin ?? 1
                // const maxAmount = account.currency?.attributes?.withdrawMax ?? 1000000000
                const maxAmount = 20000000
                if (val && minAmount && maxAmount) {
                  if (val < minAmount) {
                    return t('errors.min_amount_required', { minAmount }).toString()
                  } else if (val > maxAmount) {
                    return t('errors.max_amount_exceeded', { maxAmount }).toString()
                  } else {
                    return undefined
                  }
                }
                return t('errors.input_required').toString()
              },
              valueAsNumber: true,
              // required: { value: true, message: t('errors.input_required') },
              // maxLength: { value: 15, message: t('errors.max_length_exceeded') },
              // max: { value: 10, message: t('errors.max_length_exceeded') },
            }}
          />
        </FormControlGrid>
        <FormControlGrid fullWidth margin="normal" title="">
          <Button variant="contained" color="primary" type="submit" disabled={loading || Boolean(Object.keys(errors).length !== 0)}>
            {t('common.submit')}
            {loading && '..'}
          </Button>
        </FormControlGrid>
      </form>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />

      <RecentRequests
        account={account}
        type="ewallet"
        load={!loading}
        onOpen={row => {
          setShowingDialog(row)
        }}
      />

      <EWalletDialog
        account={account}
        depositRequest={showingDialog}
        open={showingDialog ? true : false}
        onClose={() => {
          setShowingDialog(undefined)
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
          }, 200)
        }}
      />
    </>
  )
}

let fetchTimer: any = -1
const EWalletDialog: React.FunctionComponent<DialogProps & { account: Account; depositRequest?: DepositRequest }> = ({
  account,
  depositRequest,
  onClose,
  ...props
}) => {
  const refreshStatus = () => {
    if (!(depositRequest && depositRequest.id)) return

    return Api.get(`/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}`).then(res => {
      if ((depositRequest.status === 'pending' || depositRequest.status === 'authorized') && res.data.status === 'captured') {
        toast.success('Payment success')
        onClose && onClose({}, 'backdropClick')
      } else {
        fetchTimer = setTimeout(refreshStatus, 1000)
      }
    })
  }

  useEffect(() => {
    refreshStatus()

    return () => {
      if (fetchTimer !== -1) {
        clearTimeout(fetchTimer)
        fetchTimer = -1
      }
    }
  }, [depositRequest])

  if (!(depositRequest && depositRequest.id)) return <></>

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={(e, r) => {
        onClose && onClose(e, r)
      }}
      {...props}
    >
      <DialogTitle>
        <Grid container direction="row">
          <Grid item md={12}>
            <h2 style={{ color: '#3699FF' }}>Your Deposit Request</h2>
            <h4 style={{ fontWeight: 'normal' }}>
              Transaction ID: <span style={{ fontWeight: 'bold' }}>{depositRequest.transactionId}</span>
            </h4>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <EWalletDialogContent account={account} depositRequest={depositRequest} />
      </DialogContent>
    </Dialog>
  )
}

const EWalletDialogContent: FunctionComponent<{ account: Account; depositRequest: DepositRequest }> = ({ account, depositRequest }) => {
  const checkoutUrl = `${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/checkout`

  return (
    <>
      <Paper>
        <Box p={2}>
          <DetailGrid name="Date Created" value={FormatHelper.humanDateTime(depositRequest.createdTime)} />
          <DetailGrid name="Payment Method" value={depositRequest.bankId} />
          <DetailGrid name="Pay to Merchant" value={depositRequest.addressName} />
          <DetailGrid name="Amount" value={FormatHelper.amount(depositRequest.amount, account.currencyId)} />
          <DetailGrid name="Amount Fee" value={FormatHelper.amount(depositRequest.feeAmount, account.currencyId)} />
          <DetailGrid name="Status Request" value={<DepositRequestChip depositRequest={depositRequest} size="small" />} />
        </Box>
      </Paper>
      {depositRequest.status === 'pending' ? (
        <>
          <Grid container direction="row" style={{ marginTop: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#3699FF', width: 300 }}
              href={`${Env.apiUrl}/accounts/${depositRequest?.accountId}/deposits/requests/${depositRequest?.id}/checkout`}
              target="_blank"
            >
              <Typography variant="h6" style={{ color: '#fff' }}>
                Pay Now
              </Typography>
            </Button>
          </Grid>
          <Grid container direction="row" style={{ marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="body2" align="center" style={{ fontSize: '0.7em' }}>
              Or you can copy the url below to proceed the payment
              <br />
              <span>
                <b>
                  <u>{checkoutUrl}</u>
                </b>
              </span>
              <div style={{ color: '#3699FF' }}>
                <Button
                  variant="text"
                  style={{ color: '#3699FF' }}
                  onClick={() => {
                    navigator.clipboard.writeText(checkoutUrl)
                    toast.success('URL Copied!')
                  }}
                >
                  Copy URL
                </Button>
              </div>
            </Typography>
          </Grid>
        </>
      ) : null}
    </>
  )
}

interface Props {
  name: string
  value: any
}

interface Status {
  status: string
}

const statusColor = (status: string) => {
  let color
  if (status === 'succeed' || status === 'captured' || status === 'active' || status === 'sent') {
    color = '#3BB900'
  } else if (status === 'pending' || status === 'process' || status === 'obscure') {
    color = 'orange'
  } else if (status === 'failed' || status === 'unsent') {
    color = 'red'
  } else {
    color = 'grey'
  }

  return color
}

const CustomTypography: React.FC<Status> = ({ status }) => {
  return (
    <Typography
      variant="h6"
      style={{
        color: statusColor(status),
      }}
    >
      <b>{status}</b>
    </Typography>
  )
}

const DetailGrid: FunctionComponent<Props> = ({ name, value }) => {
  return (
    <Grid container direction="row">
      <Grid item xs={5} md={3}>
        <Typography variant="h6">{name}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography variant="h6" align="right" style={{ paddingRight: 10 }}>
          :
        </Typography>
      </Grid>
      <Grid item xs={6} md={8}>
        <Typography variant="h6">{value}</Typography>
      </Grid>
    </Grid>
  )
}
