import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { AmountNumberFormat } from 'components/AmountNumberFormat'
import { AppContext, ResourceContext } from 'contexts'
import { Country, Currency, User, Quote, Account } from 'models'
import { ForexRate } from 'models/ForexRate'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

export const InquiryForm: React.FunctionComponent<{
  account: Account
  quote: Quote | undefined
  onQuoteUpdated: (quote?: Quote) => void
}> = ({ account, quote, onQuoteUpdated }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { handleError, flash } = useContext(AppContext)
  const [resource] = useContext(ResourceContext)

  const [error, setError] = useState<string>()
  const [senderCountry, setSenderCountry] = useState<Country | undefined>(resource.countries.find(f => f.id === 'SGP'))
  const [receiverCountry, setReceiverCountry] = useState<Country | undefined>(resource.countries.find(f => f.id === 'IDN'))
  // const [senderCurrency, setSenderCurrency] = useState<Currency | undefined>(
  //   resource.currencies.find((f) => f.id === account.currencyId)
  // )
  const senderCurrency = account.currency
  const [receiverCurrency, setReceiverCurrency] = useState<Currency | undefined>(resource.currencies.find(f => f.id === 'IDR'))

  const [senderAmount, setSenderAmount] = useState<number>()
  const [receiverAmount, setReceiverAmount] = useState<number>()
  const [forexRates, setForexRates] = useState<ForexRate[]>()
  const [rate, setRate] = useState<number>()
  // const [quote, setQuote] = useState<Quote | undefined>(defaultQuote)

  const fetchRates = () => {
    Api.request<ForexRate[]>({
      method: 'GET',
      url: '/forex-rates/latest',
    })
      .then(({ data }) => {
        setForexRates(data)
      })
      .catch(handleError)
  }

  useEffect(() => {
    fetchRates()
  }, [senderCurrency, receiverCurrency])

  useEffect(() => {
    if (rate && senderAmount) {
      setReceiverAmount(Number(senderAmount) * Number(rate))
    }
  }, [senderAmount, rate])

  useEffect(() => {
    if (forexRates && senderCurrency && receiverCurrency) {
      const fr = forexRates.find(fr => fr.base === senderCurrency.id && fr.currencyId === receiverCurrency.id)
      if (fr) {
        setRate(fr.rate)
      } else {
        setRate(undefined)
      }
    }
  }, [forexRates, senderCurrency, receiverCurrency])

  const handleSubmit: (e: FormEvent) => void = e => {
    e.stopPropagation()
    e.preventDefault()

    if (senderAmount && senderAmount > 0 && account.balance && senderAmount >= account.balance) {
      setError(t('errors.invalid_sender_amount'))
      return
    }

    setError(undefined)

    if (quote) {
      postQuoteConfirm()
    } else {
      postQuote()
    }
  }

  const postQuote = () => {
    Api.request<Quote>({
      method: 'POST',
      url: '/quotes',
      data: {
        sendCurrencyId: senderCurrency?.id,
        receiveCurrencyId: receiverCurrency?.id,
        sendAmount: senderAmount,
      },
    })
      .then(({ data }) => {
        // flash(t('quote.quote_created_successfully'))
        onQuoteUpdated(Quote.new(data))
      })
      .catch(handleError)
  }

  const postQuoteConfirm = () => {
    Api.request<Quote>({
      method: 'POST',
      url: '/quotes/confirm',
      data: {
        sendCurrencyId: senderCurrency?.id,
        receiveCurrencyId: receiverCurrency?.id,
        sendAmount: senderAmount,
        rate: rate,
      },
    })
      .then(({ data }) => {
        flash(t('quote.quote_created_successfully'))
        onQuoteUpdated(Quote.new(data))
      })
      .catch(handleError)
  }

  const onReset = () => {
    onQuoteUpdated(undefined)
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        {/* <Grid container>
          <Grid item xs={12}>
            <Box marginY={1}>
              <Typography className={classes.instructionTitle}>{t('inquiry.please_select_country')}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <CountrySelect
              label={t('inquiry.sender_country')}
              countries={resource.countries}
              defaultCountryId={senderCountry?.id}
              onChange={setSenderCountry}
              className={classes.countryControl}
              disabled={quote ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth margin="normal">
              <Box flex={1} display="flex" justifyContent="center" marginTop={1}>
                <ArrowForward />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <CountrySelect
                label={t('inquiry.receiver_country')}
                countries={resource.countries}
                defaultCountryId={receiverCountry?.id}
                onChange={setReceiverCountry}
                className={classes.countryControl}
                disabled={quote ? true : false}
              />
            </Box>
          </Grid>
        </Grid> */}

        {/* <Box paddingY={2}>
          <Divider />
        </Box> */}

        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.instructionTitle} align="right">
              {t('inquiry.please_select_beneficiary_currency')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            {senderCurrency && (
              <AmountCurrencyInput
                key="senderAmount"
                label={t('inquiry.sender_amount')}
                currency={senderCurrency}
                defaultValue={senderAmount}
                required
                className={classes.amountControl}
                onChangeAmount={amount => {
                  setSenderAmount(amount)
                }}
                disabled={quote ? true : !rate}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth margin="normal">
              <Box display="flex" justifyContent="center" alignItems="center" flex={1} flexDirection="row" style={{ minHeight: 50 }}>
                <Typography variant="caption" style={{ textAlign: 'center', fontSize: 11, lineHeight: 1 }}>
                  {receiverCurrency && rate ? (
                    // <>
                    //   {senderCurrency?.id} 1
                    //   <br /> = <br />
                    //   <>
                    //     {receiverCurrency.id} {rate}
                    //   </>
                    // </>
                    <>
                      <Typography variant="caption">Rate</Typography>
                      <Typography variant="body1" component="div">
                        {FormatHelper.rate(rate)}
                      </Typography>
                    </>
                  ) : (
                    <span style={{ color: 'red' }}>No rate</span>
                  )}
                </Typography>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <AmountCurrencySelectInput
                variant="outlined"
                key="receiverAmount"
                label={t('inquiry.receiver_amount')}
                currency={receiverCurrency}
                currencies={resource.currencies}
                className={classes.amountControl}
                value={receiverAmount}
                onChangeAmount={(amount, currency) => {
                  setReceiverAmount(amount)
                  setReceiverCurrency(currency)
                }}
                onChangeCurrency={currency => {
                  setReceiverCurrency(currency)
                }}
                disabled={true}
                currencyDisabled={quote ? true : false}
              />
            </Box>
          </Grid>
        </Grid>

        {error && (
          <Alert variant="outlined" color="error">
            {error}
          </Alert>
        )}

        <Box m={[1, 2]} />

        {quote ? null : (
          <Box marginY={1} display="flex" justifyContent="center">
            {quote ? (
              <>
                <Button variant="contained" type="reset" color="default" onClick={onReset}>
                  {quote ? t('common.cancel') : t('common.back')}
                </Button>
                <Box marginX={1} />
              </>
            ) : null}

            <Button variant="contained" type="submit" color="primary" disabled={quote ? true : false}>
              {t('common.continue')}
            </Button>
          </Box>
        )}
      </form>
    </>
  )
}

const CountrySelect: React.FunctionComponent<
  {
    countries: Country[]
    defaultCountryId: string | undefined
    onChange: (value: Country | undefined) => void
    label: string
  } & Omit<SelectProps, 'onChange'>
> = ({ countries, defaultCountryId, onChange, label, className, ...props }) => {
  const key = label
  return (
    <FormControl variant="outlined" className={className} margin="normal">
      <InputLabel id={`inquiry-country-select-${key}`}>{label}</InputLabel>

      <Select
        labelId={`inquiry-country-select-${key}`}
        // fullWidth
        defaultValue={defaultCountryId}
        label={label}
        onChange={e => {
          if (e.target.value !== -1) {
            onChange(countries.find(c => c.id === e.target.value))
          }
        }}
        {...props}
      >
        {countries.map(country => (
          <MenuItem key={`inq-cs-m-c-${key}-${country.id}`} value={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const AmountInput: React.FunctionComponent<TextFieldProps & { currency?: Currency }> = ({ currency, ...props }) => {
  return (
    <FormControl margin="normal">
      <TextField
        variant="outlined"
        size="medium"
        value={props.value}
        disabled={true}
        // error={errors.amount ? true : false}
        // helperText={errors.amount?.message ?? ''}
        // onBlur={props.onBlur}
        onChange={() => {
          // props.onChange(e.target.value)
        }}
        InputProps={{
          inputProps: {
            decimalScale: currency?.precision || 0,
          },
          inputComponent: AmountNumberFormat as any,
          endAdornment: <InputAdornment position="end">{currency?.id.toUpperCase()}</InputAdornment>,
        }}
        {...props}
      />
    </FormControl>
  )
}

const AmountCurrencyInput: React.FunctionComponent<
  TextFieldProps & {
    currency: Currency
    onChangeAmount: (amount: number) => void
  }
> = ({ currency, onChangeAmount, ...props }) => {
  const [pickCurrency, setPickCurrency] = useState<string | undefined>(currency?.id)

  return (
    <FormControl margin="normal">
      <TextField
        variant="outlined"
        size="medium"
        value={props.value}
        // error={errors.amount ? true : false}
        // helperText={errors.amount?.message ?? ''}
        // onBlur={props.onBlur}
        onChange={e => {
          onChangeAmount(parseFloat(e.target.value))
          // props.onChange(e.target.value)
        }}
        InputProps={{
          inputProps: {
            decimalScale: currency?.precision || 0,
          },
          inputComponent: AmountNumberFormat as any,
          // endAdornment: (
          //   <InputAdornment position="end">
          //     {currency?.id.toUpperCase()}
          //   </InputAdornment>
          // )
          endAdornment: <InputAdornment position="end">{currency.id}</InputAdornment>,
        }}
        {...props}
      />
    </FormControl>
  )
}

const AmountCurrencySelectInput: React.FunctionComponent<
  TextFieldProps & {
    currency?: Currency
    currencies: Currency[]
    onChangeAmount: (amount: number, currency?: Currency) => void
    onChangeCurrency: (currency?: Currency) => void
    currencyDisabled?: boolean
  }
> = ({ currency, currencies, onChangeAmount, onChangeCurrency, ...props }) => {
  const key = currency
  const [pickCurrency, setPickCurrency] = useState<string | undefined>(currency?.id)

  return (
    <FormControl margin="normal">
      <TextField
        variant="outlined"
        size="medium"
        value={props.value}
        // error={errors.amount ? true : false}
        // helperText={errors.amount?.message ?? ''}
        // onBlur={props.onBlur}
        onChange={e => {
          onChangeAmount(
            parseFloat(e.target.value),
            currencies.find(c => c.id === pickCurrency),
          )
          // props.onChange(e.target.value)
        }}
        InputProps={{
          inputProps: {
            decimalScale: currency ? currency.precision : 0,
          },
          inputComponent: AmountNumberFormat as any,
          // endAdornment: (
          //   <InputAdornment position="end">
          //     {currency?.id.toUpperCase()}
          //   </InputAdornment>
          // )
          endAdornment: (
            <InputAdornment position="end">
              <Select
                value={pickCurrency}
                onChange={e => {
                  const nCurrency = e.target.value as string
                  setPickCurrency(nCurrency ?? undefined)
                  onChangeCurrency(currencies.find(c => c.id === nCurrency))
                }}
                name="suffix"
                disabled={props.currencyDisabled}
              >
                {currencies.map(currency => (
                  <MenuItem key={`aci-tf-ia-s-${key ?? ''}-${currency.id}`} value={currency.id}>
                    {currency.id}
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  instructionTitle: {
    fontSize: 18,
  },
  countryControl: {
    minWidth: 320,
  },
  currencyControl: {
    minWidth: 320,
  },
  amountControl: {
    minWidth: 320,
  },
}))
