import { Box, Button, CircularProgress, Hidden, Paper, Theme, Typography, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import Logo from 'components/Logo'
import Page from 'components/Page'
import { useLoginStyles } from 'containers/auth/LoginPage'
import { AppContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import Env from 'utils/Env'
import FormatHelper from 'utils/FormatHelper'
import QueryParams from 'utils/QueryParams'
import { StorageManager } from 'utils/StorageManager'

const VerifyPage: React.FunctionComponent<{}> = () => {
  const getVT = decodeURIComponent(QueryParams.toString()).split('&')[0].split('=')[1]
  const getEmail = decodeURIComponent(QueryParams.toString()).split('&')[1].split('=')[1]
  const [session] = useContext(SessionContext)
  const { handleError } = useContext(AppContext)
  const history = useHistory()
  const classes = useLoginStyles()
  const verifyClasses = verifyStyle()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSent, setLoadingSent] = useState<boolean>(false)
  const [successVerify, setSuccessVerify] = useState<boolean>(false)
  const [failedVerify, setFailedVerify] = useState<boolean>(false)
  const [reachLimit, setReachLimit] = useState<boolean>(false)
  const isLoggedIn = session.user ? true : false
  const { t } = useTranslation()
  const [disableBtn, setDisableBtn] = useState<boolean>(false)
  const storageManager = new StorageManager()
  const [resendTimeout, setResendTimeout] = useState<number>(storageManager.load('resendEmailTimeout') || 0)

  const reqVerifyEmail = async () => {
    if (successVerify) {
      return undefined
    }
    setSuccessVerify(false)
    await Api.request({
      method: 'POST',
      url: `/users/verify-email`,
      data: {
        token: getVT
      }
    })
      .then(() => {
        setSuccessVerify(true)
      })
      .catch(() => {
        setFailedVerify(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const reqResendEmail = async () => {
    setDisableBtn(true)
    setLoadingSent(true)
    await Api.request({
      method: 'POST',
      url: `/users/send-verify-email`,
      data: {
        email: getEmail,
      },
    })
      .then(() => {
        toast.success(t('emailVerify.resendSuccess'))
        setResendTimeout(parseInt(Env.verifyEmailTtl))
      })
      .catch(err => {
        if (err instanceof Object && (err as AxiosError).response) {
          const errax = err as AxiosError
          if (
            errax.response &&
            errax.response?.data &&
            errax.response?.data?.error &&
            (errax.response?.data?.error?.statusCode as number) === 422 &&
            (errax.response?.data?.error?.message as string).toLowerCase() ===
              'email resend has reached the maximum limit of 3 times per day. please try again tomorrow.'
          ) {
            setReachLimit(true)
            return
          }
          setResendTimeout(parseInt(Env.verifyEmailTtl))
        }
        handleError(err)
      })
      .finally(() => {
        setDisableBtn(false)
        setLoadingSent(false)
      })
  }

  useEffect(() => {
    if (getVT && getEmail) {
      reqVerifyEmail()
    } else {
      history.replace('/auth/signin')
    }
  }, [getVT, getEmail])

  useEffect(() => {
    const countdown =
      resendTimeout > 0 &&
      setInterval(() => {
        let timerCountdown = resendTimeout - 1
        storageManager.save('resendEmailTimeout', timerCountdown)
        setResendTimeout(resendTimeout - 1)
      }, 1000)
    if (resendTimeout <= 0) {
      storageManager.remove('resendEmailTimeout')
      setResendTimeout(0)
    }
    return () => {
      clearInterval(countdown as NodeJS.Timeout)
    }
  }, [resendTimeout])

  return (
    <Page title={t('emailVerify.title')}>
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.header}>
            <p className={classes.headerLogo}>
              <Logo size="large" variant={'dark'} />
              <h1 className={classes.headerTagline}>{t('app.tagline')}</h1>
            </p>
            <h1 className={classes.headerSubtitle}>{t('app.description')}</h1>
            <p className={classes.headerDesc}>{t('app.description1')}</p>
            <p className={classes.headerDesc}>{t('app.description2')}</p>
          </div>
        </Hidden>
        <div className={classes.wrapper}>
          <Fragment>
            <Hidden mdUp>
              <Box className={`${classes.brand} ${classes.outer}`}>
                <Logo variant="dark" />
              </Box>
            </Hidden>

            <Paper className={classNames(classes.paperWrap, classes.petal, verifyClasses.content)}>
              <Box
                style={{
                  display: loading ? 'flex' : 'none',
                  flex: 1,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <CircularProgress className={verifyClasses.loading} />
              </Box>
              <Box style={{ display: loading ? 'none' : 'flex', flex: 1, flexDirection: 'column', gap: 24, width: '100%', height: '100%' }}>
                <Box>
                  <Hidden smDown>
                    <div className={classes.topBar}>
                      <NavLink to="/" className={verifyClasses.logo}>
                        <img src="/static/images/logo_color.svg" />
                      </NavLink>
                    </div>
                  </Hidden>
                </Box>
                <Box style={{ flex: 1, marginBottom: 12 }}>
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: 128, width: '100%', height: '100%', justifyContent: 'center' }}>
                    <Box>
                      <img src={successVerify ? '/static/images/green_check.png' : failedVerify ? '/static/images/link_broken.png' : ''} />
                    </Box>
                    <Typography variant="body1" color="initial">
                      {successVerify ? t('emailVerify.verified') : failedVerify ? t('emailVerify.expired') : ''}
                    </Typography>
                  </Box>
                </Box>
                {failedVerify && !reachLimit ? (
                  <Box style={{ marginBottom: 12 }}>
                    <Typography variant="body2" color="initial">
                      {t('emailVerify.sentTo', { email: getEmail }).toString()}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
                {failedVerify && reachLimit ? (
                  <Box style={{ marginBottom: 12 }}>
                    <Typography variant="body2" style={{ color: red[500] }}>
                      {t('emailVerify.resend_reach_limit')}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
                <Box style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                  {failedVerify ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={disableBtn || resendTimeout > 0 || reachLimit}
                      style={{ display: 'block' }}
                      className={verifyClasses.button}
                      onClick={reqResendEmail}
                    >
                      <span>{t('common.resend_email_verify')}</span>
                      {loadingSent ? <span>{<CircularProgress style={{ marginLeft: 8 }} size={12} />}</span> : <></>}
                      {resendTimeout > 0 ? (
                        <span>
                          {'   '}
                          {FormatHelper.secondToMMSS(resendTimeout)}
                        </span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disableBtn}
                    style={{ display: 'block' }}
                    className={verifyClasses.button}
                    onClick={() => {
                      if (isLoggedIn) {
                        history.replace('/profile')
                      } else {
                        history.replace('/auth/signin')
                      }
                    }}
                  >
                    {t('common.back_to_home')}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Fragment>
        </div>
      </div>
    </Page>
  )
}

export default VerifyPage

const verifyStyle = makeStyles((theme: Theme) => ({
  content: {
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '2vh 4vw',
      height: '90vh',
    },
    [theme.breakpoints.up('md')]: {
      boxSizing: 'border-box',
      padding: '8vh 5vw',
    },
  },
  logo: {
    width: '15vw',
  },
  button: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1em',
      paddingRight: '1em',
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3em',
      paddingRight: '3em',
    },
  },
  loading: {
    width: '20vw',
    height: '20vw',
  },
}))
