import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { TableMaterial } from 'components/TableMaterial'
import BeneficiariesTable from 'containers/wallet/BeneficiariesTable'
import { BeneficiaryAddDialog } from 'containers/wallet/BeneficiaryAddDialog'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import { Account, Beneficiary, BeneficiaryType } from 'models'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Column, useTable } from 'react-table'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'

export const BeneficiariesPage: React.FunctionComponent<any> = args => {
  const { t } = useTranslation()
  const history = useHistory()
  const [resourceState] = useContext(ResourceContext)
  const [accounts] = useContext(AccountContext)
  const [creating, setCreating] = useState<boolean>(false)

  const [account, setAccount] = useState<Account>()
  const [type, setType] = useState<BeneficiaryType>(BeneficiaryType.Individual)
  const [personalBeneficiaries, setPersonalBeneficiaries] = useState<Beneficiary[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<Beneficiary>()

  const getBeneficiaries = (type: BeneficiaryType) => {
    return Api.request({
      method: 'GET',
      url: '/beneficiaries',
      params: {
        filter: {
          include: [{ relation: 'account', scope: { fields: ['id', 'currencyId'] } }, { relation: 'bank' }],
          limit: 10,
          where: { type, status: { neq: 'inactive' } },
          order: 'updatedTime DESC',
        },
      },
    }).then(res => {
      return res.data
    })
  }

  const handleConfirmOpen = (data: Beneficiary) => {
    setOpen(true)
    setData(data)
  }

  const handleConfirmClose = () => {
    setOpen(false)
  }

  const columns = useMemo<Array<Column<Beneficiary>>>(
    () => [
      // { Header: 'ID', accessor: 'id' },
      { Header: 'Label', accessor: 'label', maxWidth: 160, width: 160 },
      // { Header: 'Type', accessor: 'type', maxWidth: 80 },
      {
        Header: 'Account',
        accessor: 'accountId',
        Cell: ({ value, row }) => {
          return row.original.account ? row.original.account.currencyId : row.original.accountId
        },
      },

      {
        Header: () => 'Address',
        accessor: 'address',
        // accessor: (row) => FormatHelper.amount(row.amount, account.currencyId),
        Cell: ({ value, row }) => (
          <Box flexDirection="row">
            <Box>{row.original.bank ? row.original.bank.name : row.original.bankId}</Box>
            <Box>{row.original.address}</Box>
            <Box>{row.original.name ? <>{row.original.name}</> : null}</Box>
          </Box>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value, row }) => (
          <Box flexDirection="row">
            <Box>{value}</Box>
            <Box>
              <Typography variant="caption">{row.original.updatedTime ? <>last update {FormatHelper.humanDate(row.original.updatedTime)}</> : null}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value, row }) => (
          <Box flexDirection="row">
            <IconButton
              size="small"
              onClick={() => {
                handleConfirmOpen(row.original)
              }}
              edge="end"
            >
              <Delete />
            </IconButton>
          </Box>
        ),
      },
    ],
    [account],
  )

  const personalTableProps = useTable<any>({
    columns,
    data: personalBeneficiaries,
    initialState: {},
  })

  useEffect(() => {
    if (creating) return
    getBeneficiaries(BeneficiaryType.Personal).then(rows => {
      setPersonalBeneficiaries(rows)
    })
  }, [creating])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Page title={t('withdraw.beneficiaries')} padded>
      <PaperBlock title={t('withdraw.beneficiaries')} desc={t('beneficiary.subtitle')} whiteBg={true}>
        <Box
          display="flex"
          flexDirection="row"
          // justifyItems="flex-end"
          justifyContent="flex-end"
          // alignItems="flex-end"
          m={2}
        >
          <Button
            variant="text"
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={e => {
              setType(BeneficiaryType.Personal)
              setAnchorEl(e.currentTarget)
            }}
          >
            + {t('beneficiary.add_personal_account')}
          </Button>

          <Menu id="bfc-add-new-cur" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {accounts
              ?.filter(a => Boolean(resourceState.currencies.findIndex(c => c.id == a.currencyId) !== -1))
              .map(account => (
                <MenuItem
                  key={`ben-f-cur-ac-${account.id}`}
                  onClick={() => {
                    setAnchorEl(null)
                    setCreating(true)
                    setAccount(account)
                  }}
                >
                  {account.currencyId}
                </MenuItem>
              ))}
          </Menu>
        </Box>

        {account && (
          <BeneficiaryAddDialog
            open={creating}
            account={account}
            type={type}
            onWithdrawAccountCreated={() => {
              setCreating(false)
            }}
            onClose={() => {
              setCreating(false)
            }}
          />
        )}

        <Paper>
          <TableMaterial title={t('withdraw.my_personal_beneficiaries')} {...personalTableProps} />
        </Paper>

        <Box m={2} />

        {creating ? null : (
          <Grid container>
            <Grid item md={12}>
              <Box
                display="flex"
                flexDirection="row"
                // justifyItems="flex-end"
                justifyContent="flex-end"
                // alignItems="flex-end"
                m={2}
              >
                <Button
                  variant="text"
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={e => {
                    setType(BeneficiaryType.Individual)
                    setAnchorEl(e.currentTarget)
                  }}
                >
                  + {t('beneficiary.add_beneficiary')}
                </Button>
              </Box>
            </Grid>
            <Grid item md={12}>
              <BeneficiariesTable title={t('withdraw.beneficiaries')} />
            </Grid>
          </Grid>
        )}
      </PaperBlock>

      <Dialog open={open} onClose={handleConfirmClose}>
        <DialogTitle>{'Are you sure want to delete this bank account?'}</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            No
          </Button>
          <Button
            onClick={e => {
              e.preventDefault()
              Api.request({
                method: 'PATCH',
                url: `/beneficiaries/${data?.id}`,
                data: {
                  accountId: data?.accountId,
                  type: data?.type,
                  bankId: data?.bankId,
                  address: data?.address,
                  ...(data?.label ? { label: data.label } : undefined),
                  status: 'inactive',
                },
              })
                .then(res => {
                  setOpen(false)
                  getBeneficiaries(BeneficiaryType.Personal).then(rows => {
                    setPersonalBeneficiaries(rows)
                  })
                })
                .catch(err => {
                  console.log(err)
                })
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}
