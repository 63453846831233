import { Chip, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import { Account, Callback, CallbackStatus } from 'models'
import { Partner } from 'models/Partner'
import React from 'react'
import Api from 'utils/Api'
import Validator from 'utils/Validator'

export const CallbacksTable: React.FunctionComponent<{ partner?: Partner }> = ({ partner }) => {
  const tableRef = React.createRef<any>()

  const onRetry = (callback: Callback) => {
    Api.post(`/callbacks/${callback.id}/retry`).then(res => {
      tableRef?.current?.retry()
    })
  }

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={[
        {
          title: 'ID',
          field: 'id',
          cellStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 100,
          },
          hidden: true,
          filtering: false,
        },
        {
          title: 'Datetime',
          field: 'createdTime',
          type: 'datetime',
          cellStyle: {
            whiteSpace: 'nowrap',
          },
          hidden: false,
          filtering: false,
        },
        {
          title: 'Account',
          field: 'accountId',
          hidden: false,
          headerStyle: { textAlign: 'right' },
          cellStyle: { textAlign: 'right' },
          render: (c: Callback) => (
            <>
              {c.account ? ` ${c.account.number}` : c.accountId} <br />
              {c.account ? `${c.account.user?.email}` : '-'}
            </>
          ),
        },
        {
          title: 'Transaction',
          field: 'transactionId',
          cellStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 160,
            maxWidth: 160,
          },
        },
        {
          title: 'Response',
          render: (c: Callback) => <>{c.responseStatus}</>,
        },
        {
          title: 'status',
          field: 'status',
          render: (c: Callback) => (
            <>
              <CallbackStatusChip callback={c} />
              <br />
              <Typography variant="caption">{c.responseStatus}</Typography>
            </>
          ),
        },
      ]}
      data={async query => {
        let where: any = {}
        for (const f of query.filters) {
          if (f.column.field === 'accountId' && f.value && f.value.length > 0) {
            const accounts = await Api.get<Account[]>('/accounts/search', { params: { query: f.value } }).then(res => res.data)
            if (accounts && accounts.length > 1) {
              where[f.column.field] = { inq: accounts.map(a => a.id) }
            } else if (accounts && accounts.length > 0) {
              where[f.column.field] = accounts[0].id
            }
          }
          if (f.column.field && f.column.field && f.value && f.value.length > 0 && f.column.field === 'transactionId') {
            if (Validator.isUUIDV4(f.value.trim())) {
              where['transactionId'] = f.value.trim()
            } else {
              where['transactionId'] = null
            }
          }
        }

        return new Promise((resolve, reject) => {
          const [prefix, address] = query.search?.split(' ')
          if (prefix) where.prefix = prefix
          if (address) where.address = address

          for (const f of query.filters) {
            if (f.column.field && f.column.field && f.value && f.value.length > 0 && !['transactionId', 'accountId'].includes(f.column.field)) {
              where[f.column.field] = f.value.trim()
            }
          }

          return Api.request<Callback[]>({
            method: 'GET',
            url: `/callbacks`,
            params: {
              filter: {
                limit: query.pageSize,
                skip: query.page * query.pageSize,
                order: [query.orderBy ? `${query.orderBy.field} ${query.orderDirection}` : 'createdTime DESC'],
                include: [{ relation: 'account', scope: { include: [{ relation: 'user' }] } }],
                where,
              },
            },
          })
            .then(res => {
              resolve({
                data: res.data,
                page: query.page,
                totalCount: 100000,
              })
            })
            .catch(reject)
        })
      }}
      actions={[
        {
          icon: 'refresh',
          tooltip: 'Refresh',
          isFreeAction: true,
          onClick: event => {
            tableRef?.current?.retry()
          },
        },
        {
          icon: 'replay',
          tooltip: 'Retry callback',
          onClick: (e, callback) => {
            onRetry(callback as Callback)
          },
        },
      ]}
      title={''}
      options={{
        actionsColumnIndex: -1,
        toolbar: true,
        showTitle: true,
        search: false,
        draggable: false,
        exportButton: false,
        exportAllData: false,
        filtering: true,
        pageSize: 50,
        pageSizeOptions: [5, 10, 20, 50],
        detailPanelColumnAlignment: 'left',
        debounceInterval: 700,
      }}
    />
  )
}

const CallbackStatusChip: React.FunctionComponent<{ callback: Callback }> = ({ callback }) => {
  const color = callback.status === CallbackStatus.SUCCEED ? '#6BA766' : callback.status === CallbackStatus.FAILED ? '#D9534B' : '#333'
  return <Chip label={callback.status} style={{ color }} variant="outlined" />
}
