import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { AccountBalanceWallet, Atm, Code, Receipt } from '@material-ui/icons'
import { DevelopingComponent } from 'components/DevelopingComponent'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { ResourceContext } from 'contexts'
import { AccountContext } from 'contexts/AccountContext'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import { Account } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { DepositAccounts } from './DepositAccounts'
import { DepositEWalletPayment } from './DepositEWalletPayment'
import { DepositManualTransfer } from './DepositManualTransfer'
import { DepositQRISPayment } from './DepositQRISPayment'
import { DepositVATransfer } from './DepositVATransfer'

export const DepositPage: React.FunctionComponent<RouteComponentProps<{ accountId?: string }>> = ({
  match: {
    params: { accountId },
  },
}) => {
  const { flash } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()
  const [accounts] = useContext(AccountContext)
  const [account, setAccount] = useState<Account | undefined>(accounts?.find(a => a.id === accountId))

  useEffect(() => {
    setAccount(accounts?.find(a => a.id === accountId))
  }, [accountId])

  if (!session.user) return null
  const { user } = session

  return (
    <Page title={t('deposit.deposit_instruction')} padded>
      <PaperBlock title={account?.currencyId + ' ' + t('deposit.deposit_instruction')} desc={t('deposit.deposit_instruction_desc')}>
        {account && <DepositAccounts account={account} />}
        {account && account.currencyId === 'IDR' ? <DepositMethods account={account} /> : <DevelopingComponent />}
      </PaperBlock>
    </Page>
  )
}

export const DepositMethods: React.FunctionComponent<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation()
  const [method, setMethod] = useState<string>()
  const [resourceState] = useContext(ResourceContext)

  return (
    <Box marginTop={5}>
      <Box marginBottom={2}>
        <Typography variant="h3">Request deposit</Typography>
      </Box>

      <Paper square>
        <Tabs
          orientation="horizontal"
          value={method}
          onChange={(_, v) => {
            setMethod(v)
          }}
        >
          {resourceState.config && resourceState.config.find(f => f.config === 'deposit_va_disabled')?.value === 'true' ? (
            <></>
          ) : (
            <Tab value="vatransfer" icon={<Receipt />} label={t('deposit.va_transfer')} />
          )}

          {resourceState.config && resourceState.config.find(f => f.config === 'deposit_qris_disabled')?.value === 'true' ? (
            <></>
          ) : (
            <Tab value="qris" icon={<Code />} label={t('deposit.qris')} />
          )}

          {resourceState.config && resourceState.config.find(f => f.config === 'deposit_transfer_disabled')?.value === 'true' ? (
            <></>
          ) : (
            <Tab value="manualtransfer" icon={<Atm />} label={t('deposit.bank_transfer')} />
          )}

          {resourceState.config && resourceState.config.find(f => f.config === 'deposit_ewallet_disabled')?.value === 'true' ? (
            <></>
          ) : (
            <Tab value="ewallet" icon={<AccountBalanceWallet />} label={t('deposit.e_wallet')} />
          )}
        </Tabs>
      </Paper>

      <Box p={5} flex={1}>
        {method === 'manualtransfer' ? (
          <DepositManualTransfer account={account} />
        ) : method === 'vatransfer' ? (
          <DepositVATransfer account={account} />
        ) : method === 'qris' ? (
          <DepositQRISPayment account={account} />
        ) : method === 'ewallet' ? (
          <DepositEWalletPayment account={account} />
        ) : (
          <>
            <Typography variant="body1">{t('deposit.select_method')}</Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
