import React, { SFC, useContext } from 'react'
import NumberFormat from 'react-number-format'
import { ResourceContext } from 'contexts'
import { Currency } from 'models'

interface CurrencyFormatProps {
  currency?: Currency
  value: number | undefined
  fixedDecimalScale?: boolean
}

const CurrencyFormat: SFC<CurrencyFormatProps> = ({ currency, value, fixedDecimalScale = true }) => {
  let currencyModel = null
  if (typeof currency === 'string') {
    // const [state] = useContext(ResourceContext)
    // currencyModel = state.currencies.find((c) => {
    //   return c.id.toLowerCase() === currency.toLowerCase()
    // })
  } else if (currency) {
    currencyModel = currency
  }
  if (!currencyModel) return <>{value}</>

  return (
    <NumberFormat
      value={typeof value !== 'undefined' ? value : ''}
      displayType={'text'}
      thousandSeparator
      suffix={' ' + currencyModel.id.toUpperCase()}
      decimalScale={currencyModel.precision}
      fixedDecimalScale={fixedDecimalScale}
    />
  )
}

export default CurrencyFormat
