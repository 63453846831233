import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Paper, TextField, TextFieldProps, Typography } from '@material-ui/core'
import { CurrencyIcon } from 'components/CurrencyIcon'
import { FormControlGrid } from 'components/FormControlGrid'
import { LabeledValue } from 'components/LabeledValue'
import { AppContext } from 'contexts'
import { Account, Withdraw, WithdrawStatus } from 'models'
import React, { useContext, useEffect, useState } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Spinner from 'react-spinkit'
import Api from 'utils/Api'
import FormatHelper from 'utils/FormatHelper'
import shortUUID from 'short-uuid'
import { SessionContext } from 'contexts/SessionContext'

interface WithdrawConfirmInput {
  authorizationCode: string
}

export const WithdrawConfirming: React.FunctionComponent<{
  account: Account
  withdraw: Withdraw
  onWithdrawConfirmed: (withdraw: Withdraw) => void
  onWithdrawCancelled: () => void
}> = ({ account, onWithdrawConfirmed, onWithdrawCancelled, ...props }) => {
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { handleError, flash } = useContext(AppContext)

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [withdraw, setWithdraw] = useState<Withdraw>(props.withdraw)

  const { control, handleSubmit, watch, setValue } = useForm<WithdrawConfirmInput>()

  const confirmWithdraw = (withdraw: Withdraw, authorizationCode: string) => {
    return Api.post(`/accounts/${withdraw.accountId}/withdraw/${withdraw.id}/confirm`, {
      authorizationCode: authorizationCode,
    })
      .then(res => {
        onWithdrawConfirmed(res.data)
      })
      .catch(err => {
        setValue('authorizationCode', '')
        handleError(err)
      })
  }

  const onSubmit = (formValue: WithdrawConfirmInput) => {
    if (formValue.authorizationCode && formValue.authorizationCode.length !== 6) return

    setSubmitting(true)

    if (withdraw.id && withdraw.status === WithdrawStatus.UNAUTHORIZED) {
      return confirmWithdraw(withdraw, formValue.authorizationCode).finally(() => {
        setSubmitting(false)
      })
    }

    Api.request<Withdraw>({
      method: 'POST',
      url: `/accounts/${withdraw.accountId}/withdraw`,
      data: {
        amount: withdraw.amount,
        prefix: withdraw.prefix,
        address: withdraw.address,
        addressName: withdraw.addressName,
        beneficiaryId: withdraw.beneficiaryId,
        requestId: shortUUID.generate(),
        // authorizationCode: formValue.authorizationCode,
      },
    })
      .then(res => {
        if (!session.user?.twoFAEnabled) {
          return onWithdrawConfirmed(res.data)
        } else {
          setWithdraw(res.data)
          return confirmWithdraw(res.data, formValue.authorizationCode)
        }
      })
      .catch(handleError)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onCancel = () => {
    onWithdrawCancelled()
  }

  return (
    <Box justifyContent="center" alignContent="center" alignItems="center" flexDirection="column" flex={1} display="flex">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m={2} minWidth={320} alignItems="center" flexDirection="column">
          <Paper elevation={5}>
            <Box p={2}>
              <Typography variant="h4">{t('withdraw.from')}</Typography>
            </Box>
            <Divider />

            <LabeledValue
              label={t('withdraw.account')}
              value={() => (
                <Box marginTop={1} display="flex" alignContent="center">
                  <CurrencyIcon currency={account.currency} />
                  <Box component="span" marginLeft={2}>
                    {account.number}
                  </Box>
                </Box>
              )}
            />
          </Paper>

          <Box textAlign="center" margin={1}>
            <ArrowDownCircle />
          </Box>

          <Paper elevation={5}>
            <Box p={2}>
              <Typography variant="h4">{t('withdraw.to')}</Typography>
            </Box>
            <Divider />

            {/* <LabeledValue label={t('withdraw.bank')} value={withdraw.prefix} /> */}
            <LabeledValue
              label={t('withdraw.address')}
              value={() => (
                <Box marginTop={1} display="flex" alignContent="center">
                  <CurrencyIcon currency={account.currency} />
                  <Box component="span" marginLeft={2}>
                    {withdraw.prefix ? withdraw.prefix.toUpperCase() : ''} {withdraw.address}
                  </Box>
                </Box>
              )}
            />
            <LabeledValue label={t('withdraw.address_name')} value={withdraw.addressName ?? '--'} />
          </Paper>
          <LabeledValue label={t('withdraw.amount')} value={FormatHelper.amount(withdraw.amount, account.currencyId)} />

          {withdraw.feeAmount > 0 ? <LabeledValue label={t('withdraw.fee')} value={FormatHelper.amount(withdraw.feeAmount, account.currencyId)} /> : null}

          {session.user?.twoFAEnabled ? (
            <Box marginTop={4}>
              <Controller
                control={control}
                name="authorizationCode"
                render={field => <TwoFAInput value={field.value} onChange={field.onChange} />}
                rules={{
                  minLength: 6,
                  maxLength: 6,
                }}
              />
            </Box>
          ) : null}

          {(withdraw.status === WithdrawStatus.PENDING ||
            withdraw.status === WithdrawStatus.INQUIRIED ||
            withdraw.status === WithdrawStatus.PROCESS ||
            withdraw.status === WithdrawStatus.UNAUTHORIZED) && (
            <>
              {/* <FormControl margin="normal" fullWidth>
                <FormControlLabel control={<Controller control={control} name="agreed" as={Checkbox} />} label={t('withdraw.agree_term')} />
              </FormControl> */}

              <FormControl margin="normal" fullWidth>
                <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                  {t('withdraw.confirm')}
                </Button>
              </FormControl>
            </>
          )}

          <FormControl margin="normal" fullWidth>
            <Button variant="text" color="default" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
          </FormControl>
        </Box>
      </form>
    </Box>
  )
}

const TwoFAInput: React.FunctionComponent<TextFieldProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState<string>('')

  return (
    <>
      <TextField
        fullWidth={true}
        variant="outlined"
        label={t('profile.twofa_google_code')}
        value={code}
        required={true}
        size="medium"
        onChange={e => {
          setCode(e.target.value)
        }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
        {...props}
      />
    </>
  )
}
