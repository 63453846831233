import { Avatar, Box, Divider, Drawer, Hidden, List, ListItem, makeStyles, Typography } from '@material-ui/core'
import { AccountBalance, ExitToApp, FindInPage, HistoryOutlined, SwapHoriz, SwapVert } from '@material-ui/icons'
import { ResourceContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import { UserRoles } from 'models'
import React, { useContext, useEffect } from 'react'
import { ArrowRightCircle as CallbackIcon, Key as KeyIcon, User as UserIcon, Users as UsersIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { NavBarBalances } from './NavBarBalances'
import NavItem from './NavItem'

type Route = {
  href: string
  icon: React.ReactNode
  title: string
  component: React.ReactNode
}

type Group = {
  title: string
}

type NavBarProps = {
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const NavBar: React.SFC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const { t } = useTranslation()
  const [resourceState] = useContext(ResourceContext)
  const classes = useStyles()
  const location = useLocation()

  const [session, sessionDispatch] = useContext(SessionContext)
  const { user } = session

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  // const avatar = '/static/images/avatars/avatar_12.png'

  const content = (
    <Box flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" paddingX={2} paddingY={1}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          // src={avatar}
          to="/profile"
        />
        {/* <Typography
          className={classes.name}
          color="textPrimary"
          variant="body2"
          gutterBottom
          style={{ paddingLeft: 10, paddingRight: 10, textOverflow: 'clip' }}
        >
          {user?.email}
        </Typography> */}

        <Typography color="textSecondary" variant="body1" gutterBottom>
          {user?.name}
        </Typography>

        <Typography color="textSecondary" variant="body2" style={{ color: '#999', textAlign: 'center' }}>
          {user?.partner?.name} {user?.roles?.join(',')}
        </Typography>
      </Box>

      <Box paddingX={2}>
        <List>
          <NavSection>
            <RouterLink to="/accounts" style={{ color: '#000', textDecoration: 'none' }}>
              <ListItem style={{ fontWeight: 'bold', paddingLeft: 10 }}>{t('account.my_balances')}</ListItem>
            </RouterLink>

            <NavBarBalances />
          </NavSection>

          <NavSection>
            <NavTitle title={t('transaction.transactions')} style={{ marginBottom: 16 }} />
            {/* <NavItem href="/accounts" title={t('dashboard.overview')} icon={DashboardRounded} /> */}
            {resourceState.currencies.length > 1 && <NavItem href="/exchanges" title={t('exchange.title')} icon={SwapVert} />}
            {/* <NavTitle title={t('transfer.send')} style={{ marginTop: 18, marginBottom: 18 }} /> */}
            <NavItem href="/transfers" title={t('transfer.transfer')} icon={SwapHoriz} />
            <NavItem href="/withdraws" title={t('withdraw.withdraw')} icon={AccountBalance} />
          </NavSection>

          <NavSection>
            <NavItem href="/withdraws/beneficiaries" title={t('withdraw.beneficiaries')} icon={UsersIcon} />
            <NavItem href="/transfers/history" title={t('transfer.history')} icon={HistoryOutlined} />
            <NavItem href="/withdraws/history" title={t('withdraw.history')} icon={HistoryOutlined} />
          </NavSection>

          {session.user && (session.user.hasRole(UserRoles.PartnerAdmin) || session.user.hasRole(UserRoles.PartnerUser)) ? (
            <NavSection>
              {session.user && session.user.hasRole(UserRoles.PartnerAdmin) ? (
                <NavItem href="/profile/apikeys" title={t('profile.my_apikeys')} icon={KeyIcon} />
              ) : (
                <></>
              )}
              <NavItem href="/callbacks" title={t('callbacks.callbacks')} icon={CallbackIcon} />
            </NavSection>
          ) : (
            <></>
          )}

          <NavSection>
            <NavItem href="/inquiryrrn" title={t('tools.inquiry_rrn')} icon={FindInPage} />
          </NavSection>

          <NavSection>
            <NavTitle title={t('profile.management')} style={{ marginTop: 16, marginBottom: 16 }} />
            <NavItem href="/profile" title={t('profile.my_profile')} icon={UserIcon} />

            <NavItem href="/auth/signout" title={t('profile.signout')} icon={ExitToApp} />
          </NavSection>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )
  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </div>
  )
}

const NavSection: React.FunctionComponent = props => {
  return (
    <>
      <Box marginY={1}>
        <Divider />
      </Box>
      {props.children}
    </>
  )
}

const NavTitle = (props: { title: string; style?: any }) => (
  <div>
    <ListItem
      style={{
        fontWeight: 'bold',
        // color: '#333',
        // color: 'rgb(44, 56, 126)',
        paddingLeft: 10,
      }}
    >
      {props.title}
    </ListItem>
  </div>
)

NavBar.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMobileClose: () => {},
  openMobile: false,
}

const useStyles = makeStyles(() => ({
  root: {},
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    backgroundColor: '#ffffffe6',
    width: 256,
    // paddingTop: 35,
    height: '100%',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginTop: 10,
    marginBottom: 10,
  },
  name: {},
}))

export default NavBar
