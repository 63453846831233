import { Box, Button, Typography } from '@material-ui/core'
import { ErrorOutline, ReportProblem } from '@material-ui/icons'
import Error from '@material-ui/icons/Error'
import { AppContext } from 'contexts'
import { Withdraw, WithdrawStatus } from 'models'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Spinner from 'react-spinkit'
import Api from 'utils/Api'
import { WithdrawDetailContent } from './WithdrawDetailContent'

export const InquiryFailed: React.FunctionComponent<{
  inquiry: Withdraw
  onWithdrawCancelled: () => void
}> = ({ inquiry, onWithdrawCancelled }) => {
  const { t } = useTranslation()
  const { handleError, flash } = useContext(AppContext)

  return (
    <Box justifyContent="center" alignContent="center" alignItems="center" flexDirection="column" flex={1} display="flex">
      <Box m={5} alignItems="center" flexDirection="column">
        <ReportProblem fontSize="large" style={{ fontSize: 96 }} />
      </Box>
      <Box alignItems="center" textAlign="center">
        <Typography variant="h3" gutterBottom>
          Withdraw inquiry failed
        </Typography>
        <Typography variant="body1" gutterBottom>
          Status: {inquiry.status}
        </Typography>
      </Box>
      <Box m={5} alignItems="center" textAlign="center">
        <Button variant="contained" color="default" onClick={onWithdrawCancelled}>
          {t('common.back')}
        </Button>
      </Box>
    </Box>
  )
}
