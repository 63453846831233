import { Button, CircularProgress, Typography } from '@material-ui/core'
import { AppContext } from 'contexts'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Api from 'utils/Api'
import Env from 'utils/Env'
import FormatHelper from 'utils/FormatHelper'
import { StorageManager } from 'utils/StorageManager'

const BtnVerifyEmail: React.FunctionComponent = () => {
  const [session] = useContext(SessionContext)
  const { handleError } = useContext(AppContext)
  const { t } = useTranslation()
  const storageManager = new StorageManager()
  const [verifyBtn, setVerifyBtn] = useState<{
    disabled: boolean
    loading: boolean
    timerTimeout: number
  }>({
    disabled: false,
    loading: false,
    timerTimeout: storageManager.load('verifyBtnTimeout') || 0,
  })

  if (!session.user) return null
  const { user } = session

  useEffect(() => {
    const countdown =
      verifyBtn.timerTimeout > 0 &&
      setInterval(() => {
        let timerCountdown = verifyBtn.timerTimeout - 1
        storageManager.save('verifyBtnTimeout', timerCountdown)
        setVerifyBtn(prevState => ({ ...prevState, timerTimeout: verifyBtn.timerTimeout - 1 }))
      }, 1000)
    if (verifyBtn.timerTimeout > 0) {
      setVerifyBtn(prevState => ({ ...prevState, disabled: true, loading: false }))
    }
    if (verifyBtn.timerTimeout <= 0) {
      storageManager.remove('verifyBtnTimeout')
      setVerifyBtn(prevState => ({ ...prevState, disabled: false, loading: false, timerTimeout: 0 }))
    }
    return () => {
      clearInterval(countdown as NodeJS.Timeout)
    }
  }, [verifyBtn.timerTimeout])

  useEffect(() => {
    if (user.verifiedTime) {
      storageManager.remove('verifyBtnTimeout')
      setVerifyBtn(prevState => ({ ...prevState, disabled: false, loading: false, timerTimeout: 0 }))
    }
  }, [user.verifiedTime])

  if (user.verifiedTime) return null

  return (
    <Button
      variant="outlined"
      disabled={verifyBtn.disabled}
      size="small"
      onClick={async () => {
        setVerifyBtn(prevState => ({ ...prevState, loading: true, disabled: true }))
        await Api.request({
          method: 'POST',
          url: `/users/send-verify-email`,
          data: {
            email: session?.user?.email,
          },
        })
          .then(() => {
            toast.success(t('emailVerify.resendSuccess'))
            setVerifyBtn(prevState => ({ ...prevState, loading: false, disabled: true, timerTimeout: parseInt(Env.verifyEmailTtl) }))
          })
          .catch(err => {
            setVerifyBtn(prevState => ({ ...prevState, loading: false, disabled: false, timerTimeout: 0 }))
            handleError(err)
          })
      }}
    >
      {t('profile.verify_email')}
      <span>
        {verifyBtn.loading && <CircularProgress style={{ marginLeft: 8 }} size={16} />}
        {verifyBtn.timerTimeout > 0 && (
          <Typography variant="caption" style={{ marginLeft: 8 }}>
            {FormatHelper.secondToMMSS(verifyBtn.timerTimeout)}
          </Typography>
        )}
      </span>
    </Button>
  )
}

export default BtnVerifyEmail
