import { Partner } from './'

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserDetailStatus {
  Drafted = 'drafted',
  InReview = 'in review',
  Verified = 'verified',
  Rejected = 'rejected',
}

export enum UserRoles {
  Admin = 'admin',
  AdminFinance = 'adminfinance',
  OperationFinance = 'operationfinance',
  OperationSupport = 'operationsupport',
  PartnerAdmin = 'partneradmin',
  PartnerUser = 'partneruser',
  Customer = 'customer',
}

export class User {
  constructor(
    public id: string | undefined,
    public createdTime: Date,
    public updatedTime: Date,
    public status: UserStatus,
    public email: string,
    public mobile: string,
    public name: string,
    public verifiedTime: Date,
    public partnerId: string,
    public roles: UserRoles[],
    public twoFAEnabled: boolean,
    public configs: { [k: string]: any },
    public partner?: Partner,
    public userDetail?: Partial<UserDetail>,
  ) {}

  public static create(args: Partial<User> & Required<Pick<User, 'email' | 'mobile' | 'name' | 'partnerId'>>) {
    return new User(
      args.id,
      args.createdTime ?? new Date(),
      args.updatedTime ?? new Date(),
      args.status ?? UserStatus.Inactive,
      args.email,
      args.mobile,
      args.name,
      args.verifiedTime as Date,
      args.partnerId,
      args.roles ?? [UserRoles.Customer],
      Boolean(args.twoFAEnabled),
      args.configs ?? {},
      args.partner,
      args.userDetail,
    )
  }

  public hasRole: (role: string) => boolean = role => {
    return this.roles.findIndex(r => r === role) !== -1
  }
}

export class UserDetail {
  constructor(
    public id: string,
    public createdTime: string,
    public updatedTime: string,
    public userId: string,
    public identityType: UserIdentityType,
    public identityNumber: string,
    public identityFileId: string,
    public status: UserDetailStatus,
    public file: {
      id: string
      url: string
    },
  ) {}
}

export enum UserIdentityType {
  KTP = 'KTP',
  NPWP = 'NPWP',
  PASSPORT = 'PASSPORT',
}

export class EditProfileReq {
  constructor(public mobile: string, public name: string, public identityNumber: number, public identityType: UserIdentityType) {}
}
