import React from 'react'
import { Box, BoxProps, Grid, Typography } from '@material-ui/core'
import lodash from 'lodash'
import { ReactNode } from 'react-router/node_modules/@types/react'
import { Subject } from '@material-ui/icons'

type KeyValueProps = {
  label: string
  value: ReactNode
  bold?: boolean
  p?: number
}

const LabeledValue: React.FC<KeyValueProps> = ({ label, value, p }) => {
  // return <pre>{JSON.stringify(transaction)}</pre>
  if (React.isValidElement(value)) {
    // do nothing
  } else if (typeof value === 'object') {
    value = JSON.stringify(value, undefined, 1)
  } else if (typeof value === 'boolean') {
    value = value ? 'true' : 'false'
  } else if (typeof value === 'function') {
    value = value()
  }

  return (
    <Box p={typeof p === 'undefined' ? [1, 2] : p}>
      <Typography variant="caption" gutterBottom>
        {lodash.capitalize(label)}
      </Typography>
      <Typography variant="body1" noWrap>
        {value}
      </Typography>
    </Box>
  )
}

const HorizontalLabeledValue: React.FC<KeyValueProps & BoxProps> = ({ label, value, bold, ...props }) => {
  // return <pre>{JSON.stringify(transaction)}</pre>
  if (React.isValidElement(value)) {
    // do nothing
  } else if (typeof value === 'object') {
    value = JSON.stringify(value, undefined, 1)
  } else if (typeof value === 'boolean') {
    value = value ? 'true' : 'false'
  } else if (typeof value === 'function') {
    value = value()
  }
  return (
    <Box p={[1, 2]} display="flex" flex={1} justifyContent="space-between" {...props}>
      <Typography variant="body1" style={{ justifySelf: 'flex-start', fontWeight: bold ? 'bold' : 'normal', whiteSpace: 'nowrap' }}>
        {lodash.capitalize(label)}
      </Typography>
      <Typography
        variant="body1"
        style={{
          justifySelf: 'flex-end',
          fontWeight: bold ? 'bold' : 'normal',
          paddingLeft: 10,
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

const ObjectLabeledValues: React.FC<{ object: { [k: string]: any } }> = ({ object }) => {
  return (
    <Grid container spacing={1}>
      {Object.keys(object).map((key, keyK) => {
        const obj = object[key]
        return (
          <Grid key={`olv-${key}-${keyK}`} item xs={12} md={6}>
            <LabeledValue label={key} value={obj} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export const GridObjectLabeledValues: React.FC<BoxProps & { object: { [k: string]: any }; label?: string; nested?: boolean }> = ({
  object,
  nested,
  label,
  ...other
}) => {
  if (!object) return null
  if (Object.keys(object).length == 0) return null

  let subObjects: { label: string; object: { [k: string]: any } }[] = []
  Object.keys(object).map((key, keyK) => {
    let obj = object[key]
    if (typeof obj == 'string' && obj[0] == '{') {
      try {
        let objJson = JSON.parse(obj)
        obj = objJson
      } catch (e) {
        // Do nothing
      }
    }

    if (nested && obj && typeof obj == 'object' && Object.keys(obj).length > 0) {
      subObjects.push({ label: key, object: obj })
    }
  })

  return (
    <>
      <Grid container spacing={0}>
        <Grid xs={12} md={12}>
          <Box marginLeft={2}>
            <strong>------ {label || '-----------'}</strong>
          </Box>
        </Grid>
        {Object.keys(
          lodash.omit(
            object,
            subObjects.map(s => s.label),
          ),
        ).map((key, keyK) => (
          <Grid key={`golv-${key}-${keyK}`} item xs={12} md={6}>
            <LabeledValue label={key} value={object[key]} />
          </Grid>
        ))}
      </Grid>

      {subObjects.map((sub, subk) => (
        <GridObjectLabeledValues key={`golvgtr-${subk}-${sub.label}`} label={sub.label} object={sub.object} nested={true} />
      ))}
    </>
  )

  // return (
  //   <Grid container spacing={1}>
  //     <Grid xs={12} md={12}>
  //       <Box marginLeft={2}>
  //         <strong>{label || '-----------'}</strong>
  //       </Box>
  //     </Grid>
  //     {Object.keys(object).map((key, keyK) => {
  //       let obj = object[key]
  //       console.log('Key', key, typeof obj, obj && obj[0])
  //       if (typeof obj == 'string' && obj[0] == '{') {
  //         try {
  //           let objJson = JSON.parse(obj)
  //           obj = objJson
  //         } catch (e) {
  //           // Do nothing
  //           alert(e)
  //         }
  //       }
  //       return (
  //         <Grid key={`olv-${key}-${keyK}`} item xs={12} md={6}>
  //           {nested && obj && typeof obj === 'object' ? (
  //             <Box display="flex">
  //               <GridObjectLabeledValues object={obj} nested={true} label={key} {...other} />
  //             </Box>
  //           ) : (
  //             <LabeledValue label={key} value={obj} {...other} />
  //           )}
  //         </Grid>
  //       )
  //     })}
  //   </Grid>
  // )
}

export { HorizontalLabeledValue, LabeledValue, ObjectLabeledValues }
