import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export interface AmountNumberFormatProps {
  max?: number
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { value: string } }) => void
}

export function AmountNumberFormat(props: NumberFormatProps & AmountNumberFormatProps) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      getInputRef={inputRef}
      allowEmptyFormatting
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      {...other}
      // prefix="$"
    />
  )
}
