import { Avatar, Box, Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core'
import Page from 'components/Page'
import PaperBlock from 'components/PaperBlock/PaperBlock'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ApikeysTable } from './ApikeysTable'

export const ApiKeysPage: React.FunctionComponent<{}> = () => {
  const [session] = useContext(SessionContext)
  const classes = useStyles()
  const { t } = useTranslation()

  if (!session.user) return null
  const { user } = session

  return (
    <Page title={t('profile.my_apikeys')} padded>
      <PaperBlock title={t('profile.my_apikeys')} desc={t('profile.my_apikeys_subtitle')}>
        <ApikeysTable />
      </PaperBlock>
    </Page>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 90,
    height: 90,
    margin: theme.spacing(1),
  },
}))
