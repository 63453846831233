import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputBaseProps,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { Quote } from 'models/Quote'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import { User, Account, UserRoles } from 'models'
import { SessionContext } from 'contexts/SessionContext'
import { Controller, useForm } from 'react-hook-form'
import Api from 'utils/Api'
import { AppContext } from 'contexts'
import { AccountSelect } from 'components/AccountSelect'
import { LabeledValue, ObjectLabeledValues } from 'components/LabeledValue'
import lodash from 'lodash'
import FormatHelper from 'utils/FormatHelper'

export const ReceiverForm: React.FunctionComponent<{
  quote: Quote
  onReceiverChange: (user: User | undefined, account?: Account) => void
}> = ({ quote, onReceiverChange }) => {
  const { t } = useTranslation()
  const [receiver, setReceiver] = useState<Account>()

  // if (!quote.receiveCurrencyId) return null

  return (
    <>
      <Paper variant="outlined">
        <Box p={[1, 2]}>
          <Typography gutterBottom>{t('inquiry.receiver_account')}</Typography>
          <InquiryReceiverSearch
            currencyId={quote.receiveCurrencyId}
            onSelectAccount={account => {
              setReceiver(account)
              onReceiverChange(account?.user, account)
            }}
            // defaultValue={receiver?.id}
            // value={receiver?.id}
          />

          {receiver && receiver.user && <InquiryReceiverForm quote={quote} account={receiver} />}
        </Box>
      </Paper>
    </>
  )
}

export const InquiryReceiverSearch: React.FunctionComponent<
  InputBaseProps & {
    currencyId: string
    onSelectAccount: (account?: Account) => void
  }
> = ({ currencyId, onSelectAccount, ...props }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { handleError, flash } = useContext(AppContext)
  const [session] = useContext(SessionContext)

  const [type, setType] = useState<string>()
  const [query, setQuery] = useState<string>()
  const [error, setError] = useState<string>()

  const onSearch = () => {
    if (!(query && query.length > 6)) return

    onSelectAccount(undefined)
    setError(undefined)
    Api.request<Account[]>({
      method: 'GET',
      url: `/accounts/search`,
      params: {
        currencyId: currencyId,
        query,
      },
    })
      .then(({ data }) => {
        if (data && data[0]) {
          onSelectAccount(data[0])
        } else {
          setError(t('errors.account_not_found'))
        }
      })
      .catch(err => {
        setError(FormatHelper.error(err))
      })
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth margin="normal" title={t('withdraw.to')}>
            <RadioGroup
              defaultValue={'other'}
              onChange={e => {
                setType(e.target.value)
              }}
            >
              <Box display="flex" flexDirection="row">
                <Box>
                  <FormControlLabel value={'other'} control={<Radio />} label={t('transfer.to_pazemo_account')} />
                </Box>
              </Box>
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={8}>
          <FormControl margin="normal" fullWidth>
            <Paper className={classes.searchRoot}>
              <InputBase
                className={classes.input}
                placeholder={t('inquiry.search_receiver_tips')}
                onChange={e => {
                  setQuery(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key == 'Enter') {
                    onSearch()
                  }
                }}
                {...props}
              />

              <Button variant="contained" color="primary" type="submit" className={classes.iconButton} onClick={onSearch}>
                <SearchIcon />
              </Button>
              <Divider className={classes.divider} orientation="vertical" />
            </Paper>
            <FormHelperText error={true}>{error}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export const InquiryReceiverForm: React.FunctionComponent<{
  account: Account
  quote: Quote
}> = ({ account, quote }) => {
  const { flash, handleError } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    // onUserUpdated(user, account)
  }, [account])

  return (
    <Box>
      <LabeledValue label={t('inquiry.receiver_account')} value={`${account.currencyId} ${account.number}`} />
      <ObjectLabeledValues object={lodash.omit(account.user, 'id')} />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  fieldset: { borderWidth: 0, marginTop: 10, marginBottom: 10 },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))
